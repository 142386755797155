import React from 'react';
import {connect} from 'react-redux';
import SingleDateSearch from './single_date_search'
import Report from './report'
import DocumentReport from './document_report';
import {weeklyReport} from '../api/rest_reports';

let WeeklyReport = (props) => (
	<div  className="col-lg-12">
		<h2 className="text-center">Reporte de la semana</h2>
		<SingleDateSearch onSubmit={props.handleSubmit} />
		<hr/>
		{props.report.facturas !== undefined &&
			<div>
				<Report report={props.report} />
				<DocumentReport documentos={props.documentos} />
			</div>
		}
	</div>
);

const mapStateToProps = state  => {
	return {
		report: state.report,
		documentos: state.report.documentos
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(weeklyReport());
	return {
		handleSubmit: function(values){
			let date = new Date(values.fecha_inicio);

			dispatch(weeklyReport({
				fecha_inicio: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()

			}));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReport);

