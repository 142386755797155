import React from 'react';
export const AlertDialog = (props) =>{
		return (						
      <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <h5>{props.message}</h5>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
	)
};

export const ConfirmationDialog = (props) =>{
    return (            
      <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <h5>{props.message}</h5>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export const PrintInvoiceAlertDialog = (props) =>{
    return (            
      <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <h5>{props.message}</h5>
            <div className="modal-footer">
            <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={() => props.print(props.invoice_id)}>Aceptar</button>
              <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};