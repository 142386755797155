import React from 'react';
import {loadClientInfo} from '../api/rest_client';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TIPO_DOCUMENTO} from '../constants'
const InfoClientPage =({client}) => {
	return (
		<div className="col-lg-12">
			<h1 className="text-center">Información del cliente</h1>
			<div className="jumbotron col-md-10 offset-1 p-4">
				<h1 className="display-4">{client.nombre} {client.apellido}</h1>
				<p className="lead info">
					{client.notas}
				</p>
				<hr className="my-4"/>
				<p className="text-justify info">
					<strong>Email:</strong> {client.email}
				</p>
				<p className="text-justify info">
					<strong>Tipo Cedula:</strong> {client.tipo_cedula}
				</p>
				<p className="text-justify info">
					<strong>Cedula:</strong> {client.cedula}
				</p>			
				
				<p className="text-justify info">
					<strong>Telefono(s):</strong>
				</p>
				<ul>
					{client.telefonos !== undefined && client.telefonos.map((tel, idx) =>
						<li key={idx}>
							{	tel !== undefined &&
								<span>({tel.codigo_pais}) {tel.numero} - {tel.tipo.nombre}</span>
							}
						</li>
					)}
				</ul>
				<p className="text-justify info">
					<strong>Dirección:</strong>
				</p>
				{client.direcciones !== undefined && client.direcciones.map((field, idx)=>
					<div key= {idx}>
						{ field.provincia !== undefined &&
							<div>
								{field.provincia.nombre},&nbsp; 
								{field.canton.nombre}, &nbsp; 
								{field.distrito.nombre}, &nbsp; 
								{field.barrio.nombre}&nbsp; 
								{field.otro}
							</div>
						}
					</div>
				)}
				
				<Link to={"/clientes/edit/" + client.id} className="btn btn-primary btn-lg float-right" refresh="true">				
					<FontAwesomeIcon icon="edit" size="1x"/>
					Editar
			    </Link>
			    <div className="clearfix">
		        </div>
			</div>	
		{ client.documents !== undefined && client.documents.length > 0 &&
			<div className="col-lg-12">
				<h3>Documentos</h3>
				<table className="table table-striped">
					<thead className="text-center">
						<tr>
							<th>Fecha</th>
							<th>Tipo documento</th>							
							<th>Clave</th>
							<th>Monto total</th>
						</tr>
					</thead>
					<tbody>
						{	
							client.documents.map((documento, key) => {
								let date = new Date(documento.fecha_emision);
								let format_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
								return (
									<tr key={key} className="text-center">
										<td>{format_date} </td>
										<td>{TIPO_DOCUMENTO[documento.tipo_documento]} </td>
										<td><a href={"/facturas/info/"+documento.id}>{documento.clave} </a></td>
										<td>{documento.total_comprobante} </td>
									</tr>
								)
							})

						}
					</tbody>
				</table>
			</div>	
		}
			
		</div>
    );
}
const mapStateToProps = (state)  => {
	return {
		client: state.client
	};
};
     
const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadClientInfo(id));
	return {
		
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoClientPage);