import React, { Component } from 'react';
import RecoverPasswordForm from '../forms/recover_password_form';
import {connect} from 'react-redux';
class RecoverPasswordPage extends Component {
	constructor(props){
		super(props);
	    this.handleSubmit = props.handleSubmit;
	}

	render(){
		return (
			<div className="col-md-12 ">
				<br/>
				<h2 className="text-center">
					Ingrese su Email y respuesta secreta y haga click en Aceptar
				</h2>
				<br/>
				<RecoverPasswordForm/>				
			</div>
	    );
	}
}
    
const mapDispatchToProps = dispatch => {
	return {
    }
};
export default connect(null, mapDispatchToProps)(RecoverPasswordPage);