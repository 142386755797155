import  React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ProductHeader = props => (
<div className="row bg-secondary p-1 mb-1"> 
	<div className="col-md-2 text-white">
		<h4 ><u>Productos</u></h4>  
		{ props.dolar &&
			<small>
			$ Compra: CRC {math_round(props.dolar.compra, 3)} <br/>
			$ Venta: CRC {math_round(props.dolar.venta, 3)}
			</small>
		}
	</div>
	<div className="col-md-1  text-white no-padding">
		<label><strong>Modena &nbsp;</strong></label>
		<select className="form-control" onChange={props.updateCurrency}>
			<option value="CRC" defaultValue>CRC - Colon</option>
			<option value="USD">USD - Dolar</option>
		</select>
	</div>
	<div className="col-md-2 text-white no-padding">
		<label><strong>Medio Pago &nbsp;</strong></label>
		<select className="form-control" onChange={props.updateMedioPago}>
			<option value="01" defaultValue>Efectivo</option>
			<option value="02">Tarjeta</option>
			<option value="03">Cheque</option>
			<option value="04">Transferencia - Déposito bancario</option>
			<option value="05">Recaudado por terceros</option>
			<option value="99">Otros</option>
		</select>
	</div>
	<div className="col-md-2 text-white no-padding">
		<label><strong>Condicion Venta &nbsp;</strong></label>
		<select className="form-control" onChange={props.updateCondicionVenta}>
			<option value="01" defaultValue>Contado</option>
			<option value="02">Crédito</option>
			<option value="03">Consignación</option>
			<option value="04">Apartado</option>
			<option value="05">Arrendamiento con opción de compra</option>
			<option value="06">Arrendamiento en función financiera</option>
			<option value="99">Otros</option>
		</select>
	</div>
	<div className="col-md-5 p-1 mb-1">
		<div className="btn-group float-right">
			<button type="button" className="btn btn-light btn-lg float-right" data-toggle="modal" data-target="#productSearchModal"> 
				<FontAwesomeIcon icon="plus" size="1x"/> Productos
           </button>
			<button type="button" className="btn btn-light btn-lg float-right" data-toggle="modal" data-target="#discountFormModal">  
				<FontAwesomeIcon icon="percentage" size="1x"/> Descuento
			</button>
			<button type="button" className="btn btn-light btn-lg float-right" data-toggle="modal" data-target="#exonerateFormModal">  Exoneración  </button>
			
		</div>
	</div>

</div> 
)

export default ProductHeader;

const math_round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}