import $ from 'jquery'; 
export const printInvoice = (invoice) =>{
	var printWindow = window.open('', 'PRINT');
	 setTimeout(() => { // Needed for large documents
      printWindow.document.body.style.margin = '0 0';
      printWindow.document.body.innerHTML = $("#infopageprint").html();
      printWindow.document.close(); // necessary for IE >= 10
      printWindow.focus(); // necessary for IE >= 10*/
      printWindow.print();
      printWindow.close();
    }, 500)
}