import React from 'react';
import {connect} from 'react-redux';
import {getCompanyInfo} from '../api/rest_company';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const CompanyPage =(props) => {
	return (
		<div className="Row">
			<h2>Información la Compañia</h2>
			<p className="text-justify">
				La información suministrada, corresponde a la información que sus clientes obtendrán al recibir los documentos electronicos, y los documentos impresos.
				<br/>Para Evitar que existan problemas con el sistemasde tributación, la información debe concordar con los datos registrados en Hacienda.
			</p>
			<hr/>
			{
				props.company !== undefined && (
				<div className="jumbotron col-md-8 offset-2">
					<h1 className="display-4">{props.company.nombre}</h1>
					<p className="lead">
						{props.company.descripcion}
					</p>
					<hr className="my-4"/>
					<p className="text-justify">
						<strong>Código de la actividad económica:</strong> {props.company.codigo_actividad}
					</p>
					<p className="text-justify">
						<strong>Email:</strong> {props.company.email}
					</p>
					<p className="text-justify">
						<strong>TipoCedula:</strong> {props.company.tipo_cedula}
					</p>
					<p className="text-justify">
						<strong>Cedula:</strong> {props.company.cedula}
					</p>
					<p className="text-justify">
						<strong>Telefono(s):</strong> 
					</p>
						{ props.company.telefonos !== undefined &&
						<ul>
							{props.company.telefonos.map((tel,idx)=>{
								return(
									<li key={idx}> ({tel.codigo_pais}) {tel.numero}</li>
								)
							})}
						</ul>
						}
						<p className="text-justify">
							<strong>Dirección:</strong>
						</p>
						{props.company.direcciones !== undefined && props.company.direcciones.map((field, idx)=>
							<div key= {idx}>
								{ field.provincia !== undefined &&
									<div>
										{field.provincia.nombre},&nbsp; 
										{field.canton.nombre}, &nbsp; 
										{field.distrito.nombre}, &nbsp; 
										{field.barrio.nombre}&nbsp; 
										{field.otro}
									</div>
								}
							</div>
						)}
					
					<Link to={"/configuracion/compania/edit"} className="btn btn-primary btn-lg float-right" refresh="true">									    	
							<FontAwesomeIcon icon="edit" size="2x"/>
							Editar
				    </Link>
				    <br/>
				</div>
				)
			}
		</div>
    );
}
     
const mapStateToProps = (state)  => {
	return {
		company: state.company
	};
};
const mapDispatchToProps = dispatch => {
	dispatch(getCompanyInfo());
	return {
		handleSubmit: function(values){			
			
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);