import React from 'react';
import { Field, reduxForm, FieldArray  } from 'redux-form';
import {InputTextField, TextField, SelectField} from "../tags";
import PhoneField from "../tags/phone_field";
import AddressField from "../tags/address_field";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
let ClientForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form  onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
	      	<Field name="nombre" component={InputTextField} label="Nombre *"/>
	        <Field name="apellido" component={InputTextField} label="Apellido"/>
	        <Field name="email" component={InputTextField} label="Email *" legend="Campo no obligatorio, pero si no lo incluye no se enviaran correos de confirmación"/>
	        <Field name="tipo_cedula" component={SelectField} label="Tipo Cedula *" values={TIPO_CEDULA}/>
	        <Field name="cedula" component={InputTextField} label="Cedula *"/>
          <Field name="notas" component={TextField} label="Notas "/>
	        <FieldArray name="telefonos" component={PhoneField} label="Telefonos"/>
          <FieldArray name="direcciones" component={AddressField} label="Dirección"/>
	        <button type="submit" className="btn btn-primary btn-lg  float-right">
                <FontAwesomeIcon icon="check" size="1x"/>
                Aceptar
          </button>
          <div className="clearfix"><br/></div>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  } 
   if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }
  if (!values.cedula) {
    errors.cedula = 'Campo Requerido'
  } else {
  	 switch(values.tipo_cedula){
  	 	case "01": 
  			if (!/^\d{9}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550444";
  			}
  			break;
  		case "02": 
  			if(!/^\d{10}$/.test(values.cedula)){
  				errors.cedula ="Formato Numerico ie: 3125550444";
  			}
  			break;
  		default:
  			if (!/^\d{12}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550004440";
  	   }
	 }
	
  }
  if (!values.tipo_cedula) {
    errors.tipo_cedula = 'Campo Requerido'
  } 

  if (values.telefonos && values.telefonos.length > 0) {
  	const telArrayErrors = [];
  	values.telefonos.forEach((telefono, idx) => {
  		const telErrors = {};
  		if(!telefono.codigo_pais){
  			telErrors.codigo_pais = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.numero){
  			telErrors.numero = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.tipo){
  			telErrors.tipo = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  	});
  	if(telArrayErrors.length){
  		errors.telefonos = telArrayErrors;
  	}
  }

   
   return errors;
}

ClientForm = reduxForm({
  form: 'product', // a unique name for this form
  validate,
  enableReinitialize: true
})(ClientForm);

const TIPO_CEDULA = [
	{key: "01",	value: "Cedula física"},
	{key: "02",	value: "Cedula Juridica"},
	{key: "03",	value: "DIMEX"},
	{key: "04",	value: "NITE"},
]
export default ClientForm;