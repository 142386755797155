import React,{Component} from 'react';
import {connect} from 'react-redux';
import InvoiceForm1 from './invoice_form_1';
import InvoiceForm2 from './invoice_form_2';
import {sendInvoice} from '../../api/rest_invoices';
import { invoiceBody} from '../../lib/utils';
import { withRouter } from 'react-router-dom'
import $ from 'jquery'; 
class  InvoiceForm extends Component {
	constructor(props){
		super(props);
		this.state = {
	      page: 1,
	      atv_status: props.atv_status
	    }
	    this.handleSubmit = props.handleSubmit;
	    this.nextPage = this.nextPage.bind(this)
    	this.previousPage = this.previousPage.bind(this)
	}

	nextPage() {
    	this.setState({ page: this.state.page + 1 })
  	}

  	previousPage() {
    	this.setState({ page: this.state.page - 1 })
 	}

	render(){
		return (
			<div className="col-lg-12 no-padding">
				
			{ this.state.atv_status ?
				<div >
					<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    	<div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
					{ this.state.page === 1 && 	<InvoiceForm1 id="registerForm" nextPage={this.nextPage}   />	}
					{ this.state.page === 2 && 	<InvoiceForm2 id="registerForm" handleSubmit={this.handleSubmit}  previousPage={this.previousPage} />	}
				</div>
				:
				<div >
					<br/>
					<h2 className="text-center">Para poder emitir los documentos electronicos es necesario que registre la información de Hacienda</h2>
					<h3 className="text-center">Haga click <a href ="/configuracion/hacienda" alt="">aqui</a> para actualizar la información</h3>
				</div>
			}
			</div>
		);
	}
}


const mapStateToProps = state  => {
	return {
		invoice: state.invoice,
		atv_status: state.atv_status
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleSubmit: function(invoice){
			let _invoice = {"factura": invoiceBody(invoice)};
			if (invoice.products.length > 0) {
				dispatch(sendInvoice(_invoice));
			} else {
				$("#invoiceStatusAlert").modal('toggle');
			}
		}

	}
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceForm));
