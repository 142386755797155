import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form'
import storage from 'redux-persist/lib/storage';
import {MenuReducer} from './reducers/menu_reducer';
import {LoginReducer} from './reducers/login_reducer';
import {ClientReducer, ClientListReducer} from './reducers/client_reducer';
import {ProductReducer, ProductListReducer, CabysProductListReducer, InventoryReducer, InventoryListReducer} from './reducers/product_reducer';
import {UserReducer, UserListReducer} from './reducers/user_reducer';
import {AtvReducer,AtvStatusReducer} from './reducers/atv_reducer';
import {CompanyReducer} from './reducers/company_reducer';
import {ProvinceReducer, CantonReducer, DistrictoReducer, BarrioReducer} from './reducers/address_reducer';
import {InvoiceReducer,InvoicesReducer,InvoiceReducerInfo, InvoiceMensageReducer} from './reducers/invoice_reducer';
import {ReportReducer,ReportBalanceReducer,ReportAcceptedReducer} from './reducers/report_reducer';
const persistConfig = {
  key: 'root',
  storage,
}
const reducers = combineReducers({
	menu_option : MenuReducer,
	login_data: LoginReducer,
	client_list: ClientListReducer,
	client: ClientReducer,
	product: ProductReducer,
	product_list:ProductListReducer,
	cabys_product_list:CabysProductListReducer,
	inventory: InventoryReducer,
	inventory_list: InventoryListReducer,
	user: UserReducer,
	user_list: UserListReducer,
	atv: AtvReducer,
	atv_status: AtvStatusReducer,
	company: CompanyReducer,
	provincias: ProvinceReducer, 
	cantones: CantonReducer, 
	distritos: DistrictoReducer, 
	barrios: BarrioReducer,
	invoice: InvoiceReducer,
	invoice_info: InvoiceReducerInfo,
	invoices: InvoicesReducer,
	invoice_message:InvoiceMensageReducer,
	report: ReportReducer,
	balance_report: ReportBalanceReducer,
	accepted_report: ReportAcceptedReducer,
	form: formReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);
const Store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(Store)
export default Store;