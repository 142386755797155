import axios from 'axios';
import {PRODUCT_LIST, PRODUCT,initialProduct} from '../../redux/actions'
import $ from 'jquery'; 
import {URL,setTokenHeader,successMessage, errorMessage}  from './index';



export const productList = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "products", {params},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: PRODUCT_LIST,
              product_list:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: PRODUCT_LIST,
              product_list:  []
            });
        })

  };
}

export const addProduct = (product) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.post(URL() + "products", product,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){  
              successMessage("Producto ha sido creado satisfactoriamente");
              window.location.replace("/productos/info/" + res.data.id);
          }else {
            console.log(res);
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
           errorMessage("Ocurrio un error mientras se almacenaba el producto, por favor intentelo de nuevo.")
            
            
        })
    };
};
export const updateProduct = (product,id) => {
  $("#loading_spinner").hide();
  setTokenHeader();
  return (dispatch) => {    
       return axios.put(URL() + "products/" + id, product,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){  
              successMessage("Producto ha sido creado satisfactoriamente");
              window.location.replace("/productos/info/" + res.data.id);
          }else {
            console.log(res);
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
           errorMessage("Ocurrio un error mientras se almacenaba el producto, por favor intentelo de nuevo.");            
        })
    };
};
export const loadProduct = (product_id) => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "products/" + product_id,{}, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          
          dispatch({
              type: PRODUCT,
              product:  res.data
          });
          
        }).catch((error) => {
            console.log('error', error.response);
            
            
        })
    };
};

export const nextProduct = () => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "products/next" , {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {

          let data = initialProduct;
          data.codigo = res.data.id
          dispatch({
              type: PRODUCT,
              product:  data
          });
          
        }).catch((error) => {
            console.log('error', error.response);
            
            
        })
    };
};

export const loadProductInfo = (product_id) => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "products/info/" + product_id,{}, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          dispatch({
              type: PRODUCT,
              product:  res.data
          });
          
        }).catch((error) => {
            console.log('error', error.response);
            
            
        })
    };
};


export const addInventory = (inventory) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.post(URL() + "inventory", inventory,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){  
              successMessage("Inventario ha sido creado satisfactoriamente");
              window.location.replace("/productos/info/" + res.data.product_id);
              window.location.reload();
          }else {
            console.log(res);
            window.location.reload();
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          //errorMessage("Ocurrio un error mientras se almacenaba el producto, por favor intentelo de nuevo.")
           window.location.reload();
        })
    };
};