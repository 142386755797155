import React from 'react';
import {connect} from 'react-redux';
import SingleDateSearch from './single_date_search'
import ReportAccepted from './report_accepted'
import {acceptedReport} from '../api/rest_reports';
import DocumentAcceptedReport from './document_accepted_report';
let AcceptedReport = (props) => (
	<div  className="col-lg-12">
		<h2 className="text-center">Reporte documentos Aceptados</h2>
		<SingleDateSearch onSubmit={props.handleSubmit} />
		<hr/>
		{props.report.facturas !== undefined &&
			<div>
				<ReportAccepted report={props.report} />
				<DocumentAcceptedReport documentos={props.documentos} />
			</div>
		}

	</div>
);

const mapStateToProps = state  => {
	return {
		report: state.accepted_report,
		documentos: state.accepted_report.documentos
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(acceptedReport());
	return {
		handleSubmit: function(values){
			let date = new Date(values.fecha_inicio);

			dispatch(acceptedReport({
				fecha_inicio: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()

			}));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(AcceptedReport);