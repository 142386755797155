import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, TextField} from "../tags";
let ContactForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit} className="contact">
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
	      	<Field name="nombre" component={InputTextField} label="Nombre *"/>
	        <Field name="email" component={InputTextField} label="Email *"/>
          <Field name="asunto" component={InputTextField} label="Asunto"/>
          <Field name="mensaje"  component={TextField} label="Mensaje *"/>
	        <button type="submit" className="btn btn-primary btn-lg float-right">
            Enviar
          </button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  } 
  if (!values.mensaje) {
    errors.mensaje = 'Campo Requerido'
  } 
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }
  
   return errors;
}

ContactForm = reduxForm({
  form: 'contactus', // a unique name for this form
  validate,
  enableReinitialize: true
})(ContactForm);


export default ContactForm;