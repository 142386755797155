import axios from 'axios';
import {USER_LIST, USER} from '../../redux/actions'
import {URL,successMessage, errorMessage, formatError,setTokenHeader}  from './index';
import $ from 'jquery'; 
export const userList = (page = 1) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "users", {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: USER_LIST,
              user_list:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: USER_LIST,
              user_list:  []
            });
        })

  };
}


//get clients
export const loadUser = (id) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "users/" + id)
       .then(res => {
            dispatch({
              type: USER,
              user:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: USER,
              user:  {}
            });
        })

  };
}


export const addUser = (user) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.post(URL() + "users", user,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){    
            successMessage("Usuario creado satisfactoriamente!");                 
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
           if (error.response.status === 400){
            let error_messages = formatError(error.response.data);
            errorMessage("Error al crear Usuario, por favor intentelo de nuevo" + error_messages);
          }
        })
    };
};


export const updateUser = (user, id) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.put(URL() + "users/" + id, user,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
           successMessage("Información del usuario actualizada satisfactoriamente!");
           
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            let error_messages = formatError(error.response.data);
            errorMessage("No se pudo Actualizar la información del usuario, Intente de nuevo!" + error_messages);
          }
          else if (error.response.status === 401){
             errorMessage("No se pudo Actualizar la información del usuario, No cuenta con los permisos!" );
          }
        })
    };
};

export const updateAccount = (user, id) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.put(URL() + "accounts/" + id, user,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
           
           $("#updateUserAlert").modal('show');
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            let error_messages = formatError(error.response.data);
            errorMessage("No se pudo Actualizar la información del usuario, Intente de nuevo!" + error_messages);
          }
          else if (error.response.status === 401){
             errorMessage("No se pudo Actualizar la información del usuario, No cuenta con los permisos!" );
          }
        })
    };
};