import React from 'react';
import AccountForm from '../forms/account_form';
import {updateAccount} from '../api/rest_user';
import {connect} from 'react-redux';
import {AlertDialog} from '../dialogs';
import {logout} from '../../redux/actions';
const AccountPage =(props) => {
	return (
		<div className="col-md-10 offset-1">
			<AlertDialog id="updateUserAlert"
				message="Su información de usuario ha sido actualizada satisfactoriamente. 
				Acontinuación el sistema procedera a cerrar la sesión por favor ingrese con los nuevos datos suministrados "
				title="Información Actualizada" handleSubmit={props.logoutAction}/>
			<h2>Información de su cuenta de usuario</h2>
			{ props.userStatus === "incomplete" ?
				<div  className="alert alert-warning"  role="alert">
					La información de su cuenta se encuentra incompleta, por favor ingresar la siguiente información
				</div>
			:
				<p className="text-justify">
					A continuación encontrara su información de usuario. Si asi lo requiere actualice sus datos y presione <strong>Aceptar</strong>
				</p>
			}
			<AccountForm id="accountForm" onSubmit ={props.handleSubmit}   initialValues={props.initialValues} userStatus={props.userStatus} />
		</div>
    );
}
     
const mapStateToProps = (state)  => {
	return {
		userStatus: state.login_data.user.status,
		initialValues: state.login_data.user
	};
};
const mapDispatchToProps = dispatch => {
	return {
		handleSubmit: function(values){
		    let user = {
		    	usuario: values
		    }	    
		    dispatch( updateAccount(user, values.id));
    	},
    	logoutAction: function(){
    		dispatch(logout(0));
			localStorage.clear();
			window.location = "/login"
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);