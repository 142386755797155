import React from 'react';
import {clientList} from '../api/rest_client';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const PaginationClient = (props) => (
	<div className="row col-md-12">
		<div className = "col-md-2 offset-4 text-center">
			<button type="submit" onClick={() =>{props.setPage(props.page - 1)}}>
				<FontAwesomeIcon icon="backward" size="1x"/>
	    	</button>
	    	&nbsp;
			Página <b>{props.page + 1}</b> of {props.pages} 
			&nbsp;
			<button type="submit" onClick={() =>{props.setPage(props.page + 1)}}>
				<FontAwesomeIcon icon="forward" size="1x"/>
	    	</button>
		</div>
	</div>
);

const mapStateToProps = state  => {
	return {
		page: state.client_list.page,
		total: state.client_list.total,
		rows: state.client_list.page_rows,
		search: state.client_list.search,
		pages:  Math.ceil(state.client_list.total / state.client_list.page_rows)

	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleSubmit: function(values){
		    dispatch(clientList(values));
    	},
    	setPage: function(page) {
    		if (page < 0  || page >= this.pages ) {
				return
			}
			let params = {
				page: page,
				rows: this.rows
			};
			params = Object.assign(params, this.search);
			this.handleSubmit(params);
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(PaginationClient);

