import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import TerminosCondicionesPage from '../../pages/terminos_condiciones';
import $ from 'jquery'; 
let RegisterForm3 = (props) =>{
	const { handleSubmit,previousPage} = props;
	return (						
		<div >				
			<form className="login_form" onSubmit={handleSubmit}>
				  <h2 className="text-center">Términos y Condiciones</h2>
            <div className="col-lg-12 terms">
              <TerminosCondicionesPage />
            </div>
            <br/>
            <div className="col-lg-8 offset-2">
              
                <div className="form-group single_checkbox">
                  <label htmlFor="terms">
                    <Field name="terms" id="terms"component="input"type="checkbox"   onChange={props.terms} />
                    <strong> Acepto los Términos y Condiciones</strong>
                  </label>
                </div>

               <button type="submit" id="aceptButton" className="btn btn-primary btn-lg float-right" disabled>
                Aceptar
              </button>
    					<button  className="btn btn-secundary btn-lg float-right" onClick={previousPage}>
                Antras
              </button>
            </div>
			</form>
      <div className="clearfix"><br/></div>
      <div className="clearfix"><br/></div>
		</div>
	)
	
}

const validate = values => {
  const errors = {};
      
  return errors;
}

RegisterForm3 = reduxForm({
  form: 'registerForm', 
  destroyOnUnmount: false, 
  forceUnregisterOnUnmount: true, 
  validate
})(RegisterForm3);

const mapDispatchToProps = dispatch => {
	return {
	     terms: function(){
        let terms = $("#terms").is( ":checked" );
        if(terms){
          $("#aceptButton").prop( "disabled", false );
        }else {
          $("#aceptButton").prop( "disabled", true );
        }
       }
	}; 
};


export default connect(null, mapDispatchToProps)(RegisterForm3);