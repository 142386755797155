import React from 'react';
import ProductForm from '../forms/product_form';
import {addProduct,nextProduct} from '../api/rest_product';
import {connect} from 'react-redux';
const NewProductPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<br/>
			<h4>Ingrese la información relacionada con el Producto</h4>
			<hr/>
			<ProductForm id="newProductForm" onSubmit ={props.handleSubmit} 
			initialValues={props.product} />
			<div className="clearfix">
          <br/>
          </div>
		</div>
    );
}

const mapStateToProps = state  => {
	return {
		product: state.product,
	};
};     
     
const mapDispatchToProps = dispatch => {
	dispatch(nextProduct());
	return {
		handleSubmit: function(values){
		    let producto = {
		    	producto: values
		    }	    
		    dispatch( addProduct(producto));
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewProductPage);