import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, PasswordTextField} from "../tags";
let AtvForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
	      	<Field name="username" component={InputTextField} label="Usuario *"/>
	        <Field name="password" component={PasswordTextField} label="Contraseña *"/>	                  
	        <button type="submit" className="btn btn-primary btn-lg  float-right">Aceptar</button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Campo Requerido'
  } 
  if (!values.password) {
    errors.password = 'Campo Requerido'
  } 
 
  
   return errors;
}

AtvForm = reduxForm({
  form: 'atv', // a unique name for this form
  validate,
  enableReinitialize: true
})(AtvForm);

export default AtvForm;