import React, { Component } from 'react';
import LoginForm from '../forms/login_form';
import {connect} from 'react-redux';
class LoginPage extends Component {
	constructor(props){
		super(props);
	    this.handleSubmit = props.handleSubmit;
	}

	render(){
		return (
			<div className="col-md-12 ">
				<br/><br/>
				<h2 className="text-center">
					Ingrese sus credenciales y haga click en Aceptar
				</h2>
				<br/>
				<LoginForm initialValues={{}}/>				
			</div>
	    );
	}
}
    
const mapDispatchToProps = dispatch => {
	return {
    }
};
export default connect(null, mapDispatchToProps)(LoginPage);