import React from 'react';
import {addDiscount} from '../../../redux/actions/invoice_actions';
import { connect } from 'react-redux';
import $ from 'jquery'; 
let DiscountProductForm = (props) =>{
		return (						

      <div className="modal fade" id="discountFormModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Crear Descuentos</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="col-md-12">
                  <h5>Ingrese el motivo y el total del descuento</h5>
                  <div className="form-group">
                    <label>Motivo del Descuento</label>
                    <input id="discount_motivo" name="motivo" className="form-control" type="text"/>
                  </div>
                  <div className="form-group">
                      <label>Porcentaje de descuento</label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">%</div>
                        </div>  
                        <input  id="discount_total" name="total" className="form-control" type="number" min="0" max="100"/>
                     </div>
                      
                  </div>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary btn-lg"  data-dismiss="modal" onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>

	   	
	)
	
}


const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: function(){
      let motivo =  $("#discount_motivo").val();
      let total = $("#discount_total").val();
      dispatch(addDiscount({motivo: motivo, total: total}));
    }

  }
};

export default connect(null, mapDispatchToProps)(DiscountProductForm);