import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, PasswordTextField} from "../tags";
import {login} from '../api';
import { connect } from 'react-redux';
let LoginForm = (props) =>{
	const { handleSubmit} = props;
	return (						
		<div className="row">				
			<form className="login_form" onSubmit={handleSubmit}>
				<div id="login_form_error_msg" className="alert alert-danger hidde" role="alert">
					  <strong>ERROR: </strong>Email o Password invalidos!
				</div>
				<div id="login_form_error_msg1" className="alert alert-danger hidde" role="alert">
					  <strong>ERROR: </strong> La información enviada hace referencia a una cuenta inactiva
				</div>
				<div className="col-md-4 offset-4">
					<Field name="email" component={InputTextField} label="Email / Nombre de Usuario*"/>
		          	<Field name="password" component={PasswordTextField} label="Contraseña *"/>		          	
		          	<button type="submit" className="btn btn-primary btn-lg btn-block">Aceptar</button>
					<a href="/recuperar_contrasena" className="btn btn-link float-right" >Olvide mi contraseña</a>
				</div>	
				
			</form>
		</div>
	)
	
}
const validate = values => {
  const errors = {};
   if (!values.email) {
    errors.email = 'Campo Requerido'
  } 
  if (!values.password) {
    errors.password = 'Campo Requerido'
  } 
  return errors;
 }

LoginForm = reduxForm({
  form: 'product', // a unique name for this form
  validate,
  enableReinitialize: true
})(LoginForm);

const mapDispatchToProps = dispatch => {
	return {
		onSubmit(data){
			const user = {
			"usuario": {
				"email": data.email,
				"password": data.password
			}
	};
			dispatch(login(user));
		}
	};
};
export default connect(null, mapDispatchToProps)(LoginForm);