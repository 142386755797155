import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery'; 
import {addInventory} from '../api/rest_product';
let InventoryDialog = (props) =>{
		return (						

      <div className="modal fade" id="inventoryFormModal" tabIndex="-1" role="dialog"  aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Inventario</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="col-md-12">
                  <h5>La información es requerida para agregar productos al inventario</h5>

                  <input id="inventory_product_id" name="inventory_product_id" type="hidden" value={props.product_id}/>
                  <div className="form-group">
                    <label>Cantidad de Productos *</label>
                    <input  id="cantidad_inventario" name="cantidad_inventario" className="form-control" type="number" min="0"/>
                    <span id="cantidad_inventario_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                    <label>Nombre del Proveedor</label>
                    <input id="nombre_proveedor" type="text" name="nombre_proveedor" className="form-control"  />
                    <span id="nombre_institucion_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                    <label>Detalle</label>
                    <input id="detalle_inventario" type="text" name="detalle_inventario" className="form-control" />
                    <span id="detalle_inventario" className="error hidde">Campo Requerido!</span>
                  </div>

                  
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary btn-lg"  onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>
	)
};


const mapDispatchToProps = dispatch => {

  return {
      handleSubmit: function(){
        let isValid = true;
        let product_id =  $("#inventory_product_id").val();
        let cantidad =  $("#cantidad_inventario").val();
        let proveedor = $("#nombre_proveedor").val();
        let detalles= $("#detalle_inventario").val();

        if (cantidad === ""){
          $("#cantidad_inventario").show();
          isValid = false;
        }
      
        if (isValid) {
          let inventory = {
          product_id,
           cantidad,
           proveedor,
           detalles
          }
          console.log(inventory)
         $("#inventoryFormModal").hide();
         dispatch(addInventory(inventory));        
      }

    }
  }
};
export default connect(null, mapDispatchToProps)(InventoryDialog);