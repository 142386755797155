let DECIMALS = 5;
export const calulateTotales = (invoice) =>{
	let total_impuesto = 0;
	let total_comprobante = 0;
	let total_descuentos = 0;
	let total_venta_neta = 0;
	let total_gravado = 0;
	let total_venta = 0;
	let total_exento = 0;//Todo
	let total_exonerado = 0;//Todo
	let total_mercancias_exentas = 0;//Todo
	let total_mercancias_gravadas = 0;	
	let total_mercancias_exoneradas = 0;//Todo
	let total_serv_exentos = 0; //Todo
	let total_serv_gravados = 0;
	let total_serv_exonerado = 0;//Todo
	let total_otros_cargos = 0;//Todo
	let total_iva_devuelto = 0; //Todo

	for(let i = 0; i < invoice.products.length; i++){		
		let product = invoice.products[i];		
		let _producto = calculate_price(product, invoice.descuento);
		if (!product.es_servicio) {
			if (!product.exento) {
				total_mercancias_gravadas += _producto.monto_total;
			} 
			else {
				total_mercancias_exentas += _producto.monto_total;
			}
		}
		else {
			total_serv_gravados += _producto.monto_total;
		}

		//sumarize
		total_descuentos += _producto.monto_descuento;
		total_impuesto +=  _producto.impuesto;
	}
	total_mercancias_gravadas = math_round(total_mercancias_gravadas, DECIMALS)
	total_mercancias_exentas = math_round(total_mercancias_exentas, DECIMALS)
	total_serv_gravados = math_round(total_serv_gravados, DECIMALS)
	total_impuesto = math_round(total_impuesto, DECIMALS)

	total_descuentos = math_round(total_descuentos, DECIMALS)
	total_gravado = math_round(total_mercancias_gravadas + total_serv_gravados, DECIMALS);
	total_exento =  math_round(total_mercancias_exentas + total_serv_exentos, DECIMALS);	
	total_venta = math_round(total_gravado + total_exento + total_exonerado, DECIMALS);
	total_venta_neta =  math_round(total_venta - total_descuentos, DECIMALS);
	total_comprobante = math_round(total_venta_neta + total_impuesto, DECIMALS);

	return {
		total_impuesto,
		total_comprobante,
		total_descuentos,
		total_venta_neta,
		total_gravado,
		total_venta,
		total_exento,
		total_exonerado,
		total_mercancias_exentas,
		total_mercancias_gravadas,
		total_mercancias_exoneradas,
		total_serv_exentos,
		total_serv_gravados,
		total_serv_exonerado,
		total_otros_cargos,
		total_iva_devuelto
	};
}


export const invoiceBody = (invoice) => {
	let data = calulateTotales(invoice);
	let date = new Date();	
	let fecha_emision = date.getFullYear() + "-"  + (date.getMonth()+1)  + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
	let factura = {
		"numero": invoice.id,
		"codigo_moneda": invoice.moneda,
		"tipo_cambio": 1 ,
		"tipo_documento": invoice.tipo_documento, 
		"situacion": invoice.situacion, 
		"sucursal_id": 1,
		"terminal_id": 1,
		"fecha_emision":  fecha_emision,
		"condicion_venta": "01",
		"medio_pago":invoice.medio_pago,
		"total_serv_gravados": data.total_serv_gravados,
		"total_serv_exentos": data.total_serv_exentos,
		"total_serv_exonerado": data.total_serv_exonerado,
		"total_mercancias_gravadas": data.total_mercancias_gravadas,
		"total_mercancias_exentas": data.total_mercancias_exentas,
		"total_mercancias_exonerada": data.total_mercancias_exoneradas,
		"total_gravado": data.total_gravado,
		"total_exento": data.total_exento,
		"total_venta": data.total_venta,
		"total_descuentos": data.total_descuentos,
		"total_venta_neta": data.total_venta_neta,
		"total_impuesto": data.total_impuesto,
		"total_comprobante": data.total_comprobante,
		"total_otros_cargos": data.total_otros_cargos,
		"total_iva_devuelto": data.total_iva_devuelto,
		"otros": "",
		"cliente": invoiceClient(invoice.client) ,
		"detalles": invoiceProducts(invoice.products, invoice.descuento,fecha_emision)
	}
	return factura;

}

const invoiceClient = (client) =>{
	if (client.id === undefined) {
		return null;
	}

	let direccion = (client.direcciones !== undefined && client.direcciones.length > 0)  ? client.direcciones[0] : null ;
	let telefono = client.telefonos.length > 0 ? client.telefonos[0] : null;
	let data ={
		"client_id": client.id,
		"nombre": client.nombre + " " + client.apellido, 
		"tipo_identificacion": client.tipo_identificacion, 
		"identificacion": client.cedula, 
		"nombre_comercial": client.nombre, 		 
        "email": client.email
	}
	if(direccion !== null) {
		data["provincia"] =  direccion.provincia.id;
		data["canton"] =  direccion.canton.id;
		data["distrito"] =  direccion.distrito.id;
		data["barrio"] =  direccion.barrio.id;
		data["otro"] =  direccion.otro;
	}
	if (telefono !== null) {
		data["codigo_telefono"] =  telefono.codigo_pais;
		data["telefono"] = telefono.numero;
	}
	return data;
}

const invoiceProducts = (products, descuento, fecha_emision) => {
	let productos = []

	for(let i = 0; i < products.length; i++){
		let product = {};
		let producto = products[i];
		let impuestos = {};
		let _producto = calculate_price(producto, descuento);

		impuestos["codigo"] = producto.codigo_impuesto;
		impuestos["codigo_tarifa"] = producto.codigo_tarifa;
		impuestos["codigo_servicio"] = producto.codigo_impuesto;
		
		if (producto.factor_iva > 0){
			impuestos["tarifa"] = producto.factor_iva;
			impuestos["codigo_tarifa"] = producto.codigo_tarifa;
			impuestos["factor_iva"] = producto.factor_iva;
		}
		else {
			impuestos["tarifa"] = producto.tarifa_impuesto;
			impuestos["codigo_tarifa"] = producto.codigo_tarifa;
			impuestos["factor_iva"] = 0;
		}
		
		
		impuestos["monto"] = math_round(_producto.impuesto, DECIMALS);
		product["numero_linea"] = i + 1;
		product["producto_id"] = producto.id;
		product["cantidad"] = producto.cantidad;
		product["unidad_medida"] = producto.tipo_unidad;
		product["precio_unitario"] = math_round(_producto.precio, DECIMALS);
		product["monto_total"] = math_round(_producto.monto_total, DECIMALS) ;

		if (descuento.total > 0 ){
			product["monto_descuento"] = math_round(_producto.monto_descuento, DECIMALS) ;
			product["naturaleza_descuento"] = descuento.motivo !== ""  ? descuento.motivo  : descuento.total + "% de descuento" ;
		}
		product["sub_total"] = math_round(_producto.subtotal, DECIMALS);
		product["monto_total_linea"] = math_round(_producto.monto_total_linea, DECIMALS)	 ;
		product["impuestos"] = impuestos;
/*
		impuestos["tipo_documento"]=;
		impuestos["numero_documento"]=;
		impuestos["nombre_institucion"]=;
		impuestos["fecha_emision"]=;
		impuestos["monto_impuesto"]=;
		impuestos["porcentaje_compra"]=;
		*/
		productos.push(product);
	}
	return productos;
}
const calculate_price = (product, descuento) => {
	let tarifa_impuesto = product.factor_iva > 0 ? product.factor_iva :  product.tarifa_impuesto
	tarifa_impuesto =  tarifa_impuesto / 100
	let precio = math_round(product.precio / (tarifa_impuesto + 1), DECIMALS);
	let monto_total = math_round(precio * product.cantidad, DECIMALS);
	let monto_descuento = math_round(monto_total * (descuento.total / 100), DECIMALS);
	let subtotal = math_round(monto_total - monto_descuento, DECIMALS)
	let impuesto = math_round(subtotal * tarifa_impuesto, DECIMALS);
	let monto_total_linea = math_round(subtotal + impuesto, DECIMALS);

	return {
		tarifa_impuesto,
		precio,
		monto_total,
		monto_descuento,
		subtotal,
		impuesto,
		monto_total_linea
	}

}

const math_round = (value, decimals) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

