import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var currencyFormatter = require('currency-formatter');
class ProductRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.product.id,
      nombre: props.product.nombre,
      product: props.product,
      descuento: props.descuentoP,       
      cantidad:props.product.cantidad,
      precio: props.product.precio,
      removeProduct: props.removeProduct, 
      updateCantidad: props.updateCantidad, 
      updatePrecio: props.updatePrecio,
      moneda:props.moneda,
      codigo_tarifa:props.product.codigo_tarifa,
      tarifa_impuesto:props.product.tarifa_impuesto,
      subtotal: props.product.cantidad * props.product.precio,
      iva: (props.product.tarifa_impuesto / 100) * (props.product.cantidad * props.product.precio),
      descuentoProducto: (props.product.precio * (props.descuentoP  / 100) *  props.product.cantidad),
    };

    this.handleChangePrecio = this.handleChangePrecio.bind(this);
    this.handleChangeCantidad = this.handleChangeCantidad.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);

  }

  handleChangePrecio({target: {value}}) {
    this.calculateTotal(this.state.cantidad, value);
  }

  handleChangeCantidad({target: {value}}) {
    this.calculateTotal(value, this.state.precio);
  }

  calculateTotal(cantidad, precio) {
    let discount = (precio * (this.state.descuento / 100));
    let subtotal = (precio ) * cantidad;
    let iva = subtotal * (this.state.tarifa_impuesto / 100);
    this.state.updateCantidad(cantidad, this.state.id);
    this.state.updatePrecio(precio, this.state.id);
    this.setState({
      precio: precio,
      cantidad: cantidad,
      subtotal: subtotal,
      descuentoProducto: discount,
      iva: iva
    })
  }

  handleChange(){

  }

  render() {
    return (
          <tr className="invoice_line">
            <td className="input-td-cant"><input name="cantidad" type="number" value={this.state.cantidad} onChange={this.handleChangeCantidad} /></td>
            <td>{this.state.nombre}</td>            
            <td className="input-td">
              <div className="input-group ">
                <div className="input-group-prepend">
                  <div className="input-group-text moneda">{this.state.moneda}</div>
                </div>  
                <input name="precio" type="number" value={this.state.precio} onChange={this.handleChangePrecio} /> 
               </div>
            </td>
            <td>{currencyFormatter.format(this.state.subtotal, {code: this.state.moneda}) }</td>

            <td>
              <button className="btn btn-danger float-right" onClick={()=> this.state.removeProduct(this.state.product)}>
                  <FontAwesomeIcon icon="trash-alt" size="1x"/>
                </button>
            </td>
          </tr>
    );
  }
}



export default ProductRow;