import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {FileField} from "../tags";
let AceptDocumentForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>          
	      	<Field name="file" component={FileField} label="Documento XML"/>
	        <button type="submit" className="btn btn-primary btn-lg  float-right">Subir Archivo</button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.file) {
    errors.file = 'Campo Requerido'
  } 

   return errors;
}

AceptDocumentForm = reduxForm({
  form: 'atv_file', // a unique name for this form
  validate,
  enableReinitialize: true
})(AceptDocumentForm);

export default AceptDocumentForm;