import {JWT, LOGIN_STATUS} from '../actions';
export const LoginReducer = (state = "", action) => {
	switch (action.type) {
		case JWT:
			return action.jwt;
		case LOGIN_STATUS:
			return action.login_data;
		default:
			return state;
	}
};