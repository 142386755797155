import React from 'react';

const ATV = () => {
	return (
		
			<div className="p-3 mb-2  col-md-10 offset-1">
				<h4>Requisitos de Tributación </h4>
				<p>
					<strong>Digifact</strong> es una herramienta que busca simplificar el proceso para la generación de Facturas digitales. Funciona como intermediario 
					entre el sistema ofrecido por Tributación y  el usuario.<br/>
					Esto obliga al usuario a estar registrado en el sistema de ATV del Ministerio de Hacienda. Por favor dirigirse <a without rel="noopener noreferrer" href="https://www.hacienda.go.cr/ATV/login.aspx" target="_blank" alt="">aqui</a>
					 &nbsp;y proceda con el registro <br/>
					El siguiente video muestra los pasos para registrarse al sistema ATV. 
					<a href="https://www.youtube.com/watch?v=TFH-GatWjSw&t=0s&index=2&list=PLtFLFY98-7kUUynFoebCD6_jPpFQXabTk" without rel="noopener noreferrer" alt ="" target="_blank">Tutorial Crear cuenta usuario e ingreso ATV privada </a> <br/>
				</p>
				<p>
					Una vez efectuado el registro al sistema ATV del Ministerio de Hacienda, será necesario que genere un usuario y una contraseña , que son necesarios para poder conectarse al sistema de de Hacienda<br/>
					El siguiente video muestra los pasos para generar el usuario y la contraseña. 
					<a href="https://www.youtube.com/watch?v=I4RAy9-RGeg&t=0s&index=4&list=PLtFLFY98-7kUUynFoebCD6_jPpFQXabTk" without rel="noopener noreferrer" alt ="" target="_blank">Paso a paso usuario y contraseña para firmador </a> <br/>
				</p>
				<p>
					A su vez requerira generar una llave criptográfica, necesaria para firmar los documentos de forma digital<br/>
					El siguiente video le ayudara a generar la llave criptográfica. 
					<a href="https://www.youtube.com/watch?v=uqx2dx38gv4&t=11s&index=3&list=PLtFLFY98-7kUUynFoebCD6_jPpFQXabTk" without rel="noopener noreferrer"alt ="" target="_blank">Paso a paso llave criptográfica</a> <br/>
					Recuerde guardar la llave criptográfica 
				</p>
				<p>
					También Puede Consultar el siguiente documento me muestras los pasos a seguir para generar el usuario y contraseña a su vez de la llave criptográfica<br/>
					<a href="https://www.hacienda.go.cr/docs/5bb642ad01754_Generacion%20de%20Llave%20por%20ATV%20v2.pdf" without rel="noopener noreferrer"alt ="" target="_blank">
						Generación de Llave Criptográfica y Usuario/Contraseña de usuario,  en el portal ATV, para la emisión de comprobantes electrónicos
					</a> <br/>

				</p>
				<p>
					Obtenidos los datos de usuario y contraseña, asi como la llave criptográfica con respetivo pin,  debe ingresarlos al sistema de <strong>Digifact</strong>. por favor seguir los siguientes pasos
				</p>
				<div className="row">
					<div className="col-6">
						<ul>
							<li>Inicie sesión en <strong>Digifact</strong></li>
							<li>Ingresando al menú <u>Configuración</u>, luego dar click a la opción <u>Datos de Hacienda</u></li>
							<li>Verá una ventana (ver imagen) solicitando la información de hacienda que usted recien generó </li>
							<li>En la parte superior debera ingresar el usuario y la contraseña generados por hacienda, procesa a copiar y pegar el usuario y la contraseña(tal como los obtuvo sel sistema ATV, no modifique la información) y luego proceda ha aceptar el formulario</li>
							<li>En la parte inferior de la ventana, ingrese el pin y la llave criptográfica y haga click en subir archivo</li>
						</ul>
					</div>
					<div className="col-6">
						<img src="/images/datos_atv.png" alt="registro información de hacienda"/>
					</div>
				</div>
         	</div>

	)
}
export default ATV;