import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {connect} from 'react-redux';
import {getInvoiceList} from '../api/rest_invoices';
import SearchInvoice from '../forms/search_invoice';
const InvoiceList = (props) => (
	<div className="row">
		<div className="col-sm-12 col-lg-10 offset-lg-1">
			<h2>Facturas</h2>
			<hr/>
		</div>
		<div className="col-lg-10 offset-lg-1">
			<SearchInvoice onSubmit={props.handleSubmit}/>
		</div>
		<div className="col-lg-12 col-sm-12">
			<table className="table table-striped ">
				<thead>
					<tr>
						<th>Tipo Doc.</th>
						<th>Clave</th>
						<th>Cliente</th>
						<th>Fecha</th>
						<th>Estado</th>						
						<th>Opciones</th>
					</tr>					
				</thead>
				<tbody className="list">
					{props.list.map((invoice, key) => {
						return (
							<tr key={key}>	
								<td>{TIPO_DOCUMENTO[invoice.tipo_documento]}</td>
								<td className="text-truncate">{invoice.clave}</td>
								<td>{invoice.cliente}</td>
								<td>{invoice.fecha_emision}</td>
								<td>{invoice.estado && invoice.estado.description}</td>
								<td>
									<Link to={"/facturas/info/" + invoice.id} alt="Ver Docummentp">
										<FontAwesomeIcon icon="eye" size="2x"/>
							    	</Link>
						    	</td>
							</tr>
						);
					 })
				  }
				</tbody>
			</table>
		</div>
	</div>
);

const mapStateToProps = state  => {
	return {
		list: state.invoices
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(getInvoiceList({}));
	return {
		handleSubmit: function(values){
		    dispatch(getInvoiceList(values));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);

const TIPO_DOCUMENTO = {
	"01": "Factura ", 
	"02": "Nota de débito ",
	"03": "Nota de crédito ", 
	"04": "Tiquete", 
	"05": "Nota de despacho", 
	"06": "Contrato", 
	"07": "Procedimiento", 
	"08": "Factura Compra", 
	"99": "Otros"
}