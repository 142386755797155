import React from 'react';
import {loadProductInfo} from '../api/rest_product';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InventoryDialog from '../forms/inventory_form_dialogs';
const InfoProductPage =({product}) => {
	return (			
		<div className="Row">		
			<h1 className="text-center">Información del Producto</h1>
			{	product.impuesto !== undefined &&
			<div className="jumbotron col-md-8 offset-2">
				<h1 className="display-4">{product.nombre} </h1>
				<p className="lead info">
					{product.descripcion}
				</p>
				<hr className="my-4"/>
				<p className="text-justify info">
					<strong>Precio:</strong> {product.precio}
				</p>
				<p className="text-justify info">
					<strong>Cantidad:</strong> {product.cantidad}
				</p>
				<p className="text-justify info">
					<strong>Tipo Codigo Producto:</strong> {product.tipo_codigo !== undefined && product.tipo_codigo.nombre}
				</p>
				<p className="text-justify info">
					<strong>Código:</strong> {product.codigo}
				</p>			
				<p className="text-justify info">
					<strong>Tipo Unidad de venta:</strong> {product.unidad !== undefined && product.unidad.nombre}
				</p>
				<p className="text-justify info">
					<strong>Tipo de Impuesto:</strong> {product.impuesto.nombre !== undefined && product.impuesto.nombre}
				</p>
				{	product.impuesto.nombre === 'Otros'	&&
					<p className="text-justify info">
					<strong>Tarifa Impuesto:</strong> {product.impuesto !== undefined && product.impuesto.tarifa}%
					</p>	
				}
				<div className="row">
					<div className="col-md-2 offset-10">
						<Link to={"/productos/edit/" + product.id} className="btn btn-primary btn-lg float-right" refresh="true">				
							<FontAwesomeIcon icon="edit" size="1x"/>
							&nbsp;Editar
					    </Link>
					</div>
					
			    </div>
			    {product.has_inventario ? (				       
				    <div className="col-lg-12">
				    	<h3 className="display-6">Inventario </h3>			
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Fecha</th>								
									<th>Proveedor</th>						
									<th>Cantidad</th>
									
								</tr>					
							</thead>
							<tbody className="list">
								{product.inventario.map((inventario, key) => {
									return (
										<tr key={key}>	
											<td>{inventario.fecha}</td>										
											<td>{inventario.proveedor}</td>
											<td>{inventario.cantidad}</td>										
										</tr>
									);
								 })
							  }
							</tbody>
						</table>
						<div className="col-md-4 offset-8">
							<button type="button" className="btn btn-primary btn-lg float-right" data-toggle="modal" data-target="#inventoryFormModal">  Agregar inventario  </button>
					    </div>
					</div>
					) : (<div/>)
				}
				
			</div>	
			}
			<InventoryDialog product_id = {product.id}/>
		</div>
	
    );
}

const mapStateToProps = (state)  => {
	return {
		product: state.product
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadProductInfo(id));
	return {
    	
    }
};

export default connect(mapStateToProps , mapDispatchToProps)(InfoProductPage);