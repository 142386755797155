import React from 'react';
import { Field, reduxForm,FieldArray } from 'redux-form';
import {InputTextField, TextField, SelectField} from "../tags";
import PhoneField from "../tags/phone_field";
import { connect } from 'react-redux';
import AddressField from "../tags/address_field";
let CompanyForm = (props) =>{
	const { handleSubmit} = props;
	return (						
			<form className="" onSubmit={handleSubmit}>
        <div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
				<div id="form_error_msg" className="alert alert-danger hidde" role="alert"></div>
  			<Field name="nombre" component={InputTextField} label="Nombre comercial *"/>
        <Field name="codigo_actividad" component={InputTextField} label="Código de la actividad comercial"/>
  			<Field name="descripcion" component={TextField} label="Descripcion"/>
  			<Field name="tipo_cedula" component={SelectField} label="Tipo Cedula *" values={TIPO_CEDULA}/>
        <Field name="cedula" component={InputTextField} label="Cedula *"/>
  			<Field name="email" component={InputTextField} label="Email"/>
        <Field name="data" component={TextField} label="Información a mostrar en la factura digital"/>
        <FieldArray name="telefonos" component={PhoneField} label="Telefonos"/>
        <FieldArray name="direcciones" component={AddressField} label="Dirección"/>
        <hr/>
      	<button type="submit" className="btn btn-primary btn-lg float-right">Aceptar</button>
			</form>
	)
	
}

const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  }
  if (!values.codigo_actividad){
    errors.codigo_actividad = 'Campo Requerido'
  }
   else if (!/^\d{6}$/i.test(values.codigo_actividad)) {
    errors.codigo_actividad = 'Código incorrecto'
  }
  if (!values.email) {
    errors.email = 'Campo Requiredo'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }
  if (!values.cedula) {
    errors.cedula = 'Campo Requerido'
  } else {
  	 switch(values.tipo_cedula){
  	 	case "01": 
  			if (!/^\d{9}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550444";
  			}
  			break;
  		case "02": 
  			if(!/^\d{12}$/.test(values.cedula)){
  				errors.cedula ="Formato Numerico ie: 301205550444";
  			}
  			break;
  		default:
  			if (!/^\d{9}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550444";
  	   }
	 }
	
  }
  

  if (values.telefonos && values.telefonos.length > 0) {
    const telArrayErrors = [];
    values.telefonos.forEach((telefono, idx) => {
      const telErrors = {};
      if(!telefono.codigo_pais){
        telErrors.codigo_pais = 'Campo Requerido';
        telArrayErrors[idx] = telErrors;
      }
      if(!telefono.numero){
        telErrors.numero = 'Campo Requerido';
        telArrayErrors[idx] = telErrors;
      }
      if(!telefono.tipo){
        telErrors.tipo = 'Campo Requerido';
        telArrayErrors[idx] = telErrors;
      }
    });
    if(telArrayErrors.length){
      errors.telefonos = telArrayErrors;
    }
  }
  return errors;
}

CompanyForm = reduxForm({
  form: 'register',  
  validate,
   enableReinitialize: true
})(CompanyForm);

const mapDispatchToProps = dispatch => {
	return {
	
	};
};
const TIPO_CEDULA = [
	{key: "01",	value: "Cedula física"},
	{key: "02",	value: "Cedula Juridica"},
	{key: "03",	value: "DIMEX"},
	{key: "04",	value: "NITE"},
]

export default connect(null, mapDispatchToProps)(CompanyForm);