import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, PasswordTextField} from "../tags";
import {resetPassword} from '../api';
import { connect } from 'react-redux';
let RecoverPasswordForm = (props) =>{
	const { handleSubmit} = props;
	return (						
		<div className="row">				
			<form className="login_form" onSubmit={handleSubmit}>				
				<div className="col-md-6 offset-3">
					<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
	      			<div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
	      		</div>	
				<div className="col-md-4 offset-4">
					
					<Field name="email" component={InputTextField} label="Email *"/>
		          	<Field name="respuesta_secreta" component={PasswordTextField} label="Respuesta Secreta *"/>		          	
		          	<button type="submit" className="btn btn-primary btn-lg btn-block">Aceptar</button>
					<a href="/recuperar_pregunta" className="btn btn-link btn-block " >Enviarme mi pregunta secreta</a>
				</div>	
				
			</form>
		</div>
	)
	
}
const validate = values => {
  const errors = {};
   if (!values.email) {
    errors.email = 'Campo Requerido'
  } 
  if (!values.respuesta_secreta) {
    errors.respuesta_secreta = 'Campo Requerido'
  } 
  return errors;
 }

RecoverPasswordForm = reduxForm({
  form: 'resetPassword', // a unique name for this form
  validate,
  enableReinitialize: true
})(RecoverPasswordForm);

const mapDispatchToProps = dispatch => {
	return {
		onSubmit(data){
			dispatch(resetPassword(data));
		}
	};
};
export default connect(null, mapDispatchToProps)(RecoverPasswordForm);