import {CLIENT_LIST,CLIENT} from '../actions';
export const ClientListReducer = (state = [], action) => {
	if (action.type === CLIENT_LIST){
		return action.client_list;
	}
	return state;
};


export const ClientReducer = (state = {}, action) => {
	if (action.type === CLIENT){
		return action.client;
	}
	return state;
};

