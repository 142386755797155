import React from 'react';
import ClientForm from '../forms/client_form';
import {loadClient,updateClient} from '../api/rest_client';
import {connect} from 'react-redux';
const EditClientPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<br/>
			<h4>Actualizar información del cliente</h4>
			<hr/>
			<ClientForm id="editClientForm" onSubmit ={props.handleSubmit}  initialValues={props.initialValues}/>
			<br/>
			<div className="clear "/>
		</div>
    );
}
const mapStateToProps = (state)  => {
	return {
		initialValues: state.client
	};
};
     
const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadClient(id));
	return {
		handleSubmit: function(values){
		    let client = {
		    	cliente: values
		    }	    
		    console.log(client)
		    dispatch( updateClient(client,id));
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditClientPage);