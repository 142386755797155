import React from 'react';
import MenuButton from  '../components/menu_button'
import Login from '../components/login';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Header = (props) => (
	<div className="bg-dark header col-lg-12">        		
		<div className="col-md-12">
    		<div className="row">
            <div className="Menu col-12 " >
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                  <a href="https://www.digifact.co.cr" className="navbar-brand">
                  DigiFact
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  {props.login_data.logged ?  
                    <LoggedMenu usuario={props.login_data.user} role={props.role}/> : 
                    <NoLoggedMenu />
                  }
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item dropdown no-arrow mx-1">
                        <Login />
                    </li>
                  </ul>
                </nav>
            </div>

        	</div>       
        </div>
    </div>  
);
const LoggedMenu = ({usuario, role}) => (
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <MenuButton name="Inicio" icon="home" to="/"/>
      <MenuButton name="Facturación" icon="file-alt" to="/facturas"/>
      <MenuButton name="Clientes" icon="users" to="/clientes"/>
      <MenuButton name="Productos" icon="list-alt" to="/productos"/>
      <MenuButton name="Reportes" icon="book-open" to="/reportes"/>
      { role === "admin" &&
        <MenuButton name="Configuración" icon="cogs" to="/configuracion"/>
      }
      <MenuButton name="Acerca de" icon="info" to="/acercade"/>
     
    </ul>
    <Link to="/micuenta" className="user_icon"  refresh="true">                       
      <FontAwesomeIcon icon="user-cog" size="2x"/>
      <label>&nbsp;{usuario.nombre}</label>
    </Link>
  </div>
)

const NoLoggedMenu = ()  => (
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <MenuButton name="Inicio" icon="home" to="/"/>
      <MenuButton name="Acerca de" icon="info" to="/acercade"/>
        
    </ul>
  </div>
)

const mapStateToProps = state  => {
  return {
    login_data: state.login_data,
    role: state.login_data.role
  };
};

export default connect(mapStateToProps, null)(Header);