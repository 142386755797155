import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
import {  RenderDateTimePicker} from "../tags";
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment';
momentLocaliser(moment)

let DualDateSearch = (props) => {
	const { handleSubmit} = props;
	 return (
	 	 <form onSubmit={handleSubmit}>
	 	 	<div className="form-row align-items-center">

	 	 		<div className="col-md-3 my-1">
	 	 			<h5 className="text-center">Seleccione un rango de fechas, para crear el reporte</h5>
	 	 		</div>
				<div className="col-md-3 my-1">
					<Field component={RenderDateTimePicker} showTime={false} type="number" name="fecha_inicio" className="form-control form-control-lg" placeholder="Fecha Inicio"/>
				</div>
				<div className="col-md-3 my-1">
					<Field component={RenderDateTimePicker} showTime={false} type="number" name="fecha_fin" className="form-control form-control-lg" placeholder="Fecha Inicio"/>
				</div>
				<div className="col-auto my-2">
					<button className="btn btn-secondary btn-lg" type="submit">	
						<FontAwesomeIcon icon="search" size="1x"/>
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
DualDateSearch = reduxForm({
  form: 'search_report',
  enableReinitialize: true
})(DualDateSearch);

export default DualDateSearch;