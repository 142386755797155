import {PRODUCT_LIST,PRODUCT, INVENTORY, INVENTORY_LIST,CABYS_PRODUCT_LIST,PRODUCT_SERVICE_CODE,PRODUCT_CODE_TAXES,initialProduct,PRODUCT_TAXES} from '../actions';

export const ProductListReducer = (state = [], action) => {
	if (action.type === PRODUCT_LIST){
		return action.product_list;
	}
	return state;
};

export const CabysProductListReducer = (state = [], action) => {
	if (action.type === CABYS_PRODUCT_LIST){
		return action.cabys_product_list;
	}
	return state;
};




export const InventoryListReducer = (state = [], action) => {
	if (action.type === INVENTORY_LIST){
		return action.inventory_list;
	}
	return state;
};


export const InventoryReducer = (state = {}, action) => {
	if (action.type === INVENTORY){
		return action.inventory;
	}
	return state;
};

export const ProductReducer = (state = initialProduct, action) => {
	if (action.type === PRODUCT){
		return action.product;
	}
	if (action.type === PRODUCT_SERVICE_CODE){
		state = updateCodigoServicio(state, action.codigo_servicio)
	}
	if (action.type === PRODUCT_CODE_TAXES){
		state = updateCodigTarifa(state, action.codigo_tarifa)
	}
	if (action.type === PRODUCT_TAXES){
		state = updateTarifaImpuesto(state, action.tarifa_impuesto)
	}
	return state;
};

function updateCodigoServicio(state, codigo_servicio){
	state.codigo_servicio = codigo_servicio;
	return state;
}
function updateCodigTarifa(state, codigo_tarifa){
	state.codigo_tarifa = codigo_tarifa;
	return state;
	
}function updateTarifaImpuesto(state, tarifa_impuesto){
	state.tarifa_impuesto = tarifa_impuesto;
	return state;
}