import React from 'react';
import { connect } from 'react-redux';
import {productList} from '../../api/rest_product';
import SearchProduct from '../../forms/search_product';
import {AddToInvoiceProducts} from '../../../redux/actions/invoice_actions';
import $ from 'jquery'; 
let InvoiceProductSearch = (props) =>{
		return (						

      <div className="modal fade" id="productSearchModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Buscar Producto</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              

                  <div className="col-md-12">    
                     <SearchProduct onSubmit={props.handleSubmit}/>
                     <div className="col-md-12 product_list">                                   
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Precio</th>           
                          </tr>         
                        </thead>
                        <tbody>
                          {props.list.map((producto, key) => {
                            return (
                              <tr id={"product_" + producto.id} name={producto.id} key={key} onClick={() => props.selectItem(producto)} className="product_item">  
                                <td>{producto.codigo}</td>
                                <td>{producto.nombre}</td>
                                <td >{producto.precio}</td>
                              </tr>
                            );
                           })
                          }
                        </tbody>
                      </table>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary btn-lg" data-dismiss="modal" onClick={props.agregarItems}>Agregar Seleccionados</button>
            </div>
          </div>
        </div>
      </div>

	   	
	)
	
}

const mapStateToProps = state  => {
  return {
    list: state.product_list
  };
};

const mapDispatchToProps = dispatch => {
    dispatch(productList({}));
  return {
    handleSubmit: function(values){
      dispatch(productList(values));
    },
    selectItem: function(product){
      selectRow(product);
    },
    agregarItems: function(){
      for(let i = 0; i < items.length; i++){
        dispatch(AddToInvoiceProducts(items[i]));
      }
      clearRows();
      items = [];
    }

  }
};

let items = [];

function clearRows(){
  $(".product_item").removeClass("product_select");
}
function selectRow(product){
  if ($("#product_" + product.id).hasClass("product_select")){
    $("#product_" + product.id).removeClass("product_select");
    items = items.filter(products => products.id !== product.id);
  } else{    
    items.push(product);
    $("#product_" + product.id).addClass("product_select");
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProductSearch);