import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {InputTextField, PasswordTextField, HideField} from "../tags";
import PhoneField from "../tags/phone_field";
import {PASSWORD_FORMAT, EMAIL_FORMAT} from '../constants'
let AccountForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
          <Field name="id" component={HideField} label="" className="hidde"/>
          <Field name="status"  component={HideField} value={props.userStatus}/>
	      	<Field name="nombre" component={InputTextField} label="Nombre *"/>
	        <Field name="apellido" component={InputTextField} label="Apellido *"/>
	        <Field name="email" component={InputTextField} label="Email *"/>
          <Field name="password" component={PasswordTextField} label="Contraseña"/>
          <Field name="password_confirmation" component={PasswordTextField} label="Confirmar Contraseña"/>
          <label> Ingrese una pregunta y una respuesta secreta, se utilizaran en caso de ser necesario restaurar la cuenta  </label>
          <Field name="pregunta_secreta" component={InputTextField} label="Pregunta Secreta *"/>
          <Field name="respuesta_secreta" component={PasswordTextField} label="Respuesta Secreta *"/>
          <Field name="respuesta_secreta_confirmation" component={PasswordTextField} label="Confirmar Respuesta Secreta *"/>          
	        <FieldArray name="telefonos" component={PhoneField} label="Telefonos"/>
	        <button type="submit" className="btn btn-primary btn-lg float-right">Aceptar</button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  } 
  if (!values.apellido) {
    errors.apellido = 'Campo Requerido'
  } 
  if (!values.email) {
    errors.email = 'Required'
  } else if (!EMAIL_FORMAT.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }

  if (!values.pregunta_secreta && values.status === INCOMPLETE) {
    errors.pregunta_secreta = 'Campo requerido'
  }
  if (!values.respuesta_secreta && values.status === INCOMPLETE) {
    errors.respuesta_secreta = 'Campo requerido'
  } else {
    if (!values.respuesta_secreta_confirmation && values.status === INCOMPLETE) {
      errors.respuesta_secreta_confirmation = 'Campo requerido';
    } else if (values.respuesta_secreta_confirmation !== values.respuesta_secreta){
      errors.respuesta_secreta_confirmation = 'Debe ser igual ala Respuesta Secreta';
    }
  }

  if (!values.password && values.status === INCOMPLETE) {
    errors.password = 'Campo Requerido';
  } else {
    if (!PASSWORD_FORMAT.test(values.password) && values.status === INCOMPLETE) {
      errors.password = 'Debe contener minimo 8 caracteres, incluyendo un número';
    } else {
      if (!values.password_confirmation && values.status === INCOMPLETE){
          errors.password_confirmation = "Campo Requerido"
      } else if(values.password_confirmation !== values.password) {
          errors.password_confirmation = "Debe ser igual a la Contraseña"
      }
    }
  }
 

  if (values.telefonos && values.telefonos.length > 0) {
  	const telArrayErrors = [];
  	values.telefonos.forEach((telefono, idx) => {
  		const telErrors = {};
  		if(!telefono.codigo_pais){
  			telErrors.codigo_pais = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.numero){
  			telErrors.numero = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.tipo){
  			telErrors.tipo = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  	});
  	if(telArrayErrors.length){
  		errors.telefonos = telArrayErrors;
  	}
  }
  

  
   return errors;
}

AccountForm = reduxForm({
  form: 'product', // a unique name for this form
  validate,
  enableReinitialize: true
})(AccountForm);


export default AccountForm;
const INCOMPLETE  = 'incomplete';