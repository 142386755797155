import React from 'react';
import InvoiceInfo from '../forms/invoice/invoice_info';
import {getInvoice,requestCreditNote, requestDebitNote,enviarCorreo} from '../api/rest_invoices'
import {connect} from 'react-redux';
import {printInvoice} from '../lib/print';
import {ConfirmationDialog} from '../dialogs';
import $ from 'jquery'; 
const InvoiceInfoPage =(props) => {
	return (
		<div className="row">
			<ConfirmationDialog id="creditNoteDialog" title="Nota de crédito" 
			message="¿Está seguro que desea Crear una Nota de Crédito?" handleSubmit={() => props.createCreditNote(props.invoice.clave)} />
			<ConfirmationDialog id="debitNoteDialog" title="Nota de Débito"
			 message="¿Está seguro que desea Crear una Nota de Débito?" handleSubmit={() => props.createDebitNote(props.invoice.clave)} />
			{ props.invoice !== undefined && props.invoice !== null &&
					<InvoiceInfo  invoice={props.invoice} print= {() => props.printBox(props.invoice)}
					creditNoteDialog={props.creditNoteDialog} debitNoteDialog={props.debitNoteDialog} reenviarCorreo={() =>props.reenviarCorreo(props.invoice.clave)}/>
			}
		</div>
    );
}

const mapStateToProps = state  => {
	return {
		invoice: state.invoice_info
	};
};
     
const mapDispatchToProps = (dispatch,props) => {
	const { id } =  props.computedMatch.params;
	dispatch(getInvoice(id));
	return {
		printBox: function(values){
			printInvoice(values);
    	},
    	creditNoteDialog: function(){
    		$("#creditNoteDialog").modal('show');
    	},
    	debitNoteDialog: function(){
    		$("#debitNoteDialog").modal('show');	
    	},
    	createDebitNote: function(clave){
    		dispatch(requestDebitNote(clave));
    	},
    	createCreditNote: function(clave){
    		dispatch(requestCreditNote(clave));
    	}
    	,
    	reenviarCorreo: function(clave){
    		dispatch(enviarCorreo(clave));
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoPage);