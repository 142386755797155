export const PRODUCTION_SERVER = "https://api.digifact.co.cr/v1/";
export const DEVELOPMENT_SERVER = "http://localhost:3001/v1/";
export const EMAIL_FORMAT = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PASSWORD_FORMAT = /^(?=.*\d)[a-zA-Z\d]{8,}$/i;
export const TIPO_DOCUMENTO = {
	"01": "Factura Electrónica", 
	"02": "Nota de Débito Electrónica",
	"03": "Nota de Crédito Electrónica", 
	"04": "Tiquete Eletrónico",
	"99": "Proforma"
}

export const DESCRIPCION_CAMPO = {
	"01": "Anula Documento de Referencia",
	"02": "Corrige texto documento de referencia",
	"03": "Corrige monto", 
	"04": "Referencia a otro documento",
	"05": "Sustituye comprobante provisional por contingencia.",
	"99": "Otros"
}


export const DOCUMENTO_EXONERACION = {
	"01": "Compras autorizadas",
	"02": "Ventas exentas a diplomáticos",
	"03": "Orden de compra (Instituciones Públicas y otros organismos)",
	"04": "Exenciones Dirección General de Hacienda",
	"05": "Zonas Francas",
	"99": "Otros"
}
export const DOCUMENTO_EXONERACION_SELECT = [
	{key: "01",	value: "Compras autorizadas"},
	{key: "02",	value: "Ventas exentas a diplomáticos"},
	{key: "03",	value: "Orden de compra (Instituciones Públicas y otros organismos)"},
	{key: "04",	value: "Exenciones Dirección General de Hacienda"},
	{key: "05",	value: "Zonas Francas"},
	{key: "99",	value: "Otros"}
]
export const CONDICIONES_VENTA = {
	"01": "Contado",
	"02": "Crédito",
	"03": "Consignación",
	"04": "Apartado",
	"05": "Arrendamiento con opción de compra",
	"06": "Arrendamiento en función financiera",
	"99": "Otros"
}