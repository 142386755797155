import React from 'react';
const HomePage = () => {
	return (
		<div className="home col-sm-12 col-lg-12">
			<br/>
			<div className="col-sm-10 offset-sd-0 col-lg-10 offset-lg-1">
				<div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
					<ol className="carousel-indicators">
				    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"> </li>
				    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
				    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
				  </ol>
				  <div className="carousel-inner">
				    <div className="carousel-item active">
				      <img className="d-block w-100" src="/images/page1.png?auto=yes&bg=777&fg=555&text=First slide" alt="First slide"/>
				      <div className="carousel-caption d-none d-md-block">
					    <h4>Volcán Arenal</h4>
					    <p>Majestuosa fotografía tomada al volcán Arenal durante un amancer en la Zona Norte de Costa Rica</p>
					  </div>
				    </div>
				    <div className="carousel-item">
				      <img className="d-block w-100" src="/images/page2.png?auto=yes&bg=666&fg=444&text=Second slide" alt="Second slide"/>
				      <div className="carousel-caption d-none d-md-block">
					    <h4>Factura digital</h4>
					    <p>Facture de forma simple y directa, sin necesidad de mediadores</p>
					  </div>
				    </div>
				    <div className="carousel-item">
				      <img className="d-block w-100" src="/images/page3.png?auto=yes&bg=555&fg=333&text=Third slide" alt="Third slide"/>
				      <div className="carousel-caption d-none d-md-block">
					    <p>Acceda obtenga sus resportes diarios, quincenales y mensuales, sin complicaciones.</p>
					  </div>
				    </div>
				  </div>
				  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
				    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
				    <span className="sr-only">Previous</span>
				  </a>
				  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
				    <span className="carousel-control-next-icon" aria-hidden="true"></span>
				    <span className="sr-only">Next</span>
				  </a>
				</div>
			</div>
			<div className="p-3 mb-2 col-sm-10 col-lg-10 offset-lg-1 about_page">
				<h1><strong>Digifact</strong>.cr <small>beta</small></h1>
				<hr/>
				<p>
					<strong>Digifact</strong> es una herramienta que le permitirá manejar sus facturas electrónicas requeridas por el <a href="https://www.hacienda.go.cr/contenido/14350-factura-electronica">Ministerio de Hacienda</a> de forma simple y directa. 
					Sin necesidad de ser expertos en el uso de sistemas informáticos, o en sistemas contables.<br/>
					Esta herramienta esta orientada a aquellas personas o microempresas que requiran emitir sus facturas electronicas sin mayor conocimiento de herramientas electrónicas.<br/>					
				</p>
				<h4>¿Por qué elegir <strong>Digifact</strong> sobre las demás herramientas del mercado?</h4>
				<p>					
					En el mercado existen muchas herramientas muy buenas claro esta!. Pero casi todas exigen que usted sea casi un experto en el aréa de la finanzas inclusive le exigen tener experiencia previa en el uso de sistemas informáticos(usar una computadora).<br/>
					<strong>Digifact</strong> esta pensado en esas personas que por su nivel de educación,  por el aréa en el que se desempeñan o por su edad, han tenido acceso limitado al uso de herramientas digítales, o simplemente no han necesitado utilizarlas.<br/>
				</p>
				<h4>¿Cúales son los requisitos para utilizar <strong>Digifact</strong>?</h4>
				<p>					
					<strong>Digifact</strong> es un herramienta que depende del sistema de del Ministerio de Hacienda, por lo tanto el usuario(llámese persona o micro empresa), deberá estar registrado en el sistema de
					<a target="_blank" rel="noopener noreferrer" href="https://www.hacienda.go.cr/ATV/Login.aspx"> Administración Tributaria Virtual</a> del Ministerio de Hacienda
				</p>
				<h4>¿Cuánto cuesta <strong>Digifact</strong>?</h4>
				<p>
					
					<strong>Digifact</strong> se encuentra en una versión Beta, esto quiere decir es una versión completa y funcional, sin embargo puede presentar pequeños problemas (visuales, o de rendimiento).
					Se encuentra en constante mejora, por lo cual se agradece de su retroalientación.<br/>
					Por ser una versión Beta no se cobrará hasta llegar a una versión, más definitiva. sin embargo se agradece de su contribución para continuar con el desarrollo y el mantenimiento de la herramienta.

				</p>
			</div>
	    </div>
	)
}
export default HomePage;