import React from 'react';
import { Field} from 'redux-form';
import {NumberField, SelectField, InputTextField} from './index';
const PhoneField = ({ fields, meta: { error, submitFailed } }) => (
	<div className="row">  
	  <div className="col-md-12 ">
	    <ul className="phone_list">
	        {fields.map((phone, index) => (
	        <li key={index} className="phone_list_item">
	          <div className="form-group card">
	            <fieldset>
	              <label><strong>Teléfono</strong></label>
	              <Field type="hidden" name={`${phone}.id`} component="input"/>
	              <div className="form-row">
	                <div className="form-group col-md-2 offset-md-1">
	                  <Field name={`${phone}.codigo_pais`} component={NumberField} label="Codigo país"/>
	                </div>
	                <div className="form-group col-md-5">
	                  <Field name={`${phone}.numero`} component={InputTextField} label="Numero"/>
	                </div>
	                <div className="form-group col-md-3">
	                  <Field name={`${phone}.tipo`} component={SelectField} label="Tipo Telefono *" values={TIPO_TELEFONO} />
	                </div>
	                <div className="form-group col-md-1">
	                  <input type="button" className="btn btn-danger delete_phone" value="Eliminar"  onClick={() => fields.remove(index)}/>
	                </div>
	              </div>
	            </fieldset>
	          </div>
	        </li>      
	      ))}
	        <li className="phone_list_item">
	          <div className="row ">
	            <div className="col-md-3 offset-md-9">
	              <button type="button" onClick={() => fields.push({tipo:"01"})} className="btn btn-link btn-lg float-right">  Agregar telefono </button>
	                {submitFailed && error && <span>{error}</span>}
	            </div>
	          </div>
	        </li>
	    </ul>
	  </div>
	</div>     
)
export default PhoneField;

const TIPO_TELEFONO = [
  {key: 1, value: "Principal"},
  {key: 2, value: "Movil"},
  {key: 3, value: "Fax"},
  {key: 4, value: "Trabajo"}
]