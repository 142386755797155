import axios from 'axios';
import {URL,setTokenHeader, errorMessage, formatError,successMessage, closeMessages}  from './index';
import {INVOICE_ID,INVOICES, INVOICE_INFO, INVOICE_DOLAR, INVOICE_MESSAGE } from '../../redux/actions/invoice_actions';
import $ from 'jquery'; 

export const sendInvoice = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {  
       return axios.post(URL() + "invoices", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201) {
            dispatch({
              type: INVOICE_INFO,
              invoice_info: res.data.factura
            }); 
            $('#invoiceAlert').modal('toggle');
          }          
        }).catch((error) => {
            $("#loading_spinner").hide();
        })

  };
}

export const getInvoiceList = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices", {params},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
            dispatch({
              type: INVOICES,
              invoices: res.data
            });       
        }).catch((error) => {
          $("#loading_spinner").hide();
        })

  };
}

export const getInvoice = (id) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices/"+ id,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: INVOICE_INFO,
              invoice_info: res.data.factura
            });       
        }).catch((error) => {
        })

  };
}

export const getNextInvoiceId = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices/next", {params},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200) {
            dispatch({
              type: INVOICE_ID,
              id: res.data.id
            });
            dispatch({
              type:INVOICE_DOLAR,
              dolar:res.data.dolar
            })
          }
        }).catch((error) => {
            
        })

  };
}

export const requestCreditNote = (clave) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices/nota_credito/" + clave,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201) {
            alert("La Nota de Crédito ha sido creada satisfactoriamente");
          }
        }).catch((error) => {
            $("#loading_spinner").hide();
        })

  };
}

export const requestDebitNote = (clave) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices/nota_debito/" + clave,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201) {
            alert("La Nota de Débito ha sido creada satisfactoriamente");
          }
        }).catch((error) => {
            $("#loading_spinner").hide();
        })

  };
}

export const enviarCorreo = (clave) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "invoices/send_mail/" + clave,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
        }).catch((error) => {
            $("#loading_spinner").hide();
        })

  };
}

export const uploadXml= (invoice) => {
  $("#loading_spinner").show();
  closeMessages();
  setTokenHeader();
  const formData = new FormData();
  formData.append('file', invoice.file);
  return (dispatch) => {    
       return axios.post(URL() + "invoices/upload_xml", formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
            if(res.data.factura.error) {
              errorMessage("El documento ya se  encuentra <strong>" + res.data.factura.estado + "</strong> en el Sistema");
            } else {
              dispatch({
                type:INVOICE_MESSAGE,
                invoice_message:res.data.factura
              })
            }

          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al almacenar la información de hacienda, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const aceptDocument = (params) => {
  $("#loading_spinner").show();
  closeMessages();
  setTokenHeader();
  return (dispatch) => {  
       return axios.post(URL() + "invoices/acept_document", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 200) {
            successMessage("Documento ha sido enviado al sistema ATV exitosamente...")
            dispatch({
              type:INVOICE_MESSAGE,
              invoice_message: null
            })
          }          
        }).catch((error) => {
            $("#loading_spinner").hide();
        })

  };
}