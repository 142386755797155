import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
import { SelectField, RenderDateTimePicker} from "../tags";
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment';
momentLocaliser(moment)

let SearchInvoice = (props) =>{
	const { handleSubmit} = props;
	 return (
	 	 <form onSubmit={handleSubmit}>
	 	 	<h4>Ingrese para Buscar los documentos Electronicos</h4>
	 	 	<div className="form-row align-items-center">
		 	 	<div className="col-md-3 my-1">
					<Field component={SelectField} type="text" name="tipo_documento" className="form-control form-control-lg" placeholder="Tipo" values={TIPO_DOCUMENTO}/>
				</div>
				<div className="col-md-3 my-1">
					<Field component={RenderDateTimePicker} showTime={false} type="number" name="fecha_inicio" className="form-control form-control-lg" placeholder="Fecha Inicio"/>
				</div>
				<div className="col-md-3 my-1">
					<Field component={RenderDateTimePicker} showTime={false} type="number" name="fecha_fin" className="form-control form-control-lg" placeholder="Fecha Fin"/>
				</div>
				<div className="col-auto my-2">
					<button className="btn btn-secondary btn-lg" type="submit">	
						<FontAwesomeIcon icon="search" size="1x"/>
						&nbsp;Buscar
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
SearchInvoice = reduxForm({
  form: 'search_invoice',
  enableReinitialize: true
})(SearchInvoice);

export default SearchInvoice;

const TIPO_DOCUMENTO = [
	{key: "01", value: "Factura electrónica"}, 
	{key: "02", value: "Nota de débito electrónica"},
	{key: "03", value: "Nota de crédito electrónica"}, 
	{key: "04", value: "Tiquete Eletrónico"}
]