import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {InputTextField, HideField, SelectField} from "../tags";
import PhoneField from "../tags/phone_field";
let UserForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
	      	<Field name="nombre" component={InputTextField} label="Nombre *"/>
	        <Field name="apellido" component={InputTextField} label="Apellido *"/>
          <Field name="username" component={InputTextField} label="Nombre de usuario"/>
	        <Field name="email" component={InputTextField} label="Email *"/>
          <Field name="role"  component={SelectField} label="Tipo cuenta" values={ROLES}/>
          <Field name="status"  component={HideField} />
	        <FieldArray name="telefonos" component={PhoneField} label="Telefonos"/>
	        <button type="submit" className="btn btn-primary btn-lg  float-right">Aceptar</button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  } 
  if (!values.apellido) {
    errors.apellido = 'Campo Requerido'
  } 
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }
  if (!values.username) {
    errors.username = 'Campo Requerido'
  }

  if (values.telefonos && values.telefonos.length > 0) {
  	const telArrayErrors = [];
  	values.telefonos.forEach((telefono, idx) => {
  		const telErrors = {};
  		if(!telefono.codigo_pais){
  			telErrors.codigo_pais = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.numero){
  			telErrors.numero = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  		if(!telefono.tipo){
  			telErrors.tipo = 'Campo Requerido';
  			telArrayErrors[idx] = telErrors;
  		}
  	});
  	if(telArrayErrors.length){
  		errors.telefonos = telArrayErrors;
  	}
  }
  
   return errors;
}

UserForm = reduxForm({
  form: 'product', // a unique name for this form
  validate,
  enableReinitialize: true
})(UserForm);

const ROLES = [
  {key: "admin", value: "Administrador"},
  {key: "cachier", value: "Cajero"},
  {key: "counter", value: "Contador"}
]
export default UserForm;