import React from 'react';
import AtvForm from '../forms/atv_form';
import AtvFileForm from '../forms/atv_file_form';
import {connect} from 'react-redux';
import {updateAtv, getAtv, updateAtvKey} from '../api/rest_company';
const AtvPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
		<h2>Información de su cuenta de hacienda</h2>
			<p className="text-justify">
				A continuación encontrara su información de hacienda. <small>Recuerde que esta información es obligatoria para acceder a los sevicios brindados por tributación.</small>
				<br/>Si asi lo requiere actualice sus datos y presione <strong>Aceptar</strong>
			</p>
			<div className="col-lg-12">
				<h4>Usuario y Contraseña generados</h4>
				<AtvForm id="atvForm" onSubmit ={props.handleSubmit}   initialValues={props.initialValues}/>

			</div>		
			<div className="clear col-lg-12">
				<hr/>
				<h4>Llave Criptográfica</h4>
				<p className="text-justify">
					La Llave Criptográfica es proveida por el sistema de hacienda, si no la posee dirijase a este <a rel="noopener noreferrer" target="_blank" href="https://www.hacienda.go.cr/ATV/Login.aspx" > Sitio</a> para obtenerla
				</p>

				<p className="text-justify">
				 	Seleccione la Llave Criptográfica y presione <strong>Subir Archivo</strong>
				</p>
				<AtvFileForm id="atvFileForm" onSubmit ={props.uploadKey} />
				<br/>
			</div>

		</div>
    );
}
     
const mapStateToProps = (state)  => {
	return {
		initialValues: state.atv
	};
};
const mapDispatchToProps = dispatch => {
	dispatch(getAtv());
	return {
		handleSubmit: function(values){			
			let atv = {
				atv: values
			}
		   dispatch(updateAtv(atv))
    	},
    	uploadKey: function(values){
		   dispatch(updateAtvKey(values))
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AtvPage);