import React from 'react';
import Sidebar from '../layout/sidebar'
import Content from '../layout/content'
const Invoices = () => {
	return (
		<div className="row body">
	   	  <Sidebar />
	   	  <Content />
	    </div>
	)
}
export default Invoices;