import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
let SearchProduct = (props) =>{
	const { handleSubmit} = props;
	 return (
	 	 <form onSubmit={handleSubmit}>
	 	 	<h3>Ingrese la información del Producto</h3>
	 	 	<div className="form-row align-items-center">
		 	 	<div className="col-md-5 my-1">
					<Field component="input" type="text" name="nombre" className="form-control form-control-lg" placeholder="Nombre"/>
				</div>
				<div className="col-md-5 my-1">
					<Field component="input" type="text" name="descripcion" className="form-control form-control-lg" placeholder="Descripción"/>
				</div>
				<div className="col-md-2 my-2">
					<button className="btn btn-secondary btn-lg float-right" type="submit">	
						<FontAwesomeIcon icon="search" size="1x"/>
						&nbsp;Buscar
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
SearchProduct = reduxForm({
  form: 'search_product',
  enableReinitialize: true
})(SearchProduct);

export default SearchProduct;