import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QRCode } from 'react-qr-svg';
import InvoiceInfoPrint from './invoice_info_print';
import {URL} from  '../../api';
var currencyFormatter = require('currency-formatter');
const InvoiceInfo = props => (
	<div id="infopage"className="col-md-12">
		{props.invoice !== undefined &&
		<div className="hidde">
			<InvoiceInfoPrint invoice={props.invoice} />		
		</div>
		}
		<br/>
		{props.invoice !== undefined &&
		<div className="jumbotron col-lg-10 offset-1">
			<h3 className="display-6">Detalle de la factura</h3>
			<div className="row">
				<div className="col-md-7">
					<p className="lead">
					 	<strong>Tipo Documento: </strong> {TIPO_DOCUMENTO[props.invoice.tipo_documento]}<br/>
						<strong>Clave: </strong>{props.invoice.clave} <br/>
						<strong>Número: </strong>{props.invoice.numero}<br/>				
						<strong>Fecha: </strong>{props.invoice.fecha_emision}<br/>
					</p>					
				{props.invoice.receptor !== null && props.invoice.receptor !== undefined &&
					<div>
						<h4>Cliente</h4>
						<p className="lead">
							<strong>Nombre: </strong>{props.invoice.receptor.nombre} <br/>
							<strong>Email: </strong>{props.invoice.receptor.email}<br/>				
							<strong>Telefono: </strong>{props.invoice.receptor.telefono}<br/>
						</p>
					</div>
				}
				</div>
				<div className="col-md-2">				
					<QRCode
	                    bgColor="#FFFFFF"
	                    fgColor="#000000"
	                    level="L"
	                    style={{ width: 128 }}
	                    value={URL() + "comprobante/" + props.invoice.clave}
	                />
                </div>
                <div className="col-md-3">
					<div className="btn-group btn-group-vertical btn-group-lg " role="group" aria-label="Basic example">	
					{ (props.invoice.estado === "aceptado" && props.invoice.referencia === null &&
					  (props.invoice.tipo_documento === "01" || props.invoice.tipo_documento === "04")) &&
						<div>
							<button className="btn btn-primary  float-right invoice_button" onClick={props.creditNoteDialog}>				
								<FontAwesomeIcon icon="plus-square" size="2x"/>
								&nbsp; Nota de Crédito
							</button>
							<button className="btn btn-primary  float-right invoice_button" onClick={props.debitNoteDialog}>				
								<FontAwesomeIcon icon="plus-square" size="2x"/>
								&nbsp; Nota de Débito
							</button>				
						</div>
					}
						<a target="_blank" rel="noopener noreferrer" type="media_type" download={props.invoice.clave} href={URL() + "/comprobante/" + props.invoice.clave}
						 className="btn btn-primary float-right invoice_button" alt="Descargar factura">				
							<FontAwesomeIcon icon="download" size="2x"/>
							&nbsp; Descargar
						</a>
						<a target="_blank"  rel="noopener noreferrer"  type="media_type" download={props.invoice.clave} href={URL() + "/pdf/" + props.invoice.clave}
						 className="btn btn-primary float-right invoice_button" alt="Descargar factura">				
							<FontAwesomeIcon icon="download" size="2x"/>
							&nbsp; Descargar PDF
						</a>
						{props.invoice.tipo_documento === "01" &&
						<button className="btn btn-primary float-right invoice_button" onClick={props.reenviarCorreo}>				
							<FontAwesomeIcon icon="envelope" size="2x"/>
							&nbsp; Re-enviar correo
						</button>
						}
						<button className="btn btn-primary float-right invoice_button" onClick={props.print}>				
							<FontAwesomeIcon icon="print" size="2x"/>
							&nbsp; Imprimir
						</button>
					</div>
				</div>
				<br/>
			</div>
			{ props.invoice.referencia !== null &&
			<div className="row">
				<div className="col-md-12 text-center my-3">
					Este documento ha sido referenciado por: {TIPO_DOCUMENTO[props.invoice.tipo_referencia]} {props.invoice.referencia}
				</div>
			</div>
			}
			<div className="col-md-12">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>#</th>
							<th>Producto</th>
							<th>Cantidad</th>
							<th>Unidad</th>
							<th>Precio</th>
							<th>Impuesto</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody className=" list">
					{       props.invoice.detalles !== undefined &&
						props.invoice.detalles.map((product, idx) => {
							return (<tr key={idx}>
								<td>{product.numero_linea}</td>
								<td>{product.detalle}</td>
								<td>{product.cantidad}</td>
								<td>{product.unidad_medida}</td>
								<td>{currencyFormatter.format(product.precio_unitario, {code: props.invoice.codigo_moneda}) }</td>
								<td>{currencyFormatter.format(product.impuesto.monto, {code: props.invoice.codigo_moneda})}</td>
								<td>{currencyFormatter.format(product.monto_total_linea, {code: props.invoice.codigo_moneda})}</td>
							</tr>)
						})						
					}
					</tbody>
				</table>
			</div>
			<div className="col-md-12 col-lg-6 offset-lg-3">
				<h4>Resumen</h4>
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Detalle</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>Total Servicios Gravados</th>
							<td>{currencyFormatter.format(props.invoice.total_serv_gravados, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Servicios Exentos</th>
							<td>{currencyFormatter.format(props.invoice.total_serv_exentos, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Mercancias Gravadas</th>
							<td>{currencyFormatter.format(props.invoice.total_mercancias_gravados, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Mercancias Excentas</th>
							<td>{currencyFormatter.format(props.invoice.total_mercancias_exentas, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Gravado</th>
							<td>{currencyFormatter.format(props.invoice.total_gravado, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Excento</th>
							<td>{currencyFormatter.format(props.invoice.total_excento, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Venta</th>
							<td>{currencyFormatter.format(props.invoice.total_venta, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Descuentos</th>
							<td>{currencyFormatter.format(props.invoice.total_descuentos, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Venta Neta</th>
							<td>{currencyFormatter.format(props.invoice.total_venta_neta, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Impuesto</th>
							<td>{currencyFormatter.format(props.invoice.total_impuesto, {code: props.invoice.codigo_moneda})} </td>
						</tr>
						<tr>
							<th>Total Comprobante</th>
							<td>{currencyFormatter.format(props.invoice.total_comprobante, {code: props.invoice.codigo_moneda})} </td>
						</tr>
					</tbody>
				</table>
			</div>

		</div>
	}
	</div> 
)
export default InvoiceInfo;

const TIPO_DOCUMENTO = {
	"01": "Factura Electrónica", 
	"02": "Nota de Débito Electrónica",
	"03": "Nota de Crédito Electrónica", 
	"04": "Tiquete Eletrónico"
}
