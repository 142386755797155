import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
let SearchCabys = (props) =>{
	const { handleSubmit} = props;
	 return (
	 	 <form onSubmit={handleSubmit}>
	 	 	<h4>Ingrese la información del Producto (CABYS)</h4>
	 	 	<div className="form-row align-items-center">
				<div className="col-md-10 my-1">
					<Field component="input" type="text" name="q" className="form-control form-control-lg" placeholder="Descripción"/>
				</div>
				<div className="col-md-2 my-2">
					<button className="btn btn-secondary btn-lg float-right" type="submit">	
						<FontAwesomeIcon icon="search" size="1x"/>
						&nbsp;Buscar
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
SearchCabys = reduxForm({
  form: 'search_cabys',
  enableReinitialize: true
})(SearchCabys);

export default SearchCabys;