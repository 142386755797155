import React from 'react';
import ClientForm from '../forms/client_form';
import {addClient} from '../api/rest_client';
import {cleanClient} from '../../redux/actions';
import {connect} from 'react-redux';
const NewClientPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<br/>
			<h4>Ingrese la información relacionada con el Cliente</h4>
			<hr/>
			<ClientForm id="newClientForm" onSubmit ={props.handleSubmit} initialValues={{tipo_cedula:"01"}} />
			<br/>
			<div className="clear "/>
		</div>
    );
}
     
     
const mapDispatchToProps = dispatch => {
	dispatch(cleanClient());
	return {
		handleSubmit: function(values){
		    let client = {
		    	cliente: values
		    }	    
		    dispatch( addClient(client));
    	}
    }
};
export default connect(null, mapDispatchToProps)(NewClientPage);