import React from 'react';
import { QRCode } from 'react-qr-svg';
import {URL} from  '../../api';
const InvoiceInfoPrint = ({invoice}) => (
	<div>
	{invoice !== undefined && invoice !== null &&
	<div id="infopageprint" style={body}>
		<br/>

		{invoice.emisor !== undefined &&
			<div>
				<h2 style={h2}> {invoice.emisor.nombre_comercial}</h2>
				<h4 style={h4}><strong>Identificacion: </strong> {invoice.emisor.identification}</h4>
				<h4 style={h4}><strong>Telefono: </strong> {invoice.emisor.telefono}</h4>
				<h4 style={h4}><strong>Email: </strong> {invoice.emisor.email}</h4>
			</div>
		}

		<div>
		{invoice !== undefined &&
			<div style ={line}>
				<strong>Clave Numérica: </strong><span>{invoice.clave.substring(0,21)}</span> <span>{invoice.clave.substring(22,50)}</span> <br/>
				<strong>Número: </strong>{invoice.numero}<br/>				
				<strong>Fecha: </strong>{invoice.fecha_emision}<br/>
				<strong>Moneda: </strong>{invoice.codigo_moneda}<br/>
			</div>
		}
			{invoice.receptor !== null && invoice.receptor !== undefined &&
			<div style ={line}>
				<h4 style={h4}>Cliente</h4>
				<strong>Nombre: </strong>{invoice.receptor.nombre} <br/>
				<strong>Email: </strong>{invoice.receptor.email}<br/>				
				<strong>Telefono: </strong>{invoice.receptor.telefono}<br/>
				<br/>
			</div>
			}
			<div style ={line}>
				<h4 style={h4}>Detalle</h4>
				<table style = {table}>
					<thead>
						<tr style={tr}>
							<th style={c1}>Producto</th>
							<th style={c2}>Cant</th>
							<th style={c3}>Total</th>							
							<th style={c4}></th>
						</tr>
					</thead>
					<tbody className={tbody}>
					{invoice.detalles !== undefined &&
						invoice.detalles.map((product, idx) => {
							return (<tr key={idx}>								
								<td style={c1}>{product.detalle}</td> 
								<td style={c2}>{product.cantidad}</td>
								<td style={c3}>{product.monto_total_linea}</td>
								<td style={c4}>{product.impuesto.monto > 0 ? "G" : "E"}</td>
							</tr>)
						})						
					}
					</tbody>
				</table>
				<div>
					G=Gravado E=Exento
				</div>
				
				<table style = {table_resumen}>
					<thead>
						<tr style={tr}>
							<th style={tc1}>Detalle</th>
							<th style={tc2}>Total</th>
						</tr>
					</thead>
					<tbody  className={tbody}>
						<tr style={tr}>
							<td style={tc1}>Subtotal</td>
							<td style={tc2}>{invoice.total_venta_neta}</td>
						</tr>
						<tr style={tr}>
							<td style={tc1}>Descuentos</td>
							<td style={tc2}>{invoice.total_descuentos}</td>
						</tr>						
						<tr style={tr}>
							<td style={tc1}>Impuestos </td>
							<td style={tc2}>{invoice.total_impuesto}</td>
						</tr>
						<tr style={tr}>
							<td style={tc1}>Total</td>
							<td style={tc2}>{invoice.total_comprobante}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<br/>
			<QRCode  bgColor="#FFFFFF" fgColor="#000000" level="L"style={qr} value={URL() + "comprobante/" + invoice.clave} />
		<div style={centerLine}>
			Utilize este código QR para descargar el archivo XML
		</div>
		<div style={centerLine}>
				Autorizada mediante resolución Nº DGT-R-48-2016 del 7 de octubre de 2016<br/>
				Elaborado por https://digifact.cr<br/>
				----- fin del documento -----
		</div>
	</div> 
	}
	</div>
)
export default InvoiceInfoPrint;
let centerLine = {
	display:" block",
	"textAlign":" center",
	"fontSize": "8pt"
}
let qr = {
	display:"block",
	margin: "10px auto",
	width: "128px",
	clear: "both"
}
let h2 ={
	"fontSize": "12pt",
	width: "100%",
	display:" block",
	"textAlign":" center",
	"fontWeight": "bold"
}
let h4 ={
	"fontSize": "10pt",
	"fontWeight": "bold",
	"textAlign" : "center",
	"padding" : "0",
	"margin" : "0",
}

let body = {
	width: "100%",
	display:" block",
}
let line = {
	width: "100%",
	 display:" block",
	"fontSize": "10pt",
	"textAlign" : "justify",
	"borderBottom":"1px dotted #ccc"
}
let table_resumen = {
	"width":"80%",
	"display":"table-block",
	"float":"right"
}
let table = {
	"width":"100%",
	"display":"table-block"
}

let tbody =  {
	"width":"100%",
	"display":"inline-table"
}
let tr = {
	"width":"100%"
}

let tc1 = {
	"width":"49%",
	"fontSize":"10pt",
	"textAlign":"left",
}
let tc2 = {
	"width":"49%",
	"textAlign":"right",
	"fontSize":"10pt"
}

let c1 = {
	"width":"70%",
	"fontSize":"10pt",
	"textAlign":"left",
}
let c2 = {
	"width":"10%",
	"textAlign":"center",
	"fontSize":"10pt"
}
let c3 = {
	"width":"15%",
	"textAlign":"right",
	"fontSize":"10pt"
}
let c4 = {
	"width":"5%",
	"textAlign":"right",
	"fontSize":"10pt"
}
