import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import $ from 'jquery';

class SidebarButton extends Component{
	constructor(props) {
		super(props);
		this.state= {
			id: props.name.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,''),
			name: props.name,
			icon: props.icon,
			to: props.to		
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e){
		$(".sidebar_button").removeClass('active');
		$("#" + this.state.id).addClass("active")
	}

	render() {
		const icon = this.state.icon;
		const name = this.state.name;
		const id = this.state.id;
		let classes = "sidebar_button list-group-item bg-secondary text-white"; 
		
		return (
			<Link to={this.state.to} className={classes} onClick={this.handleClick} id={id}  >									    	
					<label>
					<FontAwesomeIcon icon={icon} size="1x"/>
					&nbsp;{name}
					</label>
		    </Link>
	    );
	}
}        

export { SidebarButton as default }