import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/app.css'
import './css/form.css'
import './css/print.css'
import './css/resolution.css'
import 'react-widgets/dist/css/react-widgets.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Store, {persistor} from './redux/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faKey, faUsers, faFile, faBookOpen, faPlusCircle, faSearch, faCalendar,faCalendarAlt,faEnvelope,
		 faUserPlus, faAddressBook,faFileAlt, faFileMedical,faListAlt, faCogs, faSignOutAlt,faPlus,faPercentage,
		 faCog, faUser,faUsersCog, faEye,faTrashAlt,faUserCog, faBuilding,faEdit,faPrint,faInfo,faPlusSquare,faDownload,
		 faCheckSquare,faLocationArrow,faCheck, faPaperPlane, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';
library.add(faHome, faKey, faUsers, faFile, faBookOpen, faPlusCircle, faSearch,faUserPlus, faDownload,faPlus,faEnvelope,
		faAddressBook,faFileAlt,faCheckSquare,faCheck,faPrint,faPlusSquare,faCalendar,faCalendarAlt,faSignOutAlt,faPercentage,
			faFileMedical,faListAlt, faCogs,faUser, faUsersCog,faEye, faTrashAlt,faUserCog, faCog,faBuilding, faEdit,faLocationArrow,faInfo,faPaperPlane,faForward,faBackward)

ReactDOM.render(
	<Provider store={Store}>
		<PersistGate loading={null} persistor={persistor}>
		<BrowserRouter forceRefresh={true}>		
		{/*<BrowserRouter>*/}
		    	<App />    	
			</BrowserRouter>
		</PersistGate>
	</Provider>
,document.getElementById('root'));