 import {InitialReport,InitialBalanceReport,InitialAcceptedReport,ACCEPTED_REPORT, REPORT,BALANCE_REPORT} from '../actions/report_actions';

 export const ReportReducer = (state = InitialReport, action) => {
	if (action.type === REPORT){
		return action.report;
	}
	return state;
}

export const ReportAcceptedReducer = (state = InitialAcceptedReport, action) => {
	if (action.type === ACCEPTED_REPORT){
		return action.accepted_report;
	}
	return state;
}
 export const ReportBalanceReducer = (state = InitialBalanceReport, action) => {
	if (action.type === BALANCE_REPORT){
		return action.balance_report;
	}
	return state;
}