import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, PasswordTextField} from "../tags";
import { connect } from 'react-redux';
import {PASSWORD_FORMAT, EMAIL_FORMAT} from '../constants'
let RegisterForm2 = (props) =>{
	const { handleSubmit,previousPage} = props;
	return (						
		<div >				
			<form className="login_form" onSubmit={handleSubmit}>
				  <h3>Información del Usuario</h3>
					<Field name="user_nombre" component={InputTextField} label="Nombre *"/>
					<Field name="user_apellido" component={InputTextField} label="Apellido"/>
          <Field name="username" component={InputTextField} label="Nombre de Usuario"/>
					<Field name="user_email" component={InputTextField} label="Email"/>
          <Field name="user_password" component={PasswordTextField} label="Contraseña"/>
          <Field name="user_password_confirmation" component={PasswordTextField} label="Confirmar Contraseña"/>
          <button type="submit" className="btn btn-primary btn-lg float-right">
            <label>Siguiente</label>
          </button>
					<button  className="btn btn-secundary btn-lg float-right" onClick={previousPage}>
            Antras
          </button>
			</form>
      <div className="clearfix"><br/></div>
      <div className="clearfix"><br/></div>
		</div>
	)
	
}

const validate = values => {
  const errors = {};
  if (!values.user_nombre) {
    errors.user_nombre = 'Campo Requerido'
  }
  if (!values.user_apellido) {
    errors.user_apellido = 'Campo Requerido'
  }
  if (!values.username) {
    errors.username = 'Campo Requerido'
  }

  if (!values.user_email) {
    errors.user_email = 'Required'
  } else if (!EMAIL_FORMAT.test(values.email)) {
    errors.user_email = 'Dirección correo invalida'
  }

 if (!values.user_password) {
    errors.user_password = 'Campo Requerido';
  } else {
    if (!PASSWORD_FORMAT.test(values.user_password)) {
      errors.user_password = 'Debe contener minimo 8 caracteres, incluyendo un número';
    } else {
      if (!values.user_password_confirmation){
          errors.user_password_confirmation = "Campo Requerido"
      } else if(values.user_password_confirmation !== values.user_password) {
          errors.user_password_confirmation = "Debe ser igual a la Contraseña"
      }
    }
  }
  
  return errors;
}

RegisterForm2 = reduxForm({
  form: 'registerForm', 
  destroyOnUnmount: false, 
  forceUnregisterOnUnmount: true, 
  validate
})(RegisterForm2);

const mapDispatchToProps = dispatch => {
	return {
	
	};
};


export default connect(null, mapDispatchToProps)(RegisterForm2);