import React from 'react';
import InvoiceForm2 from '../forms/invoice/invoice_form_2';
import {sendInvoice,getNextInvoiceId} from '../api/rest_invoices';
import { invoiceBody} from '../lib/utils';
import {cleanInvoice,addTipoDocumento} from '../../redux/actions/invoice_actions';
import {connect} from 'react-redux';
import $ from 'jquery'; 
import {AlertDialog} from '../dialogs';
const NewElectronicTikectPage =(props) => {
	return (
		<div className="Row">
			<h4 className="text-center">Nuevo Tiquete Electrónico</h4>
			<InvoiceForm2 id="newElectronicTikectForm" handleSubmit={props.handleSubmit} />
			<AlertDialog id="invoiceAlert"
				message="Tiquete Electrónico Satisfacatoriamente...  Recuerde validar el estado enviado por tributación"
				title="Tiquete Electrónico" handleSubmit={() =>props.successSubmit(props.invoiceInfo.id)}/>
			<AlertDialog id="invoiceStatusAlert"
				message="Debe Agregar almenos un producto"
				title="Tiquete Electrónico" handleSubmit={props.handleSubmit}/>
		</div>
    );
}
   
const mapStateToProps = state  => {
	return {
		invoiceInfo: state.invoice_info,
		invoice: state.invoice,	
	};
};     
     
const mapDispatchToProps = dispatch => {
	dispatch(cleanInvoice());
	dispatch(getNextInvoiceId( {sucursal_id: 1,terminal_id: 1, tipo_documento: "04"}));
	dispatch(addTipoDocumento("04"));
	return {
		handleSubmit: function(invoice){			
			if (invoice.products !== undefined && invoice.products.length > 0) {
				let _invoice = {"factura": invoiceBody(invoice)};
				console.log(_invoice)
				dispatch(sendInvoice(_invoice));
			} else {
				$("#invoiceStatusAlert").modal('toggle');
			}
		},
    	successSubmit: function(id){
			window.location = "/facturas/info/" + id;
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewElectronicTikectPage);