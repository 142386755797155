import React from 'react';
import InvoiceForm from '../forms/invoice/invoice_form';
import {getNextInvoiceId} from '../api/rest_invoices';
import {cleanInvoice,addTipoDocumento} from '../../redux/actions/invoice_actions';
import {connect} from 'react-redux';
import {AlertDialog} from '../dialogs';
const NewCreditNotePage =(props) => {
	return (
		<div className="Row">
			<h4 className="text-center">Nueva Nota de Crédito</h4>
			<InvoiceForm id="newinvoiceForm" handleSubmit={props.handleSubmit} />
			<AlertDialog id="invoiceAlert"
				message="Nota de Crédito Recibida Satisfacatoriamente...  Recuerde validar el estado enviado por tributación"
				title="Nota de Crédito" handleSubmit={() => props.successSubmit(props.invoice.id)}/>
			<AlertDialog id="invoiceStatusAlert"
				message="Debe Agregar almenos un producto"
				title="Nota de Crédito" />
		</div>
    );
}
     
const mapStateToProps = state  => {
	return {
		invoice: state.invoice_info,
	};
};     
     
const mapDispatchToProps = dispatch => {
	dispatch(cleanInvoice());
	dispatch(getNextInvoiceId( {sucursal_id: 1,terminal_id: 1, tipo_documento: "03"}));
	dispatch(addTipoDocumento("03"));
	return {
		handleSubmit: function(values){
		    console.log(values);
    	},
    	successSubmit: function(id){
			window.location = "/facturas/info/" + id;
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCreditNotePage);