export const SELECT_MENU = "SELECT_MENU";
export const JWT = "JWT";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const CLIENT_LIST = "CLIENT_LIST";
export const CLIENT = "CLIENT";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const CABYS_PRODUCT_LIST = "CABYS_PRODUCT_LIST";
export const PRODUCT_SERVICE_CODE = "PRODUCT_SERVICE_CODE";
export const PRODUCT_CODE_TAXES = "PRODUCT_CODE_TAXES";
export const PRODUCT_TAXES = "PRODUCT_TAXES";
export const PRODUCT = "PRODUCT";
export const INVENTORY = "INVENTORY";
export const INVENTORY_LIST = "INVENTORY_LIST";
export const USER = "USER";
export const USER_LIST = "USER_LIST";
export const ATV = "ATV";
export const COMPANY = "COMPANY";
export const ADDRESS = "ADDRESS";
export const PROVINCIAS = "PROVINCIAS";
export const CANTONES = "CANTONES";
export const DISTRITOS = "DISTRITOS";
export const BARRIOS = "BARRIOS";
export const ATV_STATUS = "ATV_STATUS"

export const initialProduct = {
	nombre:"", 
	descripcion:"", 
	precio: 0, 
	unidad: "unid", 
	codigo_impuesto:"01",
	tarifa_impuesto: 0.13,
	cod_producto:"01", 
	codigo: "1",
	tipo_unidad: "Unid", 
	codigo_tarifa: "08", 
	codigo_servicio: "",
	exento: false
}
export const cleanProduct = option => {

	return {
		type: PRODUCT,
		product: initialProduct
	}
}
export const cleanProductList = option => {
	return {
		type: PRODUCT_LIST,
		product_list: []
	}
}

export const cleanCabysProductList = option => {
	return {
		type: CABYS_PRODUCT_LIST,
		product_list: []
	}
}

export const updateProductCodeService = codigo_servicio => {
	
	return{
		type: PRODUCT_SERVICE_CODE,
		codigo_servicio
	}
}

export const updateProductCodeTaxes = codigo_tarifa => {
	
	return{
		type: PRODUCT_CODE_TAXES,
		codigo_tarifa
	}
}
export const updateProductTaxes = tarifa_impuesto => {
	
	return{
		type: PRODUCT_TAXES,
		tarifa_impuesto
	}
}

export const initialInventory = {
	product_id: -1, 
	cantidad: 0, 
	proveedor: "", 
	detalle: ""	
}
export const cleanInventory = option => {

	return {
		type: INVENTORY,
		product: initialInventory
	}
}

export const cleanInventoryList = option => {
	return {
		type: INVENTORY_LIST,
		product_list: []
	}
}

export const updateMenu = option => {
	return {
		type: SELECT_MENU,
		menu_option: option
	}
}



export const loginStatus = option => {
	return {
		type: LOGIN_STATUS,
		login_status: option
	}
}

export const atvStatus = option => {
	return {
		type: ATV_STATUS,
		atv_status: option
	}
}
export const logout = option => {
	return {
		type: LOGIN_STATUS,
		login_data: {logged:false}
	}
}

export const cleanClient = option => {

	return {
		type: CLIENT,
		client: {}
	}
}


export const cleanUser = option => {
	return {
		type: USER,
		user: {}
	}
}
export const cleanAtv = option => {
	return {
		type: ATV,
		atv: []
	}
}

export const cleanAtvStatus = option => {
	return {
		type: ATV_STATUS,
		atv_status: false
	}
}

export const cleanCompany = option => {
	return {
		type: COMPANY,
		company: []
	}
}

export const cleanAddress = option => {
	return {
		type: ADDRESS,
		address: {}
	}
}
export const cleanClientList = option => {
	return {
		type: CLIENT_LIST,
		client_list: {
			rows: [],
			page: 1,
			total: 0
		}
	}
}


