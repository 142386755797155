import React, { Component } from 'react';
import RecoverQuestionForm from '../forms/recover_question_form';
import {connect} from 'react-redux';
class RecoverQuestionPage extends Component {
	constructor(props){
		super(props);
	    this.handleSubmit = props.handleSubmit;
	}

	render(){
		return (
			<div className="col-md-12 ">
				<br/>
				<h2 className="text-center">
					Ingrese su Email  haga click en Aceptar
				</h2>
				<br/>
				<RecoverQuestionForm/>				
			</div>
	    );
	}
}
    
const mapDispatchToProps = dispatch => {
	return {
    }
};
export default connect(null, mapDispatchToProps)(RecoverQuestionPage);