import React from 'react';
import CompanyForm from '../forms/company_form';
import {getCompany, updateCompany} from '../api/rest_company';
import {connect} from 'react-redux';
const EditCompanyPage =(props) => {
	return (
		<div className="col-md-10 offset-1">
			<h2>Editar información de su Compañia</h2>
			<p className="text-justify">
				A continuación encontrara su información de usuario. Si asi lo requiere actualice sus datos y presione <strong>Aceptar</strong>
			</p>
			<CompanyForm id="companyForm" onSubmit ={props.handleSubmit}   initialValues={props.initialValues}/>
		</div>
    );
}
     
const mapStateToProps = (state)  => {
	return {
		initialValues: state.company
	};
};
const mapDispatchToProps = dispatch => {
	dispatch(getCompany());
	return {
		handleSubmit: function(values){
			let compania = {"compania": values};
			console.log(compania)
	    	dispatch(updateCompany(compania));
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyPage);