import React from 'react';
import UserForm from '../forms/user_form';
import {loadUser, updateUser} from '../api/rest_user';
import {connect} from 'react-redux';
const EditUsertPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<br/>
			<h3>Información usuario</h3>
			<p>
				Edite la información del  usuario
			</p>
			<UserForm id="editUserForm" onSubmit ={props.handleSubmit}  initialValues={props.initialValues}/>
		</div>
    );
}
const mapStateToProps = (state)  => {
	return {
		initialValues: state.user
	};
};
     
const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadUser(id));
	return {
		handleSubmit: function(values){
		    let user = {
		    	usuario: values
		    }	    
		    dispatch( updateUser(user,id));
    	}
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUsertPage);