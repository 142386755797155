import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {productList} from '../api/rest_product';
import {connect} from 'react-redux';
import SearchProduct from '../forms/search_product';
const ProductList = (props)=> (
	<div className="row">
		<div className="col-lg-10 offset-lg-1">
			<SearchProduct onSubmit={props.handleSubmit}/>
		</div>
		<div className="col-lg-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th>Codigo</th>
						<th>Producto</th>
						<th>Precio</th>						
						<th>Opciones</th>
					</tr>					
				</thead>
				<tbody className="list">
					{props.list.map((producto, key) => {
						return (
							<tr key={key}>	
								<td>{producto.codigo}</td>
								<td>{producto.nombre}</td>
								<td>{producto.precio}</td>
								<td>
									<Link to={"/productos/info/" + producto.id} alt="Ver usuario">
										<FontAwesomeIcon icon="eye" size="2x"/>
							    	</Link>
						    	</td>
							</tr>
						);
					 })
				  }
				</tbody>
			</table>
		</div>
	</div>	
)


const mapStateToProps = state  => {
	return {
		list: state.product_list
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(productList({}));
	return {
		handleSubmit: function(values){
		    dispatch(productList(values));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

