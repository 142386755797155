import React from 'react';
import { Field } from 'redux-form';
import {connect} from 'react-redux';
import {getProvincias,getCantones, getDistritos, getBarrios} from '../api/rest_address';
import {SelectAddressField, InputTextField} from './index';
const AddressField = ({ fields, meta: { error, submitFailed },provincias, cantones, distritos, barrios, updateBarrios, updateCantones, updateDistritos }) => (
	<div className="row ">  
	  <div className="col-md-12 ">
	    <ul className="phone_list">
	        {fields.map((address, index) => (
	        <li key={index} className="phone_list_item">
	          <div className="form-group card ">
	            <fieldset>
	            	<label><strong>Dirección</strong></label>
	              <Field type="hidden" name={`${address}.id`} component="input"/>
	              <div className="col-md-12">
		              <div className="form-row">
			                <div className="form-group col-md-2 offset-md-1">
			                  <Field name={`${address}.provincia_id`} component={SelectAddressField} label="Provincia" values={provincias} onChange={updateCantones}/>
			                </div>
			                <div className="form-group col-md-3">
			                  <Field name={`${address}.canton_id`} component={SelectAddressField} label="Canton" values={cantones} onChange={updateDistritos}/>
			                </div>
			                <div className="form-group col-md-3">
			                  <Field name={`${address}.distrito_id`} component={SelectAddressField} label="Distrito" values={distritos}  onChange={updateBarrios}/>
			                </div>
							<div className="form-group col-md-3">
			                  <Field name={`${address}.barrio_id`} component={SelectAddressField} label="Barrio" values={barrios} />
			                </div>
		              </div>
		              <div className="form-row">
			              <div className="form-group col-md-11 offset-md-1">
			              	<Field name={`${address}.otro`} component={InputTextField} label="Otras señas" values={[]}/>
			              </div>
		              </div>
		              <div className="form-row">
		            	  	<div className="form-group col-md-12">
			                  <input type="button" className="btn btn-danger  float-right" value="Eliminar"  onClick={() => fields.remove(index)}/>
		                	</div>
	             	  </div>
             	  </div>
	            </fieldset>
	          </div>
	         
	        </li>      
	      ))}
	        { fields.length <= 0 &&
	        <li className="phone_list_item">
	          <div className="row ">
	            <div className="col-md-3 offset-md-9">
	              <button type="button" onClick={() => fields.push()} className="btn btn-link btn-lg float-right">  Agregar Dirección </button>
	                {submitFailed && error && <span>{error}</span>}
	            </div>
	          </div>
	        </li>
	    	}
	    </ul>
	  </div>
	</div>     
)

const mapStateToProps = state  => {
	return {
		provincias: state.provincias,
		cantones: state.cantones,
		distritos: state.distritos,
		barrios: state.barrios
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(getProvincias());
	dispatch(getCantones());
	dispatch(getDistritos());
	dispatch(getBarrios());	
	return {
		updateCantones: function({target:{value} }){
			dispatch(getCantones({"id_provincia": value}));
    	},
    	updateDistritos: function({target:{value} }){
			dispatch(getDistritos({"id_canton": value}));
    	},
    	updateBarrios: function({target:{value} }){
			dispatch(getBarrios({"id_distrito": value}));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(AddressField);