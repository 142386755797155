import React from 'react';
import Footer from  './layout/footer';
import Header from  './layout/header';
import Home from './pages/home';
import HomePage from './pages/home_page';
import Help from './pages/help';
import AboutPage from './pages/about_page';
import Invoices from './pages/invoices';
import NoMatch from './pages/no_match';
import {Switch, Route} from "react-router-dom";
import {Spinner} from './components/spinner';

let App = () => 
{

  return ( 
    <div className="App">
        <Spinner/>
      	<Header/>    	
    	   <Switch>
         	  <Route exact  path='/' component={HomePage}/>
            <Route path='/login' component={Home}/>
            <Route path='/info' component={Help}/>
            <Route path='/terminosycondiciones' component={Help}/>
            <Route path='/recuperar_contrasena' component={Home}/>
            <Route path='/recuperar_pregunta' component={Home}/>
            <Route path='/registro' component={Home}/>
            <Route path='/micuenta' component={Home}/>
            <Route path='/activar' component={Home}/>
			      <Route path='/facturas' component={Invoices}/>
			      <Route path='/clientes' component={Invoices}/>
            <Route path='/reportes' component={Invoices}/>
            <Route path='/productos' component={Invoices}/>
           	<Route path='/configuracion' component={Invoices}/>
            <Route path='/acercade' component={AboutPage}/>
         	<Route component={NoMatch} />
        </Switch>
      	
    	 <Footer />
    </div>
    )
};

export default App;
