import React from 'react';
import {activarCompany} from '../api'
import {connect} from 'react-redux';
const ActivateCompanyPage = (props) => {
	

		return (
			<div className="col-lg-12 ">
				<br/><br/>
				<div id="activate_msg" className="col-lg-12">
					<h1 className="text-center">Gracias por registrarse a <strong>DigiFact</strong>, Su cuenta ha sido activada satisfactoriamente..!! </h1>
					<h2 className="text-center">Por favor ingrese al sistema e ingrese la información solicitada.</h2>
				</div>
			</div>
	    );
	
}
    
const mapDispatchToProps = (dispatch, props) => {
		const  id  =  props.location.search;
		
		dispatch(activarCompany(id.substr(1)))
	return {
    }
};
export default connect(null, mapDispatchToProps)(ActivateCompanyPage);