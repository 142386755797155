import React from 'react';
var currencyFormatter = require('currency-formatter');
const ReportAccepted = (props) => (
	<div  className="col-lg-12">
		<h3>Resumen</h3>
		<hr/>	
		<div  className="row">

			<div  className="col-lg-6 offset-md-3">
				<h4>Facturas Electronicas Aceptadas</h4>
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Detalle</th>
							<th>Total</th>
						</tr>	
					</thead>
					<tbody>
						<tr>
							<th>N° de documentos</th>							
							<td>{props.report.facturas.total_documentos}</td>
						</tr>
						<tr>
							<th>Total Gravado</th>
							<td>{currencyFormatter.format(props.report.facturas.total_gravado,'')}</td>
						</tr>
						<tr>
							<th>Total Exento</th>
							<td>{currencyFormatter.format(props.report.facturas.total_exento,'')}</td>
						</tr>
						<tr>
							<th>Total Descuentos</th>
							<td>{currencyFormatter.format(props.report.facturas.total_descuentos,'')}</td>
						</tr>
						<tr>
							<th>Total Impuestos</th>
							<td>{currencyFormatter.format(props.report.facturas.impuestos,'')}</td>
						</tr>
						<tr>
							<th>Total</th>
							<td>{currencyFormatter.format(props.report.facturas.total,'')}</td>
						</tr>
					</tbody>
				</table>		
			</div>
		</div>		
	</div>

);

export default ReportAccepted;