import React from 'react';
import UserForm from '../forms/user_form';
import {addUser} from '../api/rest_user';
import {cleanUser} from '../../redux/actions';
import {connect} from 'react-redux';
const NewUserPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<br/>
			<h3>Nuevo usuario</h3>
			<p>
				Ingrese la información del nuevo usuario que desea agregar a su compañia
			</p>
			<UserForm id="newClientForm" onSubmit ={props.handleSubmit} initialValues={{role:"cachier", status: "new"}} />
		</div>
    );
}
     
     
const mapDispatchToProps = dispatch => {
	dispatch(cleanUser());
	return {
		handleSubmit: function(values){
		    let user = {
		    	usuario: values
		    }	    
		    dispatch( addUser(user));
    	}
    }
};
export default connect(null, mapDispatchToProps)(NewUserPage);