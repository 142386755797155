import axios from 'axios';
import {ATV, COMPANY, ATV_STATUS} from '../../redux/actions'
import {URL,setTokenHeader,successMessage, errorMessage, formatError}  from './index';
import $ from 'jquery'; 
export const addCompany = (company) => {
  $("#loading_spinner").show();
  return (dispatch) => {    
       return axios.post(URL() + "registro", company,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 201){   
            successMessage("Información de la compañia almacenada satisfactoriamente!");         
          }
          $("#loading_spinner").hide();
        }).catch((error) => {
          $("#loading_spinner").hide();
        	if (error.response.status === 400){
        		console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al crear la compañia, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const updateCompany = (company) => {
  setTokenHeader();
  $("#loading_spinner").show();
  return (dispatch) => {    
       return axios.put(URL() + "companies/1", company,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
            window.location = "/configuracion/compania";
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al actualizar la información de la  compañia, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const getCompany = (id = 1) => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "companies/" + id, {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200){   
            dispatch({
              type: COMPANY,
              company:  res.data
            });
          }
        }).catch((error) => {
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al obtener la compañia, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const getCompanyInfo = () => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "companies/info/" , {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200){   
            dispatch({
              type: COMPANY,
              company:  res.data
            });
          }
        }).catch((error) => {
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al obtener la compañia, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const updateAtv= (atv) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.post(URL() + "atv", atv,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
            successMessage("Información de hacienda almacenada satisfactoriamente!");         
          }else if(res.status === 200){   
            errorMessage("El usuario y la contraseña sumistrados, no fueron aceptados por el sistema del Ministerio de Hacienda. Por favor verifiquelos");
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al almacenar la información de hacienda, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};

export const getAtv= () => {
  setTokenHeader();
  return (dispatch) => {    
       return axios.get(URL() + "atv", {},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200){   
            dispatch({
              type: ATV,
              atv:  res.data
            });
          }
        }).catch((error) => {
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al obtener la información de hacienda, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};


export const updateAtvKey= (atv) => {
  $("#loading_spinner").show();
  setTokenHeader();
  const formData = new FormData();
  formData.append('key_file', atv.key_file);
  formData.append('pin', atv.pin);
  return (dispatch) => {    
       return axios.post(URL() + "atv/key", formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 200){   
             successMessage("Información de hacienda almacenada satisfactoriamente!");  
             dispatch({
              type: ATV_STATUS,
              atv_status:true
            });

          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          if (error.response.status === 400){
            console.log(error.response.data);
            let error_messages = formatError(error.response.data);
            errorMessage("Error al almacenar la información de hacienda, por favor intentelo de nuevo" + error_messages);
          }
           
        })
    };
};
