import React from 'react';
import SidebarButton from  '../components/sidebar_button'
import {Switch} from "react-router-dom";
import AuthorizedRoute from '../components/authorized_route';

const Sidebar = () =>(

    <div className="sidebar col-md-2 bg-secondary">
        <div className="list-group list-group-flush  " >
                <Switch>
                    <AuthorizedRoute path='/facturas'  roles={["admin","cachier"]} component={SidebarInvoices}/>
                    <AuthorizedRoute path='/clientes' roles={["admin","cachier"]} component={SidebarClients}/>
                    <AuthorizedRoute path='/productos' roles={["admin","cachier"]} component={SidebarProduct}/>
                    <AuthorizedRoute path='/configuracion' roles={["admin"]} component={SidebarConfig}/>
                    <AuthorizedRoute path='/reportes' roles={["admin","cachier"]} component={SidebarReports}/>
                </Switch>
            
        </div>
    </div>

);

export default Sidebar;

const SidebarInvoices = () => (
    <div>       
        <SidebarButton name="Tiquete Electrónico" icon="plus-square" to="/facturas/tiqueteelectronico" />
        <SidebarButton name="Crear Factura" icon="file-medical" to="/facturas/new" />
        <SidebarButton name="Proforma" icon="file" to="/facturas/proforma" />        
        <SidebarButton name="Factura Compra" icon="file" to="/facturas/facturacompra" />        
        <SidebarButton name="Factura Exportación" icon="file" to="/facturas/proforma" />        
       {/* <SidebarButton name="Nota de Crédito" icon="file" to="/facturas/notacredito" />
        <SidebarButton name="Nota de Débito" icon="plus-circle" to="/facturas/notadebito" />*/}
        <SidebarButton name="Buscar Factura" icon="search" to="/facturas/"/>
        <SidebarButton name="Aceptar Documentos" icon="file" to="/facturas/aceptar"/>
    </div>
);

const SidebarClients = () => (
    <div>
        <SidebarButton name="Clientes" icon="address-book" to="/clientes" />
        <SidebarButton name="Crear Cliente" icon="user-plus" to="/clientes/new" />
        <SidebarButton name="Buscar Cliente" icon="search" to="/clientes/search"/>
    </div>
);

const SidebarProduct = () => (
    <div>
        <SidebarButton name="Productos" icon="list-alt" to="/productos" />
        <SidebarButton name="Crear Producto" icon="plus-circle" to="/productos/new" />
        <SidebarButton name="Buscar Producto" icon="search" to="/productos/search"/>
    </div>
);

const SidebarConfig = () => (
    <div>
        <SidebarButton name="Datos de la compañia" icon="building" to="/configuracion/compania" />
        <SidebarButton name="Datos de hacienda" icon="cog" to="/configuracion/hacienda" />
        <SidebarButton name="Usuarios" icon="users-cog" to="/configuracion/usuarios" />
        <SidebarButton name="Crear Usuario" icon="user-plus" to="/configuracion/usuarios/new" />
    </div>
);

const SidebarReports = () => (
    <div>
        <SidebarButton name="Diario" icon="calendar" to="/reportes/dia" />
        <SidebarButton name="Semanal" icon="calendar" to="/reportes/semanal" />
        <SidebarButton name="Mensual" icon="calendar" to="/reportes/mensual" />
        <SidebarButton name="Personalizado" icon="calendar-alt" to="/reportes/custom" />
        <SidebarButton name="Doc. Aceptados" icon="calendar" to="/reportes/doc_aceptados" />
        <SidebarButton name="Balance" icon="calendar" to="/reportes/balance" />
    </div>
);