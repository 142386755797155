import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
import {HideField, InputTextField} from "../tags";
import moment from 'moment'
import momentLocaliser from 'react-widgets-moment';
momentLocaliser(moment)

let BalanceReportForm = (props) => {
	const { handleSubmit} = props;
	 return (

	 	 <form onSubmit={handleSubmit}>
	 	 	<hr/>
	 	 	<div className="form-row align-items-center">
	 	 		<div className="col-md-10 my-1 offset-1">
	 	 			<h4 className="text-center">Indique un correo electronico, para enviar el reporte mensual</h4>
	 	 		</div>
	 	 		
	 	 			 	 		
				<div className="col-md-4 my-1 offset-4">
					<Field name="email" component={InputTextField} label="Email *"/>					
					<Field name="fecha" component={HideField} value={props.date}/>
				</div>
				
				<div className="col-auto my-2">
					<button className="btn btn-secondary btn-lg" type="submit">	
						Enviar <FontAwesomeIcon icon="paper-plane" size="1x"/>
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
BalanceReportForm = reduxForm({
  form: 'balance_report_form',
  enableReinitialize: true
})(BalanceReportForm);

export default BalanceReportForm;