import axios from 'axios';
import {REPORT,BALANCE_REPORT,BALANCE_REPORT_FORM,ACCEPTED_REPORT,InitialReport,InitialBalanceReport,InitialAcceptedReport,InitialBalanceReportForm}from '../../redux/actions/report_actions';
import {URL, setTokenHeader}  from './index';
import $ from 'jquery'; 

export const dailyReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/diario", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
        	  $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  res.data
            });
          
        }).catch((error) => {
        	$("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  []
            });
        })

  };
}


export const weeklyReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/semanal", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  []
            });
        })

  };
}

export const monthlyReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/mensual", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  InitialReport
            });
        })

  };
}

export const customReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {
       return axios.post(URL() + "reporte/personalizado", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: REPORT,
              report:  []
            });
        })

  };
}

export const acceptedReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/doc_aceptados", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            dispatch({
              type: ACCEPTED_REPORT,
              accepted_report:  res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: ACCEPTED_REPORT,
              accepted_report:  InitialAcceptedReport
            });
        })

  };
}

export const balanceReport = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/balance", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            dispatch({
              type: BALANCE_REPORT,
              balance_report: res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: BALANCE_REPORT,
              balance_report:  InitialBalanceReport
            });
        })

  };
}

export const balanceReportForm = (params) => {
  $("#loading_spinner").show();
  setTokenHeader();  
  return (dispatch) => {  
       return axios.post(URL() + "reporte/balance_email", params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            $("#loading_spinner").hide();
            console.log(res.data)
            dispatch({
              type: BALANCE_REPORT_FORM,
              balance_report: res.data
            });
          
        }).catch((error) => {
          $("#loading_spinner").hide();
            dispatch({
              type: BALANCE_REPORT_FORM,
              balance_report:  InitialBalanceReportForm
            });
        })

  };
}