import axios from 'axios';
import {CLIENT_LIST, CLIENT} from '../../redux/actions'
import $ from 'jquery'; 
import {URL, setTokenHeader,successMessage, errorMessage}  from './index';
import {INVOICE_CLIENT} from '../../redux/actions/invoice_actions';
//get clients
export const clientList = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "clients", {params} ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: CLIENT_LIST,
              client_list:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: CLIENT_LIST,
              client_list:  []
            });
        })

  };
}

//get clients
export const loadClient = (id) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "clients/" + id)
       .then(res => {
            dispatch({
              type: CLIENT,
              client:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: CLIENT,
              client:  {}
            });
        })

  };
}
//get clients
export const loadClientInfo = (id) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "clients/info/" + id)
       .then(res => {
            dispatch({
              type: CLIENT,
              client:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: CLIENT,
              client:  {}
            });
        })

  };
}

//get clients
export const loadClientInfoInvoice = (id) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "clients/info/" + id)
       .then(res => {
            dispatch({
              type: INVOICE_CLIENT,
              client:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: INVOICE_CLIENT,
              client:  {}
            });
        })

  };
}

export const addClient = (client) => {
  $("#loading_spinner").show();
  setTokenHeader();
  return (dispatch) => {    
       return axios.post(URL() + "clients", client,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
            successMessage("Información del cliente almacenada satisfactoriamente!");       
            window.location.replace("/clientes/info/" + res.data.id);  
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
            errorMessage("No se pudo Crear el cliente, Intente de nuevo!");
        })
    };
};

export const updateClient = (client, id) => {
  setTokenHeader();
  $("#loading_spinner").show();
  return (dispatch) => {    
       return axios.put(URL() + "clients/" + id, client,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 201){   
           successMessage("Información del cliente actualizada satisfactoriamente!");
           window.location.replace("/clientes/info/" + res.data.id);  
           
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
           errorMessage("No se pudo Actualizar la información del cliente, Intente de nuevo!");
        })
    };
};