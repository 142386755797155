import React from 'react';
import ProductForm from '../forms/product_form';
import {loadProduct, updateProduct} from '../api/rest_product';
import {connect} from 'react-redux';
const EditProductPage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
		<br/>
			<h4>Actualice la información relacionada con el Producto</h4>
			<hr/>
			<ProductForm id="newProductForm" onSubmit ={props.handleSubmit} initialValues={props.initialValues} />
		</div>
    );
}

const mapStateToProps = (state)  => {
	return {
		initialValues: state.product
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadProduct(id));
	return {
		handleSubmit: function(values){
		    let producto = {
		    	producto: values
		    }
		    console.log(producto)
		    dispatch( updateProduct(producto, id));
    	}
    }
};

export default connect(mapStateToProps , mapDispatchToProps)(EditProductPage);