import React from 'react';
import ATV from '../components/help/atv';
import {Switch, Route} from "react-router-dom";
import TerminosCondicionesPage from '../pages/terminos_condiciones';
const Help = () => {
	return (
		<div className="home about_page row">
			<h1>DigiFact.cr <small>beta</small></h1>
			<hr/>
			<Switch>
	            <Route path='/info' component={ATV}/>
	            <Route path='/terminosycondiciones' component={TerminosCondicionesPage}/>
	        </Switch>
	    </div>
	)
}
export default Help;