import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {connect} from 'react-redux';
import {userList} from '../api/rest_user';
const UserList = (props) => (
	<table className="table table-striped">
		<thead>
			<tr>
				<th>Nombre Usuario</th>
				<th>Correo</th>
				<th>Role</th>						
				<th>Opciones</th>
			</tr>					
		</thead>
		<tbody className="list">

		{props.list.map((user, key) => {
			return (
				<tr key={key}>	
					<td>{user.nombre}</td>
					<td>{user.email}</td>
					<td>{user.roles}</td>
					<td>
						<Link to={"/configuracion/usuarios/edit/" + user.id} alt="Ver usuario">
							<FontAwesomeIcon icon="eye" size="2x"/>
				    	</Link>&nbsp; &nbsp;
				    	<Link to={"/configuracion/usuarios/edit/" + user.id} >
							<FontAwesomeIcon icon="trash-alt" size="2x"/>
				    	</Link>

			    	</td>
				</tr>
			);
		})
	}
		</tbody>
	</table>
)

const mapStateToProps = state  => {
	return {
		list: state.user_list
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(userList());
	return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(UserList);

