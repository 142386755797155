import {USER, USER_LIST} from '../actions';
export const UserListReducer = (state = [], action) => {
	if (action.type === USER_LIST){
		return action.user_list;
	}
	return state;
};


export const UserReducer = (state = {}, action) => {
	if (action.type === USER){
		return action.user;
	}
	return state;
};