import React from 'react';
import {connect} from 'react-redux';
import DualDateSearch from './dual_date_search'
import Report from './report'
import {customReport} from '../api/rest_reports';
import DocumentReport from './document_report';
let CustomReport = (props) => (
	<div  className="col-lg-12">
		<h2 className="text-center">Reporte del día</h2>
		<DualDateSearch onSubmit={props.handleSubmit} />
		<hr/>
		{props.report.facturas !== undefined &&
			<div>
				<Report report={props.report} />
				<DocumentReport documentos={props.documentos} />
			</div>
		}
	</div>
);

const mapStateToProps = state  => {
	return {
		report: state.report,
		documentos: state.report.documentos
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(customReport());
	return {
		handleSubmit: function(values){
			let date = new Date(values.fecha_inicio);
			let date2 = new Date(values.fecha_fin);

			dispatch(customReport({
				fecha_inicio: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
				fecha_fin:date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
			}));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomReport);

