import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {InputTextField, TextField, SelectField} from "../tags";
import { connect } from 'react-redux';
let RegisterForm1 = (props) =>{
	const { handleSubmit} = props;
	return (						
		<div >				
			<form className="login_form" onSubmit={handleSubmit}>

    		  <h3>Información del negocio</h3>
					<Field name="nombre" component={InputTextField} label="Nombre comercial *"/>
					<Field name="descripcion" component={TextField} label="Descripcion"/>
					<Field name="tipo_cedula" component={SelectField} label="Tipo Cedula *" values={TIPO_CEDULA}/>
	        <Field id="company_cedula "name="cedula" component={InputTextField} label="Cedula *"/>
					<Field id="company_email" name="email" component={InputTextField} label="Email"/>
		      <button type="submit" className="btn btn-primary btn-lg  float-right">
            <label>Siguiente</label>
          </button>
          <button type="reset" className="btn btn-secundary btn-lg float-right">
            Cancelar
          </button>
			</form>
      <div className="clearfix"><br/></div>
		</div>
	)
	
}

const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  }
  if (!values.email) {
    errors.email = 'Campo Requerido'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección correo invalida'
  }
  if (!values.cedula) {
    errors.cedula = 'Campo Requerido'
  } else {
  	 switch(values.tipo_cedula){
  	 	case "01": 
  			if (!/^\d{9}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550444";
  			}
  			break;
  		case "02": 
  			if(!/^\d{12}$/.test(values.cedula)){
  				errors.cedula ="Formato Numerico ie: 301205550444";
  			}
  			break;
  		default:
  			if (!/^\d{9}$/.test(values.cedula)) {
  				errors.cedula ="Formato Numerico ie: 205550444";
  	   }
	 }
	
  }
  return errors;
}

RegisterForm1 = reduxForm({
  form: 'registerForm', 
  destroyOnUnmount: false, 
  forceUnregisterOnUnmount: true, 
  validate  
})(RegisterForm1);

const mapDispatchToProps = dispatch => {
	return {
	
	};
};
const TIPO_CEDULA = [
	{key: "01",	value: "Cedula física"},
	{key: "02",	value: "Cedula Juridica"},
	{key: "03",	value: "DIMEX"},
	{key: "04",	value: "NITE"},
]

export default connect(null, mapDispatchToProps)(RegisterForm1);