import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
export const InputTextField = ({ input, label, type, legend, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong>{legend !== undefined && <small>{legend}</small>}</label>
      <input {...input} id={input.name} type="text" className="form-control form-control-lg  "/>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)

export const DisabledInputTextField = ({ input, label, type, legend, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong> {legend !== undefined && <small>{legend}</small>}</label>
      <input {...input} id={input.name} type="text" className="form-control form-control-lg" disabled/>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export const FileField = ({input: { value: omitValue, onChange, onBlur, ...inputProps }, label, legend, meta: {omitMeta,touched, error, warning},  ...props }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor=""><strong>{label}</strong>{legend !== undefined && <small>{legend}</small>}</label>      
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        className="form-control form-control-lg "
        {...props.input}
        {...props}
      />
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)


export const HideField = ({ input,  type, meta: { touched, error, warning } }) => (
      <input {...input} type="hidden" className="form-control form-control-lg "/>
)
export const CheckboxField = ({ input, label, legend }) => (
   <div className="form-group single_checkbox">
      <input {...input} id={input.name} type="checkbox"/>
      <label htmlFor={input.name} >
           <strong>{label}</strong>
      </label>
   </div>
)
export const PasswordTextField = ({ input, label, type, legend, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong>{legend !== undefined && <small>{legend}</small>}</label>
      <input {...input} type="password" className="form-control form-control-lg "/>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)

export const TextField = ({ input, label, type, legend, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong> {legend !== undefined && <small>{legend}</small>}</label>
      <textarea {...input} type="text" className="form-control form-control-lg "/>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)



export const NumberField = ({ input,id, label, type, legend, meta: { touched, error, warning } }) => (
  <div id={id} className={type === "hidden" ? "hidde" : ""} >
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong> {legend !== undefined && <small>{legend}</small>}</label>
      <input {...input} type="number" className="form-control form-control-lg "/>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)

export const SelectField = ({ input, label, value, legend,values, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong> {legend !== undefined && <small>{legend}</small>}</label>
      <select {...input} id={input.name} type="number" className="form-control form-control-lg ">
       <option value="" disabled>Seleccione una opción</option>
      {
		    values.map((item, key) => {
          return(
                    
                    <option key={key} value={item.key} >{item.value} </option>
          )
        })
      }
      </select>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)

export const SelectAddressField = ({ input, label, legend, value,values, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group"}>
      <label htmlFor={input.name}><strong>{label}</strong>{legend !== undefined && <small>{legend}</small>}</label>
      <select {...input} type="number" className="form-control form-control-lg ">
       <option value="" disabled>Seleccione una opción</option>
      {
        values.map((item, key) => <option key={key} value={item.id} >{item.nombre}</option>)
      }
      </select>
      {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
    </div>
)

export const CheckboxGroupField = ({ input, label, legend,  values, meta: { touched, error, warning } }) => (
    <div className={touched && error ?" field_error form-group" : "form-group row"}>
      <label htmlFor={input.name}><strong>{label}</strong>{legend !== undefined && <small>{legend}</small>}</label>
      <div className="col-md-12">        
        <ul>
        {
          values.map((option, index) => {
              
              return (
              <li className="checkbox group_list_item" key={index}>
                  <label>
                      <input type="checkbox"
                             name={`${input.name}[${index}]`}
                             value={option.key}
                             checked={input.value.indexOf(option.key) !== -1}
                             onChange={(event) => {
                                 const newValue = [...input.key];
                                 if (event.target.checked) {
                                     newValue.push(option.key);
                                 } else {
                                     newValue.splice(newValue.indexOf(option.key), 1);
                                 }

                                 return input.onChange(newValue);
                             }}/>
                      {option.value}
                  </label>
              </li>)
          } )
        }
        </ul>
        {touched && ((error && <small className="form-text field_error ">{error}</small>) || (warning && <span>{warning}</span>))}
      </div>    
    </div>
);

export const CountNumberField = ({ input, type, value}) => (
      <input {...input} type={type}  onChange={() => input.onChange(input)} />
)

export const RenderDateTimePicker = ({ input: { onChange, value }, showTime }) =>(
  <DateTimePicker
    onChange={onChange}
    format="DD MMM YYYY"
    time={showTime}
    value={!value ? null : new Date(value)}
  />
)