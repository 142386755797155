import React from 'react';
import ContactForm from '../components/forms/contact_form';
import {connect} from 'react-redux';
import {contactUs} from '../components/api'
const AboutPage = (props) => {
	return (
		<div className="home about_page col-sm-10 col-lg-12">
			<br/>
			
			<div className="p-3 mb-2 col-sm-10  col-lg-10 offset-lg-1">
				<h1>DigiFact.cr <small>beta</small></h1>
				<hr/>
				<div className="row">
					<div className="p-3 mb-2 col-sm-6 col-lg-5">
						<img src="/images/bank.png" className="text-center" alt=""/>				
					</div>
					<div className="p-3 mb-2 col-sm-10 col-lg-6">
						<p>
							Digifact es una herramienta que le permitirá manejar sus facturas electrónicas requeridas por el <a href="https://www.hacienda.go.cr/contenido/14350-factura-electronica">Ministerio de Hacienda</a> de forma simple y directa. 
							Sin necesidad de ser expertos en el uso de sistemas informáticos, o en sistemas contables.<br/>
							Esta herramienta esta orientada a aquellas personas o microempresas que requiran emitir sus facturas electronicas sin mayor conocimiento de herramientas electrónicas.<br/>					
						</p>
					</div>
				</div>
				<h4>¿Por qué Digifact?</h4>
				<p>
					
					DigiFact nacio de una necesidad. Una persona mayor, con desconocimiento del uso de herramientas digitales, que durante 50 años de su vida ha hecho frente 
					a sus asuntos tributarios de forma tradicional (papeles, facturas, etc..), <br/>
					El gobierno de Costa Rica ha decidido  mejorar su sistema de recaudación, por lo cual ha decidido implementar el uso de facturas electrónicas, de uso obligatorio, para todos los contribuyentes,
					pero esta herramienta resulta ser funcional, pero de uso complejo, especialmente para esta persona mayor y que debe atender su negocio.<br/>
				</p>
				<p>
					DigiFact ha sido creado pensando en esta persona, y en las demás que tengan situaciones similares, o que requieran el uso de una herramienta simple y directa, que no requiera de grandes conocimientos contables
					ni conocimientos de computo. y ¿Cúales son los aspectos que garantizan lo descrito?
				</p>
					<ul>
						<li>Las fuentes, botones y cajas de texto, son de major tamaño (ayudando a las personas con problemas visuales)</li>
						<li>La información se encuentra de forma simple y directa</li>
						<li>Los formularios son minimalistas, eso quiere decir que solo requieren la información necesaria</li>
					</ul>
				
			</div>

			<div className="p-3 mb-2 col-lg-8 offset-lg-2">
				<h4 className="text-center">Ayudenos a mejorar</h4>
				<p className="text-center">
					Envienos sus comentarios, sus dudas, sugerencias, con gusto las tomaremos en cuenta para el mejoramiento de esta su herramienta.
				</p>
				<div className="p-3 mb-2  col-sm-8 offset-sm-0 col-lg-8 offset-lg-2 alert alert-light">
					<h3>Contáctenos </h3>
					<hr/>
					<ContactForm initialValues={{}} onSubmit={props.handleSubmit}/>
					<br/>
				</div>
			</div>	
	    </div>
	)
}

    
const mapDispatchToProps = dispatch => {
	return {
		handleSubmit: function(values){
			dispatch(contactUs(values))
    	}
    }
};
export default connect(null, mapDispatchToProps)(AboutPage);
