import React from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {logout, cleanClient, cleanProduct, cleanUser, cleanAtv, 
	cleanCompany,cleanAddress,cleanAtvStatus, cleanClientList,cleanProductList} from '../redux/actions';
import {cleanInvoice,cleanInvoiceInfo,cleanInvoices} from '../redux/actions/invoice_actions';

const Login = (props) =>  (
	<div className=" login-header">
		{props.login_data.logged ? (
			<div className="btn-group" role="group">
				<button type="button" className="btn btn-secondary btn-lg" onClick={props.logoutClick}>
			     Logout&nbsp;<FontAwesomeIcon icon="sign-out-alt" size="1x"/> 
			    </button>
		    </div>
		) : (
			<div className="btn-group" role="group">
  				<Link to="/login" className=" btn btn-secondary btn-lg">									    	
					 Login
			    </Link>
  				<Link  to="/registro" className="btn btn-secondary btn-lg" >
			     	Registrese
			    </Link>
		    </div>
		)}
	</div>			
);

const mapStateToProps = state  => {
  return {
    login_data: state.login_data
  };
};

const mapDispatchToProps = dispatch => {
	return {
		logoutClick(e){
			dispatch(logout(0));
			dispatch(cleanClient());
			dispatch(cleanClientList());
			dispatch(cleanProduct());
			dispatch(cleanProductList());
			dispatch(cleanUser());
			dispatch(cleanAtv());
			dispatch(cleanAtvStatus());
			dispatch(cleanCompany ());
			dispatch(cleanAddress());
			dispatch(cleanInvoice());			
			dispatch(cleanInvoices());
			dispatch(cleanInvoiceInfo());

			window.location = "/login";
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);