import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery'; 
import SearchCabys from './search_cabys'
import {cabysLoadData} from '../api';
import {updateProductCodeService,updateProductCodeTaxes,updateProductTaxes}  from '../../redux/actions';
let CabysForm = (props) =>{
	return (
		<div className="modal fade" id="cabysSearchModal" tabIndex="-1" role="dialog" aria-labelledby="cabysSearchModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cabysSearchModal">Buscar Producto Cabys</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                  <div className="col-md-12">    
                    <SearchCabys onSubmit={props.handleSubmit} />
                     <div className="col-md-12 product_list">                                   
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Impuesto</th>           
                          </tr>         
                        </thead>
                        <tbody>
                          {props.list.map((producto, key) => {
                            return (
                              <tr id={"product_" + producto.codigo} name={producto.codigo} key={key} onClick={() => props.selectItem(producto)} className="product_item">  
                                <td>{producto.codigo}</td>
                                <td>{producto.descripcion}</td>
                                <td >{producto.impuesto}%</td>
                              </tr>
                            );
                           })
                          }
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary btn-lg" data-dismiss="modal"   onClick={props.agregarItem}>Agregar Codigo</button>
            </div>
          </div>
        </div>
      </div>
	)
	
}
const CABYS_TAXES = {
  "13": "08",
  "0": "01",
  "1": "02",
  "2": "03",
  "4": "04",
}
const mapStateToProps = state  => {
  return {
    list: state.cabys_product_list,
    codigo_servicio: state.product.codigo_servicio
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: function(values){
     dispatch(cabysLoadData(values));
    },
    selectItem: function(product){
      selectRow(product);
    },
    agregarItem: function(){
     $("#codigo_servicio").val(item.codigo);
     $("#tarifa_impuesto").val(item.impuesto);
     $("#codigo_tarifa").val(CABYS_TAXES[item.impuesto]).change();
     dispatch(updateProductCodeService(item.codigo));
     dispatch(updateProductCodeTaxes(CABYS_TAXES[item.impuesto]));
     dispatch(updateProductTaxes(item.impuesto));
     // AddCabysCode
      //product.codigo_servicio = item.codigo;
    /*  $("#codigo_servicio").val(item.codigo);
      let id = "#codigo_tarifa option[value='" + CABYS_TAXES[item.impuesto] + "']";
      console.log(id);
       $("#codigo_tarifa").val(CABYS_TAXES[item.impuesto]).change();*/
      clearRows();
    }
    
  }
};

let item = {};

function clearRows(){
  $(".product_item").removeClass("product_select");
}
function selectRow(product){
  clearRows();  
  item = product;
  console.log(item);
  $("#product_" + product.codigo).addClass("product_select");
  
}
export default connect(mapStateToProps, mapDispatchToProps)(CabysForm);

