import React from 'react';
import {connect} from 'react-redux';
import SingleDateSearch from './single_date_search';
import BalanceReportForm from './balance_report_form';
import {balanceReport,balanceReportForm} from '../api/rest_reports';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
var currencyFormatter = require('currency-formatter');

let BalanceReport = (props) => (
	<div  className="col-lg-12">
		<h2 className="text-center">Balance</h2>
		<SingleDateSearch onSubmit={props.handleSubmit} />
		<hr/>
		<div className="row">
			<div className="col-lg-6">
				<C3Chart data={props.chart_data} />
			</div>
			<div className="col-lg-4">
				<h4 className="text-center" >Débito Fiscal mes: </h4> <br/>
				<table className="table table-striped">
					<tbody>
						<tr>
							<th>Crédito</th> 
							<td className="text-right">{currencyFormatter.format(props.report.credito,'')}</td>
						</tr>
						<tr>
							<th>Débito</th> 
							<td className="text-right">{currencyFormatter.format(props.report.debito,'')}</td>
						</tr>
						<tr>
							<th>Total</th>
							<td className="text-right">{currencyFormatter.format(props.report.total,'')}</td>
						</tr>
					</tbody>
				</table>
			</div>	
		</div>	
		<BalanceReportForm onSubmit={props.sendEmailBalance} initialValues={{fecha: props.date}} />
	</div>
);

const mapStateToProps = state  => {
	return {
		report: state.balance_report,
		chart_data: {
			columns: [
            	['Crédito', state.balance_report.credito],
            	['Débito', state.balance_report.debito],
	        ],
	        type : 'pie',
		},
		date: state.balance_report.fecha

	};
};

const mapDispatchToProps = dispatch => {
	dispatch(balanceReport());
	return {
		handleSubmit: function(values){
			let date = new Date(values.fecha_inicio);
			dispatch(balanceReport({
				fecha_inicio: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()

			}));
    	},
    	sendEmailBalance: function(values) {
    		let date = new Date(values.fecha);
    		console.log(date)
    		dispatch(balanceReportForm({	    			
    			fecha_inicio: values.fecha,// date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
    			email: values.email
    		}))
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(BalanceReport);

