import React from 'react';
import AceptDocumentForm from '../forms/acept_document_form';
import {connect} from 'react-redux';
import {uploadXml,aceptDocument} from '../api/rest_invoices';
import {cleanInvoiceMessage} from '../../redux/actions/invoice_actions';
import $ from 'jquery'; 
const AceptFilePage =(props) => {
	return (
		<div className="col-lg-10 offset-lg-1">
			<h4>Aceptar Documentos</h4>
			<p className="text-justify">
			 	Seleccione el documento XML que contiene  la factura digital <strong>Subir Archivo</strong>
			</p>
			<AceptDocumentForm id="FileForm" onSubmit ={props.handleSubmit} />
			<br/>
			{ props.invoice_message !== null &&
				<div>
					<div className="col-lg-8 offset-lg-2">						
						<h4>Información del Documento</h4>
						<hr/>
						<strong>Clave: </strong> {props.invoice_message.clave}<br/>
						<strong>Emisor: </strong>{props.invoice_message.emisor.Nombre} - {props.invoice_message.emisor.Identificacion.Numero}<br/>
						<strong>Fecha Emision: </strong>{props.invoice_message.fecha}<br/>
						<strong>Resumen Factura </strong><br/>
						<strong>Total Impuestos: </strong>  {props.invoice_message.resumen.TotalImpuesto}<br/>
						<strong>Total Comprobante: </strong> {props.invoice_message.resumen.TotalComprobante}<br/>
					</div>	
					<hr/>	
					<div className="col-lg-8 offset-lg-2">
						<div className="form-group">
		                    <label>Elija una opción Documento</label>
		                    <select id="mensaje"  name="mensaje" className="form-control">
		                    	<option value="1"> Aceptar</option>
		                      	<option value="2"> Aceptar Parcialmente</option>
		                      	<option value="3"> Rechazar</option>                    
		                    </select>
	                  	</div>
	                  	<div className="form-group">
		                    <label>Elija una opción Documento</label>
		                    <select id="condicion_impuesto"  name="condicion_impuesto" className="form-control">
		                    	<option value="01"> Genera crédito IVA </option>
		                      	<option value="02"> Genera Crédito parcial del IVA </option>
		                      	<option value="03"> Bienes de Capital</option>
		                      	<option value="04"> Gasto corriente no genera crédito</option>
		                      	<option value="05"> Proporcionalidad</option>
		                    </select>
	                  	</div>
		                  <div className="form-group">
		                    <label>Monto total impuesto acreditar</label>
		                    <input id="monto_total_impuesto_acreditar" name="monto_total_impuesto_acreditar" className="form-control" type="number" defaultValue={props.invoice_message.resumen.TotalImpuesto}/>
		                  </div>

		                  <div className="form-group">
		                    <label>Monto total de gasto aplicable</label>
		                    <input id="monto_total_de_gasto_aplicable" name="monto_total_de_gasto_aplicable" className="form-control" type="number" defaultValue="0"/>
		                  </div>

		                  <div className="form-group">
		                    <label>Detalle</label>
		                    <input id="detalle" name="detalle" className="form-control" type="text"/>
		                  </div>

						<button className="btn btn-primary btn-lg  float-right" onClick={() => props.aceptDocuments(props.invoice_message)} >Aceptar</button>
					</div>
				</div>

			}


		</div>
    );
}
     
const mapStateToProps = (state)  => {
	return {
		invoice_message: state.invoice_message,
	};
};
const mapDispatchToProps = dispatch => {
	dispatch(cleanInvoiceMessage());
	return {
		handleSubmit: function(values){			
			 dispatch(uploadXml(values))
    	},
    	aceptDocuments: function(invoice){
    		let mensaje = $("#mensaje").val();
    		let detalle = $("#detalle").val();
    		let condicion_impuesto = $("#condicion_impuesto").val();
    		let monto_total_impuesto_acreditar = $("#monto_total_impuesto_acreditar").val();
    		let monto_total_de_gasto_aplicable = $("#monto_total_de_gasto_aplicable").val();

    		let request = {
    			mensaje,
    			detalle,
    			condicion_impuesto,
    			monto_total_impuesto_acreditar,
    			monto_total_de_gasto_aplicable,
    			clave: invoice.clave,
    			sucursal_id: 1,
    			terminal_id: 1
    		}

    		dispatch(aceptDocument(request))
    	}
    	
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AceptFilePage);