import React from 'react';
import ProductRow from  './product_row';
import ProductHeader from './product_header';
var currencyFormatter = require('currency-formatter');
let InvoiceDetails = (props) => (
<div className="col-lg-12 no-padding">
    	<div className="col-lg-12 no-padding">   
		    <ProductHeader updateCurrency={props.updateCurrency} handleSubmit={props.handleSubmit} dolar={props.dolar}
		        updateCondicionVenta={props.updateCondicionVenta} updateSituacion={props.updateSituacion} updateMedioPago={props.updateMedioPago} />
		    
		</div>
       
    	<div className="row">          
            <div className="col-lg-9 no-padding">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="td-cant">Cantidad</th>
                      <th className="td-desc">Nombre</th>                          
                      <th className="td-prec">Precio</th>
                      <th className="td-total">Subtotal</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody className="list">
                      {props.invoice_products.map((product, idx) =>
                        <ProductRow key={product.id} product={product} removeProduct={props.removeProduct} 
                        				updateCantidad={props.updateCantidad}  updatePrecio={props.updatePrecio} 
                        				descuentoP={props.descuentoP} moneda={props.moneda} /> 
                      )}
                    </tbody>  
                </table>
            </div>
			<div className="col-md-3 no-padding">
				<div className="bg-secondary text-white p-1">
					<h4>Resumen</h4>   
				</div>
				<table className="table table-striped list resumen">
					<tbody>
					    <tr>
						    <td>Subtotal</td>
						    <td>{currencyFormatter.format(props.subtotal, { code: props.moneda })}</td>
						</tr>
						
						<tr>
						    <td>Dto %</td>
						    <td>{props.descuentoP}%</td>
						</tr>
						<tr>
						    <td>Dto</td>
						    <td>{currencyFormatter.format(props.descuento, { code: props.moneda })}</td>
						</tr>
						<tr>
						    <td>IVA.</td>
						    <td>{currencyFormatter.format(props.impuesto, { code: props.moneda })}</td>
						</tr>
						<tr>
						    <td>Total</td>
						    <td>{currencyFormatter.format(props.total, { code: props.moneda })}</td>
						</tr>
					</tbody>
				</table>          
			</div>   
		</div>

		
</div>	
)

export default InvoiceDetails;