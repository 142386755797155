import React from 'react';
import { connect } from 'react-redux';
import InvoiceProductSearch from './invoice_product_search';
import InvoiceDetails from './invoice_details';
import DiscountProductForm from './discount_product_form';
import {removeToInvoiceProducts, updateInvoiceProducts, 
        updatePrecioInvoiceProducts,addCurrency, 
        AddCondicionVenta,AddSituacion,AddMedioPago } from '../../../redux/actions/invoice_actions';
import {calulateTotales,invoiceBody} from '../../lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExonerateDialog from './exonerate_dialog';
let InvoiceForm2 = (props) =>{
		return (	
   	   	<div className="col-lg-12 no-padding">		
             <div className="p-1 mb-1 bg-info text-white">
               <div className="row">

                  <div className="col-md-3">
                    <h3>{props.client.nombre} {props.client.apellido}</h3>
                  </div>

                  <div className="col-md-3">
                    <strong>Email:</strong> {props.client.email}<br/>            
                    <strong>Cedula:</strong> {props.client.cedula}
                  </div>
                  <div className="col-md-3">
                     <strong>Numero Documento </strong> 
                     <h4>{props.invoiceId} </h4>
                  </div>

                   <div className="col-md-3">
                    <button className="btn btn-primary btn-lg float-right acept_invoice" onClick={() => props.handleSubmit(props.invoice)}>
                      <FontAwesomeIcon icon="check" size="1x"/>
                      Aceptar Factura
                    </button>
                  </div>
                </div> 
              </div>   
                <DiscountProductForm />
                <InvoiceProductSearch />
                <ExonerateDialog />
                <InvoiceDetails invoice_products={props.products} moneda={props.moneda} descuentoP={props.descuentoP} impuesto={props.impuesto} 
                              descuento={props.descuento} total={props.total} subtotal={props.subtotal} updateCurrency={props.updateCurrency}
                              removeProduct={props.removeProduct} updateCantidad={props.updateCantidad}  updatePrecio={props.updatePrecio} 
                              updateCondicionVenta={props.updateCondicionVenta} updateSituacion={props.updateSituacion} 
                              updateMedioPago={props.updateMedioPago} dolar={props.dolar}/>
                
        </div>

	)
	
}

const mapStateToProps = state  => {
  let discount = state.invoice.descuento === undefined ? 0 : state.invoice.descuento.total;
  let data =  calulateTotales(state.invoice);
  console.log(invoiceBody(state.invoice));
  return {
    invoiceId: state.invoice.id,
    client: state.invoice.client,
    products: state.invoice.products,
    moneda:state.invoice.moneda,
    descuentoP: discount,
    descuento: data.total_descuentos,
    subtotal: data.total_gravado,    
    total: data.total_comprobante,    
    impuesto: data.total_impuesto,
    invoice: state.invoice,
    dolar: state.invoice.dolar,
    exoneracion: state.invoice.exoneracion
  };
};

const mapDispatchToProps = dispatch => {  
   return {
     removeProduct: function(product){
        dispatch(removeToInvoiceProducts(product));
      },
      updateCantidad: function(cantidad, id) {
        dispatch(updateInvoiceProducts({cantidad, id}))
      },
      updatePrecio: function(precio, id) {
        dispatch(updatePrecioInvoiceProducts({precio, id}))
      },
      updateCurrency:function({target: {value}}){
        dispatch(addCurrency(value));
      },
      updateMedioPago:function(medio_pago){
        dispatch(AddMedioPago(medio_pago))
      },
      updateCondicionVenta: function(condicion_venta){
        dispatch(AddCondicionVenta(condicion_venta))
      },
      updateSituacion: function(situaciion){
        dispatch(AddSituacion(situaciion))
      }


  }
  
};




export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm2);