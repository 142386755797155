import React from 'react';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom'
import { Route} from "react-router-dom";
class AuthorizedRoute extends React.Component {
  render() {
    const { component: Component, pending, logged, role, ...rest } = this.props
    let can = this.props.logged;
    let roles = this.props.roles;
    if (roles !== undefined){
      can = roles.includes(this.props.role)
    }
    return (
      <Route {...rest}  refresh="true"  render={props => {
        if (pending) return <div>Loading...</div>
        return (can )
          ? <Component refresh="true"  {...this.props} />
          : <Redirect to="/login" />
      }} />
    )
  }
}

const stateToProps = state => ({
    logged: state.login_data.logged,
    role: state.login_data.role
})

export default connect(stateToProps)(AuthorizedRoute)