import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import $ from 'jquery'; 
import {InputTextField,NumberField, TextField, SelectField,DisabledInputTextField} from "../tags";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CabysForm from './cabys_form'
let ProductForm = (props) =>{
 const { handleSubmit, error} = props;
	 return (
	 	<div>
	    <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
	      	<div id="form_error_msg" className="alert alert-alert hidde alert-dismissible fade show"  role="alert"></div>
	      	<Field name="nombre" component={InputTextField} label="Nombre *"/>
	        <Field name="descripcion" component={TextField} label="Descripcion"/>
	        <div className="row">
		        <div className="col-lg-4 no-padding">
		        	<Field name="precio" component={NumberField} label="Precio Final*" legend="Precio incluye impuestos"/>
		        </div>
		        <div className="col-lg-4 ">
	        		<Field name="iva_valor"  component={DisabledInputTextField} label="IVA" />
	        	</div>
	        	<div className="col-lg-4 ">
	        		<Field name="precio_con_impuestos"  component={DisabledInputTextField} label="Precio sin impuestos" />
	        	</div>
	        </div>
	        
  			<fieldset  className="form-group">
	        	<legend><h4>Características del producto</h4></legend>
	        	<hr/>	  
	        	<div className="row">
		        	<div className="col-lg-6 no-padding">
	          			<Field name="tipo_unidad" component={SelectField} label="Tipo unidad de venta" values={UNIDAD_VENTA}/>
	      			</div>
	      			<div className="col-lg-6">
	          			<Field name="unidad_medida_comercial" component={DisabledInputTextField} label="Unidad medida comercial"/>
	      			</div>
	  			</div>
		        <div className="row">
		        	<div className="col-lg-6 no-padding">
		        		<Field name="cod_producto" component={SelectField} label="Tipo Codigo producto" values={TIPO_COD_PRODUCTO} value="01"/>
	    			</div>
		        	<div className="col-lg-6 ">
	          			<Field name="codigo" component={InputTextField} label="Codigo"/>
	          		</div>
	          		
				</div>				
  			</fieldset>  			
  			<fieldset  className="form-group">
	        	<legend><h4> Impuestos</h4></legend>
	        	<hr/>
	        	<div className="row" id="tarifas" >
	        		<div className="col-lg-6   no-padding">
	          			<Field name="codigo_servicio" id="codigo_servicio"  component={InputTextField} label="Codigo Producto / Servicio (Cabys)"  />
	          		</div>
	          		<div className="col-lg-6 ">
	          			<button type="button" className="btn btn-dark btn-lg float-right" data-toggle="modal" data-target="#cabysSearchModal">
			                <FontAwesomeIcon icon="search" size="1x"/>
			                &nbsp;Buscar Codigo Cabys
			            </button>
	          		</div>
	          		
	        	</div>
	  			<div className="row" id="tarifas" >
	          		<div className="col-lg-6  no-padding" >
	          			<Field name="codigo_impuesto" component={SelectField} label="Código del Impuesto" values={IMPUESTOS}/>
	          		</div>      			
	          		<div className="col-lg-6" >
	          			<Field id="codigo_tarifa"  name="codigo_tarifa" component={SelectField} label="Código de la tarifa del impuesto" values={COD_TARIFA_IMPUESTO_VALOR_AGREGADO}/>
	      			</div>
	          	</div>
	          	<div className="row" id="tarifas" >
	          		<div className="col-lg-6  no-padding" >
	          			<Field name="tarifa_impuesto" id="tarifa_impuesto" component={DisabledInputTextField} label="Tarifa del Impuesto" />
	      			</div>
	          		<div className="col-lg-6" >
	          			<Field name="factor_iva" id="factor_iva" component={NumberField} label="Factor del impuesto" />
	      			</div>      			
	          	</div>
          	</fieldset>
          	<div className="row">
	        	<div className="col-lg-6 no-padding">
		          	<div className="form-group single_checkbox">
				        <Field name="exento" id="exento"component="input"type="checkbox"   />
				        <label htmlFor="exento"><strong> Producto Exento de impuestos</strong></label>
			        </div>
		        </div>
				<div className="col-lg-6 no-padding">       
			        <div className="form-group single_checkbox">
				        <Field name="has_inventario" id="has_inventario"component="input"type="checkbox"   />
				        <label htmlFor="has_inventario"><strong> Producto se maneja con inventario</strong></label>
			        </div>
		        </div>
	        </div>

	        <Field name="es_servicio" id="es_servicio" component="input" type="checkbox"   className="hidde"/>
	        <hr/>
	        
	        <Field name="base_imponible" id="base_imponible" component={NumberField} label="Base imponible especial" />
		
	        <button type="submit" className="btn btn-primary btn-lg float-right">
                <FontAwesomeIcon icon="check" size="1x"/>
                Aceptar
            </button>
          <br/>
          <br/>
           <div className="clearfix"><br/></div>
	    </form>	   
	     <CabysForm  onSelectCode={props.updateCabysCode}/>
	    </div>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.nombre) {
    errors.nombre = 'Campo Requerido'
  } 
  if (!values.precio) {
    errors.precio = 'Campo Requerido'
  } else if (isNaN(Number(values.precio))) {
  	errors.precio = 'Debe incluir un valor numerico'
  }else{
  	values.precio_con_impuestos =  calculate_final_value(values.precio, values.codigo_tarifa,values.tarifa_impuesto, values.exento );
  	values.iva_valor =  calculate_iva(values.precio,values.codigo_tarifa,values.tarifa_impuesto, values.exento ).toString();
  	values.unidad_medida_comercial = values.nombre
  }

  if (!values.codigo_servicio){
  	 errors.codigo_servicio = 'Campo Requerido';
  }
  if (!values.cod_producto) {
    errors.cod_producto = 'Campo Requerido'
  }
  if (!values.codigo) {
    errors.codigo = 'Campo Requerido'
  }
  if (!values.tipo_unidad) {
    errors.tipo_unidad = 'Campo Requerido'
  }
  if (!values.codigo_impuesto) {
    errors.codigo_impuesto = 'Campo Requerido'
  }

  values.tarifa_impuesto = TARIFA_IMPUESTO_VALOR_AGREGADO[values.codigo_tarifa]
  values.es_servicio = UNIDAD_VENTA_SERVICE[values.tipo_unidad]

  if (values.codigo_impuesto && (values.codigo_impuesto === "07")) {
  	$("#base_imponible").show();
  	if (!values.base_imponible) {
    	errors.base_imponible = 'Campo Requerido'
  	}
  }else {
  	$("#base_imponible").hide();
  }

   return errors;
}


ProductForm = reduxForm({
  form: 'product', // a unique name for this form
  validate,
  enableReinitialize: true
})(ProductForm);

const mapStateToProps = (state, props) => ({
 	product: state.product,
})

const mapDispatchToProps = dispatch => {  
   return {
     updateCabysCode: function(cabysCode){
      },
      


  }
  
};
//export default ProductForm;
export default connect(mapStateToProps,mapDispatchToProps)(ProductForm)


const calculate_iva = (value, tax_code, tarifa, exento) =>{
	if (exento) {
		return 0;
	}
	let tax = tarifa;
	value = value * 1;
	value = value - (value / ((tax / 100) + 1));
	return value.toFixed(5);
}

const calculate_final_value = (value, tax_code, tarifa, exento) =>{
	if (exento) {
		return value;
	}
	let tax = tarifa;
	value = value * 1;
	value = value / ( (tax / 100) + 1);
	return value.toFixed(5);
}
const IMPUESTOS = [
	{key: "01", value: "Impuesto al Valor Agregado"},
	{key: "02", value: "Impuesto Selectivo de Consumo"},
	{key: "03", value: "Impuesto Único a los combustibles"},
	{key: "04", value: "Impuesto específico de Bebidas Alcohólicas"},
	{key: "05", value: "Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador"},
	{key: "06", value: "Impuesto a los Productos de Tabaco "},
	{key: "07", value: "IVA (cálculo especial)"},
	{key: "08", value: "IVA Régimen de Bienes Usados (Factor)"},
	{key: "12", value: "Impuesto Especifico al Cemento "},
	{key: "98", value: "Otros"}
]


const TIPO_COD_PRODUCTO = [
	{key: "01", value: "Código del producto del vendedor"},
	{key: "02", value: "Código del producto del comprador"},
	{key: "03", value: "Código del producto asignado por la industria"},
	{key: "04", value: "Código uso interno"},
	{key: "99", value: "Otros"}
]

const COD_TARIFA_IMPUESTO_VALOR_AGREGADO = [
	{key: "01", value: "Tarifa 0% (Exento)"},
	{key: "02", value: "Tarifa reducida 1%"},
	{key: "03", value: "Tarifa reducida 2%"},
	{key: "04", value: "Tarifa reducida 4%"},
	{key: "05", value: "Transitorio 0%"},
	{key: "06", value: "Transitorio 4%"},
	{key: "07", value: "Transitorio 8%"},
	{key: "08", value: "Tarifa general 13%"}
]

const TARIFA_IMPUESTO_VALOR_AGREGADO = {
	"01": 0,
	"02": 1,
	"03": 2,
	"04": 4,
	"05": 0,
	"06": 4,
	"07": 8,
	"08": 13,
}
const UNIDAD_VENTA = [
	{"key":"Al", "value":"Alquiler de uso habitacional"},
	{"key":"Alc", "value":"Alquiler de uso comercial"},
	{"key":"Cm", "value":"Comisiones"},
	{"key":"I", "value":"Intereses"},
	{"key":"Os", "value":"Otro tipo de servicio"},
	{"key":"Sp", "value":"Servicios Profesionales"},
	{"key":"Spe", "value":"Servicios personales"},
	{"key":"St", "value":"Servicios técnicos"},
	{"key":"1", "value":"uno (indice de refracción)"},
	{"key":"´", "value":"minuto"},
	{"key":"´´", "value":"segundo"},
	{"key":"°C", "value":"grado Celsius"},
	{"key":"1/m", "value":"1 por metro"},
	{"key":"A", "value":"Ampere"},
	{"key":"A/m", "value":"ampere por metro"},
	{"key":"A/m²", "value":"ampere por metro cuadrado"},
	{"key":"B", "value":"bel"},
	{"key":"Bq", "value":"Becquerel"},
	{"key":"C", "value":"coulomb"},
	{"key":"C/kg", "value":"coulomb por kilogramo"},
	{"key":"C/m²", "value":"coulomb por metro cuadrado"},
	{"key":"C/m³", "value":"coulomb por metro cúbico"},
	{"key":"cd", "value":"Candela"},
	{"key":"cd/m²", "value":"candela por metro cuadrado"},
	{"key":"cm", "value":"centímetro"},
	{"key":"d", "value":"día"},
	{"key":"eV", "value":"electronvolt"},
	{"key":"F", "value":"farad"},
	{"key":"F/m", "value":"farad por metro"},
	{"key":"g", "value":"Gramo"},
	{"key":"Gal", "value":"Galón"},
	{"key":"Gy", "value":"gray"},
	{"key":"Gy/s", "value":"gray - por segundo"},
	{"key":"H", "value":"henry"},
	{"key":"h", "value":"hora"},
	{"key":"H/m", "value":"henry por metro"},
	{"key":"Hz", "value":"hertz"},
	{"key":"J", "value":"Joule"},
	{"key":"J/(kg·K)", "value":"joule por kilogramo kelvin"},
	{"key":"J/(mol·K)", "value":"joule por mol kelvin"},
	{"key":"J/K", "value":"joule por kelvin"},
	{"key":"J/kg", "value":"joule por kilogramo"},
	{"key":"J/m³", "value":"joule por metro cúbico"},
	{"key":"J/mol", "value":"joule por mol"},
	{"key":"K", "value":"Kelvin"},
	{"key":"kat", "value":"katal"},
	{"key":"kat/m³", "value":"katal por metro cúbico"},
	{"key":"kg", "value":"Kilogramo"},
	{"key":"kg/m³", "value":"kilogramo por metro cúbico"},
	{"key":"Km", "value":"Kilometro"},
	{"key":"Kw", "value":"kilovatios"},
	{"key":"L", "value":"litro"},
	{"key":"lm", "value":"lumen"},
	{"key":"ln", "value":"pulgada"},
	{"key":"lx", "value":"lux"},
	{"key":"m", "value":"Metro"},
	{"key":"m/s", "value":"metro por segundo"},
	{"key":"m/s²", "value":"metro por segundo cuadrado"},
	{"key":"m²", "value":"metro cuadrado"},
	{"key":"m³", "value":"metro cúbico"},
	{"key":"min", "value":"minuto"},
	{"key":"mL", "value":"mililitro"},
	{"key":"mm", "value":"Milímetro"},
	{"key":"mol", "value":"Mol"},
	{"key":"mol/m³", "value":"mol por metro cúbico"},
	{"key":"N", "value":"newton"},
	{"key":"N/m", "value":"newton por metro"},
	{"key":"N·m", "value":"newton metro"},
	{"key":"Np", "value":"neper"},
	{"key":"º", "value":"grado"},
	{"key":"Oz", "value":"Onzas"},
	{"key":"Pa", "value":"pascal"},
	{"key":"Pa·s", "value":"pascal segundo"},
	{"key":"rad", "value":"radián"},
	{"key":"rad/s", "value":"radián por segundo"},
	{"key":"rad/s²", "value":"radián por segundo cuadrado"},
	{"key":"s", "value":"Segundo"},
	{"key":"S", "value":"siemens"},
	{"key":"sr", "value":"estereorradián"},
	{"key":"Sv", "value":"sievert"},
	{"key":"T", "value":"tesla"},
	{"key":"t", "value":"tonelada"},
	{"key":"u", "value":"unidad de masa atómica unificada"},
	{"key":"ua", "value":"unidad astronómica"},
	{"key":"Unid", "value":"Unidad"},
	{"key":"V", "value":"volt"},
	{"key":"V/m", "value":"volt por metro"},
	{"key":"W", "value":"Watt"},
	{"key":"W/(m·K)", "value":"watt por metro kevin"},
	{"key":"W/(m²·sr)", "value":"watt por metro cuadrado estereorradián"},
	{"key":"W/m²", "value":"watt por metro cuadrado"},
	{"key":"W/sr", "value":"watt por estereorradián"},
	{"key":"Wb", "value":"weber"},
	{"key":"Ω", "value":"ohm"},
	{"key":"Otros", "value":"Se debe indicar la descripción de la medida a utilizar"}
]
const UNIDAD_VENTA_SERVICE = {
	"Al": false,
	"Alc": false,
	"Cm": false,
	"I": false,
	"Os": true,
	"Sp": true,
	"Spe": true,
	"St": true,
	"1": false,
	"´": false,
	"´´": false,
	"°C": false,
	"1/m": false,
	"A": false,
	"A/m": false,
	"A/m²": false,
	"B": false,
	"Bq": false,
	"C": false,
	"C/kg": false,
	"C/m²": false,
	"C/m³": false,
	"cd": false,
	"cd/m²": false,
	"cm": false,
	"d": false,
	"eV": false,
	"F": false,
	"F/m": false,
	"g": false,
	"Gal": false,
	"Gy": false,
	"Gy/s": false,
	"H": false,
	"h": false,
	"H/m": false,
	"Hz": false,
	"J": false,
	"J/(kg·K)": false,
	"J/(mol·K)": false,
	"J/K": false,
	"J/kg": false,
	"J/m³": false,
	"J/mol": false,
	"K": false,
	"kat": false,
	"kat/m³": false,
	"kg": false,
	"kg/m³": false,
	"Km": false,
	"Kw": false,
	"L": false,
	"lm": false,
	"ln": false,
	"lx": false,
	"m": false,
	"m/s": false,
	"m/s²": false,
	"m²": false,
	"m³": false,
	"min": false,
	"mL": false,
	"mm": false,
	"mol": false,
	"mol/m³": false,
	"N": false,
	"N/m": false,
	"N·m": false,
	"Np": false,
	"º": false,
	"Oz": false,
	"Pa": false,
	"Pa·s": false,
	"rad": false,
	"rad/s": false,
	"rad/s²": false,
	"s": false,
	"S": false,
	"sr": false,
	"Sv": false,
	"T": false,
	"t": false,
	"u": false,
	"ua": false,
	"Unid": false,
	"V": false,
	"V/m": false,
	"W": false,
	"W/(m·K)": false,
	"W/(m²·sr)": false,
	"W/m²": false,
	"W/sr": false,
	"Wb": false,
	"Ω": false,
	"Otros": false
}