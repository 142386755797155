import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery'; 
import {addExoneration } from '../../../redux/actions/invoice_actions';
let ExonerateDialog = (props) =>{
		return (						

      <div className="modal fade" id="exonerateFormModal" tabIndex="-1" role="dialog"  aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Exoneración</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="col-md-12">
                  <h5>La información es requerida para la exoneración</h5>

                  <div className="form-group">
                    <label>Tipo Documento *</label>
                    <select id="tipo_documento" type="number" name="tipo_documento" className="form-control">
                      <option value="01"> Compras autorizadas</option>
                      <option value="02"> Ventas exentas a diplomáticos</option>
                      <option value="03"> Orden de compra (Instituciones Públicas y otros organismos)</option>
                      <option value="04"> Exenciones Dirección General de Hacienda</option>
                      <option value="05"> Zonas Francas</option>
                      <option value="99"> Otros</option>
                    </select>
                    <span id="tipo_documento_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                    <label>Número Documento *</label>
                    <input id="numero_documento" type="number" name="numero_documento" className="form-control" />
                    <span id="numero_documento_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                    <label>Nombre de Institución *</label>
                    <input id="nombre_institucion" type="text" name="nombre_institucion" className="form-control"  />
                    <span id="nombre_institucion_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                    <label>Monto Exoneración*</label>
                    <input id="monto_exoneracion" type="number" name="monto_exoneracion" className="form-control" />
                    <span id="monto_exoneracion_error" className="error hidde">Campo Requerido!</span>
                  </div>

                  <div className="form-group">
                      <label>Porcentaje de Exoneración *</label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">%</div>
                        </div>  
                        <input  id="porcentanje_exoneracion" name="porcentanje_exoneracion" className="form-control" type="number" min="0" max="100"/>
                     </div>
                     <span id="porcentanje_exoneracion_error" className="error hidde">Campo Requerido!</span>
                      
                  </div>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary btn-lg"  onClick={props.handleSubmit}>Aceptar</button>
            </div>
          </div>
        </div>
      </div>

	   	
	)
	
}


const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: function(){
      let isValid = true;
      let tipo_documento =  $("#tipo_documento").val();
      let numero_documento = $("#numero_documento").val();
      let nombre_institucion = $("#numero_documento").val();
      let monto_exoneracion = $("#numero_documento").val();
      let porcentanje_exoneracion = $("#numero_documento").val();
      if (tipo_documento === ""){
        $("#tipo_documento_error").show();
        isValid = false;
      }
      if (numero_documento === ""){
        $("#numero_documento_error").show();
        isValid = false;
      }
       if (nombre_institucion === ""){
        $("#nombre_institucion_error").show();
        isValid = false;
      }
       if (monto_exoneracion === ""){
        $("#monto_exoneracion_error").show();
        isValid = false;
      }
       if (porcentanje_exoneracion === ""){
        $("#porcentanje_exoneracion_error").show();
        isValid = false;
      }
      if (isValid) {
        let exoneracion = {
          tipo_documento,
          numero_documento,
          nombre_institucion,
          monto_exoneracion,
          porcentanje_exoneracion
        }
      
        $("#exonerateFormModal").modal("hide");
        dispatch(addExoneration(exoneracion));
      }
    }

  }
};

export default connect(null, mapDispatchToProps)(ExonerateDialog);