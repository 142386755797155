import axios from 'axios';
import {LOGIN_STATUS,ATV_STATUS,CABYS_PRODUCT_LIST} from '../../redux/actions'
import $ from 'jquery'; 
import Store from '../../redux/store';
import {PRODUCTION_SERVER,DEVELOPMENT_SERVER} from '../constants';
export const URL = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEVELOPMENT_SERVER;
  } else {
     return PRODUCTION_SERVER;
  }
}
export const CABYS_URL = 'https://api.hacienda.go.cr/fe/cabys'


//Login information
export const login = (user) => {
	return (dispatch)	=> {		
	     return axios.post(URL() + "login", user,{
		      headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          console.log(URL())
          if (res.status === 200){    
          		$("#login_form_error_msg").hide();
              $("#login_form_error_msg1").hide();
            if(res.data.error === undefined){
              //ATV_STATUS
              dispatch({
                type: ATV_STATUS,
                atv_status: res.data.atv_status
               });
          		dispatch({
          			type: LOGIN_STATUS,
          			login_data: {
                   status: res.status, 
                   logged: res.status === 200, 
                   user: res.data.user,
                   token: res.data.jwt,
                   role:res.data.role
                 }                 
          		});
              if(res.data.user.status === "incomplete") {
                window.location = "/micuenta";
              }else if (res.data.atv_status){
            		window.location = "/";
              }else {
                window.location = "/configuracion/hacienda";
              }
            }else{
              $("#login_form_error_msg1").show();
            } 
          }
      	}).catch((error) => {
          	$("#login_form_error_msg").show();
            dispatch({
        			type: LOGIN_STATUS,
        			login_data: {status: error.response.status, logged: false}
        		});
        })
    };
};

export const activarCompany = (id) => {
  $("#loading_spinner").show();
  return (dispatch) => {    
       return axios.get(URL() + "activar_compania?id=" + id,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 200){    
              $("#activate_msg").show();
            
          }
        }).catch((error) => {
            $("#loading_spinner").hide();
        })
    };
};

//validar compañia
export const validarCompany = (params, callback) => {
  $("#loading_spinner").show();
  closeMessages();
  return (dispatch) => {    
       return axios.post(URL() + "validar_compania",params ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 200){    
            
              if (res.data.exist){
               let error = $("<ul>");
                if (res.data.email){
                  $(error).append($("<li>").html("El email ya se encuentra en el sistema."));
                }
                if(res.data.cedula){
                  $(error).append($("<li>").html("La cédula ya se encuentra en el sistema."));
                }
                errorMessage($(error).html());
              } else {
                $(callback).call();
              }
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
          $("#form_error_msg").show();
        })
    };
};

export const resetPassword = (params) => {
  return (dispatch) => {    
       return axios.post(URL() + "reset_password" ,params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200){    
            successMessage("Su contraseña ha sido restablecida, verifique su buzon de email por las nuevas credenciales");
          }
        }).catch((error) => {
            errorMessage("La información proveida no coincide, por faor verifique e intentelo de nuevo")
        })
    };
};

export const recoverQuestion = (params) => {
  return (dispatch) => {    
       return axios.post(URL() + "pregunta_secreta" ,params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200){    
            successMessage("Su fue enviada, verifique su buzon de email...");
          }
        }).catch((error) => {
            errorMessage("La información proveida no coincide, por faor verifique e intentelo de nuevo")
        })
    };
};

export const contactUs = (params) => {
  $("#loading_spinner").show();
  return (dispatch) => {    
       return axios.post(URL() + "contact" ,params,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          $("#loading_spinner").hide();
          if (res.status === 200){    
            successMessage("Su comentario fue enviado satisfactoriamente... Gracias!");
          }
        }).catch((error) => {
          $("#loading_spinner").hide();
            errorMessage("Ocurrio un error por favor intentelo de nuevo más tarde")
        })
    };
};

export const cabysLoadData = (params) => {
  setTokenHeader();
  if(params === {}){
    return;
  }
  return (dispatch) => {  
       return axios.get(CABYS_URL, {params},{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: CABYS_PRODUCT_LIST,
              cabys_product_list:  res.data['cabys']
            });
          
        }).catch((error) => {
            dispatch({
              type: CABYS_PRODUCT_LIST,
              cabys_product_list:  []
            });
        })

  };
}

export let token = () =>{

}

export const setTokenHeader = () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer' + Store.getState().login_data.token;
}

export let formatError = (data) => {
  let error_messages = "";
  Object.keys(data).map((_field,key) => {
    let field = data[_field];
    error_messages += "<div><strong>"+_field+":</strong>  "+field[0]+"</div>"
    return{}
  })
  return error_messages;
}

export function successMessage(msg){
   $('.App').animate({
        scrollTop: $("#form_success_msg").offset().top
    }, 2000);
  $("#form_error_msg").hide();
  $("#form_success_msg").show();
  $("#form_success_msg").html(msg);
}

export function errorMessage(msg){
  $('.top').animate({
                  scrollTop: $("#form_success_msg").offset().top
    }, 2000);
  $("#form_success_msg").hide();
  $("#form_error_msg").show();
  $("#form_error_msg").html(msg);
}

export function closeMessages() {
  $("#form_success_msg").hide();
  $("#form_error_msg").hide();
}