import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import $ from 'jquery';
import {updateMenu} from '../redux/actions';

const MenuButton = (props) =>{
	return (
		<li className="nav-item  ">		
	     	<Link className="nav-link text-white menu_button"  to={props.to} onClick={props.handleClick} id={props.name.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')}>
	     		<FontAwesomeIcon icon={props.icon} /> 
	     		<label>{props.name} </label>
	     	</Link>
	    </li>
	);
}; 

const mapStateToProps = (state, ownProps) => {
	return {
		menu_option: state.menu_option
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		handleClick(e){
			let id = ownProps.name.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'');
			$(".menu_button").removeClass('active');		
			$("#" + id).addClass("active");
			dispatch(updateMenu(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);