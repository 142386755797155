import React from 'react';
const TerminosCondicionesPage = (props) => {
	return (
		<div className="home about_page disclamer col-lg-10 offset-1">
			<p className="text-center">
				REGLAMENTO DE CONDICIONES, LIMITACIONES Y RESPONSABILIDADES
				POR EL USO DE FACTURAS ELECTRÓNICAS A TRAVÉS DE LA
				PLATAFORMA <br/>
				<a href="https://www.Digifact.cr" alt="">https://www.Digifact.cr</a>
			</p>
			<h4>PROPIEDAD Y RESPONSABILIDAD</h4>
			<ol>
				<li>
					Rudy Alonso Montoya Umaña titular del sitio web www.digifact.co.cr en adelante DigiFact, establece el presente
					documento para regular la relación entre las empresas, personas o instituciones,tanto de carácter privado como público; que utilicen FACTURA
					ELECTRÓNICA DigiFact a través de su plataforma <a href="https://www.Digifact.cr" alt="">https://www.Digifact.co.cr</a>
				</li>
				<li>
					El uso de FACTURA ELECTRÓNICA está autorizado en todo el país, por medio de la directriz o resolución, Nº DGT-R-51-2016, de la DIRECCIÓN
					GENERAL DE TRIBUTACIÓN del Ministerio de Hacienda, de las 16:20  horas del día 9 de enero de dos mil nueve, publicado en el Diario Oficial La 
					Gaceta N° 25 del jueves 5 de febrero del 2009.
				</li>
				<li>
					DigiFact como Proveedor de solución de facturación en sitios Web ofrece el servicio de FACTURA ELECTRÓNICA, primero que todo como una
					herramienta ágil y eficiente en términos administrativos, para ser utilizado portodas las personas y empresas que deseen realizar sus cobros y lograr las ventajas
					que el sistema ofrece; y desde luego como una oportunidad de comercio,fundamentado en la resolución Nº DGT-R-51-2016. Por ello, los cambios que se realicen o las sustituciones en dicha resolución no son ni serán responsabilidad de
					DigiFact, quién se ajustará a ellos en todo momento
				</li>
				<li>
					El presente Reglamento tiene incorporado en forma implícita la resolución, Nº
					DGT-R-51-2016, a la que se refiere el artículo anterior. Se entiende que este
					reglamento, se emite en función y vigencia de dicha resolución y sus reformas.
					Cualquier cambio que se realice en dicha resolución, y que afecte el
					funcionamiento de FACTURA ELECTRÓNICA, se entiende que modifica
					tácitamente este Reglamento, para lo cual DigiFact realizará los cambios necesarios
					en el menor tiempo posible.
				</li>
				<li>
					Se entiende siempre que las reformas a dicha resolución Nº DGT-R-51-2016, o
					su sustitución, y que afecten el servicio de FACTURA ELECTRÓNICA,
					tendrán vigencia inmediata a partir del día de su publicación en la Gaceta, y el
					usuario deberá ajustarse a partir de esa fecha a los cambios, ello
					independientemente si DigiFact ha modificado o no su reglamento de acuerdo al
					siguiente artículo; pues dicha resolución tiene carácter legal y público, y desde
					luego que su rango es superior a este Reglamento.
				</li>
				<li>
					En los casos que DigiFact no haya sido informada (o notificada, o enterada)
					oficialmente de algún cambio o sustitución, entiéndase con anterioridad a la
					publicación que afecte Nº DGT-R-51-2016, y que inesperadamente llegue a ser
					publicada en la Gaceta sin previo anuncio o conocimiento; entonces DigiFact en
					forma inmediata, -entendido dentro de sus posibilidades físicas y tecnológicas, así
					como personal idóneo y disponible-, tratará de hacer el cambio y adaptación de
					reglamento en el menor tiempo posible. Siendo que se trata de un asunto no
					previsible para DigiFact, ni tampoco en sus manos poder anticiparlo, se entenderá que
					no tendrá responsabilidad alguna por los problemas que le pueda causar al
					usuario.

				</li>
				<li>
					Cualquier persona que se sienta afectada por los cambios en dicha resolución,
					deberá reclamarlos por las vías administrativas o judiciales pertinentes, pero
					nunca contra DigiFact. En estos casos, los usuarios deberán renunciar al servicio que
					ofrece DigiFact.
				</li>
				<li>
					Por este reglamento, -ue se considera la parta dispositiva del contrato de
					utilización de FACTURA ELECTRÓNICA con DigiFact, que se entiende aprobado
					y suscrito a través de la boleta de suscripción del caso-; el usuario renuncia a
					cualquier demandada, denunciada o querellada contra DigiFact; precisamente porque
					los cambios que se realicen, o por la sustitución de la resolución, Nº DGT-R-51-
					2016, no han sido de conocimiento previo de DigiFact, sino que dependen de la
					Dirección de Tributación Directa. La obligación de DigiFact será siempre -y
					únicamente- la de actualizar este reglamento, cuándo ello sea necesario al haber
					cambios en la resolución
				</li>
				<li>
					DigiFact podrá suspender o dejar de brindar el servicio total o parcialmente, sin
					responsabilidad alguna; si una o más reformas o sustituciones de la resolución Nº
					DGT-R-51-2016 puedan afectar o afecten realmente el servicio, o ello le implique
					o genere algún tipo de responsabilidad que no pueda asumir. En estos casos a
					través de la página  www.digifact.cr se	dará a conocer la posición de DigiFact.
				</li>
				<li>
					 Los derechos de autor de las actividades que aquí se describen, incluyendo este
					Reglamento, así como todas las páginas, documentos, software que se encuentren
					en la página www.digifact.cr, al igual que cualquier tipo de publicidad, información, comunicación, documento
					que se realice con respecto a la Factura Electrónica,  fijados en la red, o impresos, o cualquiera de
					sus subsidiarias o empresas comerciales ligadas al grupo DigiFact, SON Y SERÁN
					PROPIEDAD EXCLUSIVA DE DigiFact. Cualquier uso indebido o sin autorización
					de la propiedad intelectual de DigiFact, se considerará violación a las leyes de la
					Propiedad Intelectual, y como tal será denunciado, demandado o querellado según
					corresponda, ello por cualquier medio legal que conceda la ley.
				</li>
			
			<h4>USUARIOS</h4>
			
				<li>
					Son usuarios las personas físicas y jurídicas que se hayan inscrito mediante el
					formulario D-140, “DECLARACIÓN DE INSCRIPCIÓN EN EL REGISTRO
					DE CONTRIBUYENTES” de la DIRECCIÓN GENERAL DE
					TRIBUTACIÓN del Ministerio de Hacienda o por medio de la dirección
					electrónica https://www.haciendadigital.go.cr/tribunet/ ; y que voluntariamente
					decidan adherirse al sistema de DigiFact de facturación alternativa, como medio
					alternativo para confeccionar y enviar facturas, (o cualquier tipo de cobros que
					requiera una factura de acuerdo a las leyes tributarias).
				</li>
				<li>
					FACTURA ELECTRÓNICA en términos generales se fundamenta en la
					resolución Nº DGT-R-51-2016, mientras FACTURA ELECTRÓNICA de DigiFact,
					se regula por este reglamento, en concordancia con la mencionada resolución. El
					MANUAL del usuario de DigiFact se puede acceder en la página
					http://www.digifact.cr/docs/ManualdeUsuario.pdf
				</li>
				<li>
					El usuario que se registre en DigiFact, se ajustará a éste reglamento, y a las
					disposiciones de la resolución Nº DGT-R-51-2016 a la que se refiere este
					Reglamento.
				</li>
				<li>
					La aceptación de estos términos, representa la adhesión a este reglamento y
					para todos los efectos se entenderá como formalizado el Contrato de FACTURA
					ELECTRÓNICA DE DigiFact
				</li>
			<h4>DEFINICIONES</h4>
				<li>
					Para efectos de la presente resolución se entiende por:
					<ul>
						<li>
							Resolución Nº DGT-R-51-2016: es el documento legal emitido por la
							DIRECCIÓN GENERAL DE TRIBUTACIÓN del Ministerio de Hacienda,
							de las 16:20 horas del día 9 de enero de dos mil nueve, publicado en el Diario
							Oficial La Gaceta N° 25 del jueves 5 de febrero del 2009, por el que el estado
							aprueba y avala el uso de FACTURAS ELECTRÓNICAS, como medio
							alternativo de facturación de personas físicas y jurídicas, que deban emitir
							facturas obligatoriamente. El sistema de FACTURA ELECTRÓNICA es un
							medio alternativo -de acuerdo a la normativa tributaria-, al sistema tradicional y
							convencional de facturación regulada por la misma Dirección General de la
							Tributación Directa
						</li>
						<li>
							Documento Electrónico: Es cualquier manifestación con carácter representativo
							o declarativo, expresado o transmitido por un medio electrónico o informático
							(tomado de la resolución Nº DGT-R-51-2016).
						</li>
						<li>
							FACTURA ELECTRÓNICA: documento comercial con efectos tributarios,
							generado, expresado y transmitido en formato electrónico (tomado de la
							resolución Nº DGT-R-51-2016). Comprende Nota de Crédito y Nota de Débito
							Electrónicas.
						</li>
						<li>
							Nota de Crédito y Nota de Débito Electrónicas: son los documentos
							electrónicos que permiten anular o hacer ajustes a la factura electrónica y a estos
							documentos, sin alterar la información del documento origen (tomado de la
							resolución Nº DGT-R-51-2016).

						</li>
						<li>
							Emisor-Receptor Electrónico: Persona física o jurídica registrada en la
							Administración Tributaria para emitir y recibir documentos electrónicos (tomado
							de la resolución Nº DGT-R-51-2016). FACTURA ELECTRÓNICA de DigiFact: es el servicio de elaborar facturas electrónicas a
							través de la plataforma: www.digifact.cr.

						</li>
						<li>
							Receptor Electrónico-No emisor: Sujetos pasivos acogidos a regímenes
							especiales que no realizan ventas en el territorio aduanero nacional y que reciben
							documentos electrónicos por las compras efectuadas en el mercado local (tomado
							de la resolución Nº DGT-R-51-2016).
						</li>
						<li>
							Receptor manual: Persona física o jurídica que por la compra de bienes o
							servicios recibe como comprobante de la transacción realizada, la impresión de la
							factura electrónica y sus documentos electrónicos asociados y que será el que
							ampare el respectivo asiento contable (tomado de la resolución Nº DGT-R-51-
							2016)
						</li>
						<li>
							Proveedor de solución de facturación en sitios Web: Las personas físicas o
							jurídicas interesadas en ofrecer el servicio de facturación electrónica mediante
							sitios Web (tomado de la resolución Nº DGT-R-51-2016).
						</li>
						<li>
							Sistemas de punto de venta: Sistema informático que gestiona el proceso de
							venta mediante una interfaz accesible a los vendedores, que permite la confección
							e impresión de la factura o comprobante de venta mediante referencias del
							producto o servicio almacenadas en una base de datos, realiza cambios en el
							inventario y otras labores del negocio. El término “punto de venta” se deriva del
							acrónimo POS, que en inglés significa “Point Of Sale”, también se conoce como
							“Terminal Punto de Venta” (TPV). A los efectos de esta resolución, el concepto
							“punto de venta” excluye los datáfonos y dispositivos similares utilizados para el
							cobro a los clientes por medio de tarjetas de crédito o débito
						</li>
						<li>
						Usuario: la persona física o jurídica que se adhiere al servicio de FACTURA ELECTRÓNICA de DigiFact de 
						conformidad con este reglamento y la resolución Nº DGT-R-51-2016.
						</li>
						<li>
							Plataforma o Plataforma www.digifact.cr: es la tecnología que
							incluye software y hardware, que tanto que DigiFact dispone para poder brindar los
							servicios a los que se refiere este documento.
						</li>
						<li>
							Software: es el lenguaje o sistema de comunicación entre computadoras que
							permite acceder a los servicios de FACTURA ELECTRÓNICA de DigiFact que
							requiere de una conexión a internet www.digifactcr: es el sitio oficial de DigiFact en el que se encuentra este
							documento, y a través del cual se accede
						</li>
					</ul>
				</li>
			<h4>El Servicio</h4>
				<li>
					DigiFact ofrece los siguientes servicios, sin que por ello no se puedan incorporar a
					futuro otros más relacionados con FACTURA ELECTRÓNICA, cuando la
					legislación así lo permita, lo cual se hará de acuerdo a lo dicho más adelante en
					cuanto a las reformas a este reglamento.
					<ul>
						<li>Servicio de elaboración de Factura electrónica</li>
						<li>Servicio de elaboración de Tiquetes electrónicos</li>
						<li>Servicio de elaboración de Notas de Crédito y Notas de Débito</li>
						<li>Servicio Emisor-Receptor Electrónico</li>
					</ul>
				</li>
			<h4>COMPENSACIÓN PARA DigiFact</h4>
				<li>
					Digifact se encuentra en un periodo de pruebas, momentaneamente no se esta realizando cobro alguno, por el uso del servicio.					
				</li>
				<li>
					Digifact se reserva la potestad de realizar cobros por sus servicios en cualquier momento, Avizando con un minimo de un mes antes de iniciar con el cobro por el uso del sistema
				</li>
			<h4>SOBRE LA PRESTACIÓN DEL SERVICIO</h4>
				<li>
					FACTURA ELECTRÓNICA de DigiFact y su plataforma, estarán disponibles 24
					horas al día, todos los días del año, salvo situaciones de fuerza mayor o
					mantenimientos requeridos por el servicio los cuales de ser posible se anunciarían
					con la debida antelación.
				</li>
				<li>
					El usuario debe estar registrado en la Dirección de Tributación Directa, bajo
					formulario D-140, “DECLARACIÓN DE INSCRIPCIÓN EN EL REGISTRO
					DE CONTRIBUYENTES” o por medio de la dirección electrónica
					https://www.haciendadigital.go.cr/tribunet/ .
				</li>
				<li>
						Una vez registrado en www.digifact.cr, recibirá una clave de acceso
					y se compromete al fiel resguardo de ellas pues dispondrá del ingreso a sus
					servicios en el sistema de información, deberá hacer el mejor uso y solicitando su
					cambio cuando lo considere necesario. Una vez entregados a los Clientes los
					nombres de usuario y claves de acceso, éstas personas serán los responsable de la
					asignación de las mismas a su personal de confianza y autorizado para llevar a
					cabo las actualizaciones, bloqueos, anulaciones, o cualquier otro proceso
					operativo en el manejo de la información respectiva. A partir de ese momento,
					DigiFact no asume responsabilidad por el inapropiado manejo de dichas claves o de la
					información.
				</li>
				<li>
					Por los medios que brinda DigiFact, se generarán los documentos de FACTURA
					ELECTRÓNICA, que serán almacenados vía Internet a un lugar seguro de DigiFact,
					pero podrán ser revisados por el usuario vía su clave, de ahí la importancia de
					resguardarlas. Todo movimiento también generará un correo electrónico.
				</li>
				<li>
					De todo movimiento quedará un registro electrónico y en su momento en línea en
					el servidor respectivo podrá consultar
				</li>
				<li>
					El contenido, el monto, el plazo y demás características que contenga la
					FACTURA ELECTRÓNICA, es responsabilidad del usuario.
				</li>
				<li>
					DigiFact, se compromete al fiel resguardo de la información y hacer uso de la misma
					únicamente para los efectos autorizados; y por ningún motivo podrá enviar a
					través de medios escritos o electrónicos o no, a terceros, salvo lo que corresponda
					a la Dirección General de Tributación
				</li>
				<li>
					El usuario definirá los rubros (detalle de la factura) que componen cada pago.
					Estos rubros se confeccionan en la plataforma de DigiFact
				</li>
				<li>
					El usuario tendrá acceso a la información del estado de sus cuentas por medio de
					DigiFact y sus claves seguras. Estas consultas permitirán conocer en cualquier
					momento el estado de sus cuentas
				</li>
				<li>
					El sistema no registra si la factura ha sido cancelada o no. Excepto que DigiFact lo
					integre como parte de su servicio y el cliente lo contrate
				</li>
				<li>
					El usuario será el responsable de la corrección o generación de nuevas facturas en
					caso de ser necesario, todo en la forma que establece la resolución Nº DGT-R-51-
					2016 y de conformidad con el sistema que DigiFact provea.
				</li>
			<h4>PROTECCIÓN DE LA PROPIEDAD INTELECTUAL</h4>
				<li>
					Los contenidos de las pantallas de las páginas de DigiFact , así como el sistema y sus
					plataformas, incluyendo los programas, bases de datos, redes, archivos que
					permiten acceder y usar su cuenta, son propiedad de DigiFact y están protegidas por
					las leyes y los tratados internacionales de derecho de autor, marcas, patentes,
					modelos y diseños industriales. El uso indebido y la reproducción total o parcial
					de dichos contenidos quedan estrictamente prohibidos, salvo autorización expresa
					y por escrito de DigiFact.
				</li>
			<h4>GENERALIDADES Y RESPONSABILIDAD DE LAS PARTES</h4>
				<li>
					El usuario que se adhiere a este Reglamento, lo hace a todos los términos
					expresados en este contrato, y es muestra de su voluntad y espíritu de la
					contratación, y forma el parte del perfeccionamiento del negocio aquí aceptado
				</li>
				<li>
					El adherente al aceptar este contrato, lo hace de buena tal y como generalmente se
					le reconoce en el mundo de los negocios
				</li>
				<li>
					DigiFact podrá reformar y actualizar este documento en forma automática y sin previo
					aviso, y los cambios entrarán en vigencia una vez que se haya dado a conocer la
					reforma en sus páginas, o bien cuando se le sustituya en Internet en forma total
				</li>
				<li>
					DigiFact podrá utilizar cualquier otro sistema de comunicación que considere
					conveniente para dar a conocer la reforma o sustitución
				</li>
				<li>
					Si un usuario no acepta las reformas o cambios, deberá comunicarlos a DigiFact en un
					plazo máximo de 45 días naturales contados a partir de la reforma o sustitución,
					en cuyo caso la relación se dará por terminada, sin ningún tipo de responsabilidad
					para las partes, salvo las económicas que no se hayan satisfecho
				</li>
				<li>
					El contrato únicamente podrá ser rescindido cuando alguna de las partes haga
						dicha solicitud por escrito con un mínimo de 30 días de anticipación. Además, se
						entiende que si el usuario no cumple, una o más de las cláusulas de este
						reglamento, esta situación dará derecho a DigiFact a rescindir su obligación, sin
						perjuicio de los daños y perjuicios que puedan reclamarse. En cualquier caso que
						se de la finalización del contrato, no habrá responsabilidad de ningún tipo para las
						partes, salvo las económicas que no se hayan satisfecho
				</li>
				<li>
					DigiFact no será responsable de aquellas obligaciones que derivadas de este contrato
					estén a su cargo y no pueda cumplir en los términos del mismo, debido al
					acontecimiento de una causa fortuita o de fuerza mayor
				</li>
				<li>
					Las obligaciones que este contrato impone o los derechos que otorga, no podrán
					ser cedidos, total o parcialmente. Sin embargo DigiFact podrá dar en garantía incluso
					traspasar el contrato a un Banco o entidad financiera, para garantizar cualquier
					tipo de obligación contraída, sin que ello implique que el servicio se considere
					cedido, sino su valor contable únicamente
				</li>
				<li>
					Las partes se someten a las leyes de la República de Costa Rica, para la
					interpretación de este contrato
				</li>
			</ol>
			<p>
				© Derechos Reservados 2018. Este reglamento ha sido confeccionado para DigiFact para su
				uso comercial. Prohibida su reproducción por cualquier medio.
			</p>
	    </div>
	)
}


export default TerminosCondicionesPage;
