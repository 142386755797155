import React from 'react';
import {loadProductInfo} from '../api/rest_product';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const InfoProductPage =({product}) => {
	return (			
		<div className="Row">		
			<h1 className="text-center">Información del Producto</h1>
			{	product.impuesto !== undefined &&
			<div className="jumbotron col-md-8 offset-2">
				<h1 className="display-4">{product.nombre} </h1>
				<p className="lead info">
					{product.descripcion}
				</p>
				<hr className="my-4"/>
				<p className="text-justify info">
					<strong>Precio:</strong> {product.precio}
				</p>
				<p className="text-justify info">
					<strong>Cantidad:</strong> {product.cantidad}
				</p>
				<p className="text-justify info">
					<strong>Tipo Codigo Producto:</strong> {product.tipo_codigo !== undefined && product.tipo_codigo.nombre}
				</p>
				<p className="text-justify info">
					<strong>Código:</strong> {product.codigo}
				</p>			
				<p className="text-justify info">
					<strong>Tipo Unidad de venta:</strong> {product.unidad !== undefined && product.unidad.nombre}
				</p>
				<p className="text-justify info">
					<strong>Tipo de Impuesto:</strong> {product.impuesto.nombre !== undefined && product.impuesto.nombre}
				</p>
				{	product.impuesto.nombre === 'Otros'	&&
					<p className="text-justify info">
					<strong>Tarifa Impuesto:</strong> {product.impuesto !== undefined && product.impuesto.tarifa}%
					</p>	
				}
				<div class="row">
					<div className="col-md-2 offset-8">
					    <Link to={"/productos/inventario/" + product.id} className="btn btn-primary btn-lg float-right" refresh="true">				
							Inventario
					    </Link>
				    </div>
					<div className="col-md-2 ">
						<Link to={"/productos/edit/" + product.id} className="btn btn-primary btn-lg float-right" refresh="true">				
							<FontAwesomeIcon icon="edit" size="1x"/>
							&nbsp;Editar
					    </Link>
					</div>
					
			    </div>
			</div>	
			}
		</div>
	
    );
}

const mapStateToProps = (state)  => {
	return {
		product: state.product
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const { id } =  props.computedMatch.params;
	dispatch(loadProductInfo(id));
	return {
		

    	
    }
};

export default connect(mapStateToProps , mapDispatchToProps)(InfoProductPage);