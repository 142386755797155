import {ADD_INVOICE_PRODUCTS, REMOVE_INVOICE_PRODUCTS,INVOICES,INVOICE_TIPO_DOCUMENTO,INVOICE_INFO,
	UPDATE_INVOICE_PRODUCTS,UPDATE_PRECIO_INVOICE_PRODUCTS, INVOICE_CLIENT, INVOICE_ID,
	INVOICE_EXTONERATE,INVOICE_MEDIO_PAGO,INVOICE_SITUACION,INVOICE_CONDICION_VENTA,INVOICE_DOLAR,
	DISCOUNT_INVOICE, INVOICE, CURRENCY_INVOICE,InitialInvoice, INVOICE_MESSAGE} from '../actions/invoice_actions';

export const InvoicesReducer = (state = [], action) => {
	if (action.type === INVOICES){
		return action.invoices;
	}
	return state;
}
export const InvoiceReducerInfo = (state = null, action) => {
	if (action.type === INVOICE_INFO){
		return action.invoice_info;
	}
	return state;
}

export const InvoiceMensageReducer = (state = null, action) => {
	if (action.type === INVOICE_MESSAGE){
		return action.invoice_message;
	}
	return state;
}

export const InvoiceReducer = (state = InitialInvoice, action) => {
	if (action.type === INVOICE){
		return action.invoice;
	}
	if (action.type === DISCOUNT_INVOICE){
		state = addDiscount(state, action.discount);
	}
	if (action.type === CURRENCY_INVOICE){
		return addCurrency(state, action.currency);
	}
	if (action.type === INVOICE_EXTONERATE){
		return addExoneration(state, action.exoneracion)
	}
	if (action.type === INVOICE_CLIENT){
		return addClient(state, action.client);
	}
	if (action.type === ADD_INVOICE_PRODUCTS){
		return addProduct(state, action.product);
	}
	if (action.type === REMOVE_INVOICE_PRODUCTS){		
		return removeProduct(state, action.product);
	}
	if(action.type === UPDATE_INVOICE_PRODUCTS){
		return updateQuantityProduct(state,action.product)
	}
	if (action.type === UPDATE_PRECIO_INVOICE_PRODUCTS){
		state = updateProceProduct(state, action.product);
	}
	if (action.type === INVOICE_ID){
		state = addID(state, action.id);
	}
	if (action.type === INVOICE_TIPO_DOCUMENTO){
		state = addTipoDocumento(state, action.tipo_documento);
	}
	if (action.type === INVOICE_MEDIO_PAGO){
		state = addMedioPago(state, action.medio_pago);
	}

	if (action.type === INVOICE_SITUACION){
		state = addSituacion(state, action.situacion);
	}
	if (action.type === INVOICE_CONDICION_VENTA){
		state = addCondicionVenta(state, action.condicion_venta);
	}
	if (action.type === INVOICE_DOLAR){
		state = addDolar(state, action.dolar);
	}
	return state;
}

function addDolar(state, dolar){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta: state.condicion_venta,
		products: state.products,
		client: state.client
	}
}


function addCondicionVenta(state, condicion_venta){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta: condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addSituacion(state, situacion){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addMedioPago(state, medio_pago){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addExoneration(state, exoneracion){
	return {
		id: state.id,
		exoneracion: exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addDiscount(state, discount){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: discount,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addTipoDocumento(state, tipo_documento){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}
function addCurrency(state, currency){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: currency,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}

function addClient(state, client){
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: client
	}
}

function addID(state, id){
	return {
		id: id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: state.products,
		client: state.client
	}
}
function addProduct(state, product) {
	let exist = false;
	for(let i = 0; i < state.products.length; i++ ) {
		if (state.products[i].id === product.id) {
			exist = true
		}
	}
	if(!exist){
		return {
			id: state.id,
			exoneracion: state.exoneracion,
			dolar: state.dolar,
			tipo_documento: state.tipo_documento,
			descuento: state.descuento,
			moneda: state.moneda,
			medio_pago: state.medio_pago,
			situacion: state.situacion,
			condicion_venta:state.condicion_venta,
			products: state.products.concat(product),
			client: state.client
		}
		 
	}
	return state;
}



function removeProduct(state, product) {
	let products = [];
	for(let i = 0; i < state.products.length; i++ ) {
		let new_product = state.products[i];
		if (new_product.id !== product.id) {			
			products.push(new_product);
		}
	}
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: products,
		client: state.client
	}
}

function updateProceProduct(state, product) {
	let products = [];
	for(let i = 0; i < state.products.length; i++ ) {
		let new_product = state.products[i];
		if (new_product.id === product.id) {			
			new_product.precio = product.precio;
		}
		products.push(new_product);
	}
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: products,
		client: state.client
	}
}

function updateQuantityProduct(state, product) {
	let products = [];
	for(let i = 0; i < state.products.length; i++ ) {
		let new_product = state.products[i];
		if (new_product.id === product.id) {			
			new_product.cantidad = product.cantidad;
		}
		products.push(new_product);
	}
	return {
		id: state.id,
		exoneracion: state.exoneracion,
		dolar: state.dolar,
		tipo_documento: state.tipo_documento,
		descuento: state.descuento,
		moneda: state.moneda,
		medio_pago: state.medio_pago,
		situacion: state.situacion,
		condicion_venta:state.condicion_venta,
		products: products,
		client: state.client
	}
}