import React from 'react';
import { connect } from 'react-redux';
import {clientList, loadClientInfoInvoice} from '../../api/rest_client';
import SearchClient from '../../forms/search_client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginationClient from '../../lists/pagination_client';
let InvoiceForm1 = (props) =>{
		return (						
	   	<div className="col-sm-12 col-lg-12">				
          <div className="col-sm-12 col-lg-12">
            <SearchClient onSubmit={props.searchClient}/>
          </div>
        <div className="col-sm-12 col-lg-12">
            <table className="table table-striped list">
              <thead>
                <tr>
                  <th>Nombre Usuario</th>           
                  <th>Correo</th>
                  <th>Telefonos</th>
                  <th></th>
                </tr>         
              </thead>
              <tbody className="list">
                {props.list.map((client, key) => {
                  return (
                    <tr key={key}>  
                      <td>{client.nombre} {client.apellido}</td>
                      <td>{client.email}</td>
                      <td>{client.telefonos.map((tel) => "(" + tel.codigo_pais + ") " + tel.numero +" ")}</td>
                      <td>
                        <button type="submit" className="btn btn-primary btn-lg float-right" onClick={() =>{ props.selectClient(client.id); props.nextPage()}}>
                          <FontAwesomeIcon icon="check-square" size="1x"/>
                          &nbsp;Seleccionar
                        </button>
                        </td>
                    </tr>
                  )
                })
                }   
              </tbody>
            </table>
            <PaginationClient />
          </div>
      </div>
	)
	
}

const mapStateToProps = state  => {
  return {
    list: state.client_list.rows,
    page: state.client_list.page,
    total: state.client_list.total,
    rows: state.client_list.page_rows,
    search: state.client_list.search,
  };
};

const mapDispatchToProps = dispatch => {
    dispatch(clientList({}));
  return {
    searchClient: function(values){
        dispatch(clientList(values));
    },
    selectClient: function(client_id){
      dispatch(loadClientInfoInvoice(client_id));
    }

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm1);