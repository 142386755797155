import React from 'react';
const DocumentAcceptedReport = ({documentos}) => (
	<div  className="col-lg-12">
		<h3>Documentos <small>FE: Factura Electrónica, ND: Nota de Débito, NC: Nota de Crédito, TE: Tiquete Electrónico </small> </h3>
		<div  className="row">
			<div  className="col-lg-12">
				<table className="table table-striped">
					<thead>
						<tr className="text-center">
							<th>Fecha</th>
							<th>Sub Total</th>
							<th>Total</th>
							<th>Impuestos</th>
						</tr>
					</thead>
					<tbody className="list">
					{documentos !== undefined &&
						Object.keys(documentos).map((idx) => {
							let document = documentos[idx];
							return (
								<tr key={idx} className="text-center">
									<td>{document.fecha}</td>
									<td>{document.total}</td>
									<td>{document.total_doc}</td>
									<td>{document.impuestos}</td>
								</tr>
							);
						})
					}
					</tbody>
				</table>
			</div>
		</div>
			

	</div>
);

export default DocumentAcceptedReport;