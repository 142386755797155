import React, { Component } from 'react';
import RegisterForm1 from '../forms/register_form_1';
import RegisterForm2 from '../forms/register_form_2';
import RegisterForm3 from '../forms/register_form_3';
import {connect} from 'react-redux';
import {addCompany} from '../api/rest_company';
import {validarCompany} from '../api';
import {AlertDialog} from '../dialogs';
import $ from 'jquery'; 
class RegisterPage extends Component {
	constructor(props){
		super(props);
		this.state = {
	      page: 1
	    }
	    this.handleSubmit = props.handleSubmit;
	    this.validateCompania = props.validateCompania;
	    this.nextPage = this.nextPage.bind(this);
    	this.previousPage = this.previousPage.bind(this);
    	this.changePage =  this.changePage.bind(this);
    	this.toLogin = props.toLogin.bind(this);
	}

	nextPage(values) {
		this.validateCompania(values, this.changePage());
  	}

  	changePage() {
  		this.setState({ page: this.state.page + 1 })
  	}

  	previousPage() {
    	this.setState({ page: this.state.page - 1 })
 	}
	render(){
		return (
			<div className="col-lg-12">
				<h2 className="text-center">Ingrese la información solicitada para registrar una nueva cuenta</h2>
				<hr/>
				<AlertDialog id="registerAlert"
				message="El Registro se hacompletado satisfactoreamente... Verifique su cuenta de correo para continuar con el proceso de regitro"
				title="Registro" handleSubmit={this.toLogin}/>
				<div className="col-md-8 offset-2">
					<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade"  role="alert"></div>
		    		<div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade"  role="alert"></div>
		    		{ this.state.page === 1 && 	<RegisterForm1 id="registerForm" onSubmit={this.nextPage}  initialValues={{tipo_cedula:"01"}} />	}
					{ this.state.page === 2 && 	<RegisterForm2 id="registerForm" onSubmit={this.changePage}  previousPage={this.previousPage} />	}
					{ this.state.page === 3 && 	<RegisterForm3 id="registerForm" onSubmit={this.handleSubmit}  previousPage={this.previousPage} />	}
				</div>
			</div>
	    );
	}
}
    
const mapDispatchToProps = dispatch => {
	return {
		validateCompania: function(values, callback){
			dispatch(validarCompany(values, callback));
		},
		handleSubmit: function(values){
			let registro = {"registro": values};
			dispatch(addCompany(registro));
			$("#registerAlert").modal('show')
    	}, 
    	toLogin:function(){
    		window.location = "/login"
    	}
    }
};
export default connect(null, mapDispatchToProps)(RegisterPage);