export const REPORT = "REPORT";
export const BALANCE_REPORT = "BALANCE_REPORT";
export const BALANCE_REPORT_FORM = "BALANCE_REPORT_FORM";
export const ACCEPTED_REPORT = "ACCEPTED_REPORT";
export const InitialReport = {
	date: {},
	tipo_reporte: "dialy",	
	invoices: [],
	summary: {}
}

export const InitialAcceptedReport = {
	date: {},
	tipo_reporte: "accepted",	
	invoices: [],
	summary: {}
}

export const InitialBalanceReport = {
	date: {},
	tipo_reporte: "balance",	
	invoices: [],
	summary: {}
}

export const InitialBalanceReportForm = {
	date: {},
	tipo_reporte: "balance_report",	
	email: ""
}