import {ATV,ATV_STATUS} from '../actions';
export const AtvReducer = (state = [], action) => {
	if (action.type === ATV){
		return action.atv;
	}
	return state;
};

export const AtvStatusReducer = (state = false, action) => {
	if (action.type === ATV_STATUS){
		return action.atv_status;
	}
	return state;
};
