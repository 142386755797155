export const INVOICE_PRODUCTS = "INVOICE_PRODUCTS";
export const ADD_INVOICE_PRODUCTS = "ADD_INVOICE_PRODUCTS";
export const REMOVE_INVOICE_PRODUCTS = "REMOVE_INVOICE_PRODUCTS";
export const UPDATE_INVOICE_PRODUCTS = "UPDATE_INVOICE_PRODUCTS";
export const UPDATE_PRECIO_INVOICE_PRODUCTS = "UPDATE_PRECIO_INVOICE_PRODUCTS";
export const INVOICE = "INVOICE";
export const INVOICE_INFO = "INVOICE_INFO";
export const DISCOUNT_INVOICE = "DISCOUNT_INVOICE";
export const CURRENCY_INVOICE = "CURRENCY_INVOICE";
export const INVOICE_CLIENT = "INVOICE_CLIENT";
export const INVOICE_ID = "INVOICE_ID";
export const INVOICES = "INVOICES";
export const INVOICE_TIPO_DOCUMENTO = "INVOICE_TIPO_DOCUMENTO";
export const INVOICE_EXTONERATE = "INVOICE_EXTONERATE";
export const INVOICE_MEDIO_PAGO = "INVOICE_MEDIO_PAGO";
export const INVOICE_SITUACION = "INVOICE_SITUACION";
export const INVOICE_CONDICION_VENTA = "INVOICE_CONDICION_VENTA";
export const INVOICE_DOLAR = "INVOICE_DOLAR";
export const INVOICE_MESSAGE = "INVOICE_MESSAGE";
//INVOICES
export const InitialInvoice = {
	id: 0,
	exoneracion: {},
	dolar: {compra: 0, venta: 0},
	tipo_documento: "01",
	descuento: {motivo:"", total: 0},
	moneda: "CRC",
	medio_pago: "01",
	situacion: "1",
	condicion_venta:"01",
	client: {},
	products: []
}
export const cleanInvoiceMessage = option =>{
	return {
		type:INVOICE_MESSAGE,
		invoice_message: null
	}
}

export const AddDolar = dolar => {
	return {
		type: INVOICE_DOLAR,
		dolar
	}
}
export const AddCondicionVenta = condicion_venta => {
	return {
		type: INVOICE_CONDICION_VENTA,
		condicion_venta
	}
}

export const AddSituacion = situacion => {
	return {
		type: INVOICE_SITUACION,
		situacion
	}
}

export const AddMedioPago = medio_pago => {
	return {
		type: INVOICE_MEDIO_PAGO,
		medio_pago
	}
}

export const AddToInvoiceProducts = product => {
	return {
		type: ADD_INVOICE_PRODUCTS,
		product: product
	}
}

export const removeToInvoiceProducts = product => {
	return {
		type: REMOVE_INVOICE_PRODUCTS,
		product: product
	}
}
export const updateInvoiceProducts = product => {
	return {
		type: UPDATE_INVOICE_PRODUCTS,
		product: product
	}
}
export const updatePrecioInvoiceProducts = product => {
	return {
		type: UPDATE_PRECIO_INVOICE_PRODUCTS,
		product: product
	}
}


export const cleanInvoice = option => {
	return{
		type: INVOICE,
		invoice: InitialInvoice
	}
}
export const cleanInvoiceInfo = option => {
	return{
		type: INVOICE_INFO,
		invoice_info: null
	}
}
export const cleanInvoices = option => {
	return{
		type: INVOICES,
		invoices: []
	}
}

export const addDiscount = discount => {
	return{
		type: DISCOUNT_INVOICE,
		discount: discount
	}
}
export const addCurrency = currency => {
	return{
		type: CURRENCY_INVOICE,
		currency: currency
	}
}
export const addClient = client => {
	return{
		type: INVOICE_CLIENT,
		client
	}
}

export const addID = id => {
	return{
		type: INVOICE_ID,
		id
	}
}
export const addTipoDocumento = tipo_documento => {
	return{
		type: INVOICE_TIPO_DOCUMENTO,
		tipo_documento
	}
}

export const addExoneration = exoneracion => {
	return{
		type: INVOICE_EXTONERATE,
		exoneracion
	}
}

