import axios from 'axios';
import {PROVINCIAS, CANTONES, DISTRITOS, BARRIOS} from '../../redux/actions';
import {URL, setTokenHeader}  from './index';

export const getProvincias = () => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "provincias", {} ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {         
            dispatch({
              type: PROVINCIAS,
              provincias:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: PROVINCIAS,
              provincias:  {}
            });
        })

  };
}

export const getCantones = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "cantones", {params} ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {         
            dispatch({
              type: CANTONES,
              cantones:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: CANTONES,
              cantones:  {}
            });
        })

  };
}

export const getDistritos = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "distritos", {params} ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: DISTRITOS,
              distritos:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: DISTRITOS,
              distritos:  {}
            });
        })

  };
}

export const getBarrios = (params) => {
  setTokenHeader();
  return (dispatch) => {  
       return axios.get(URL() + "barrios", {params} ,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
            dispatch({
              type: BARRIOS,
              barrios:  res.data
            });
          
        }).catch((error) => {
            dispatch({
              type: BARRIOS,
              barrios:  {}
            });
        })

  };
}