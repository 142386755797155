import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Field, reduxForm } from 'redux-form';
let SearchClient = (props) =>{
	const { handleSubmit} = props;
	 return (

	 	 <form onSubmit={handleSubmit}>
	 	 	<h4>Ingrese la información del cliente</h4>
	 	 	<div className="form-row align-items-center">
		 	 	<div className="col-md-3 my-1">
					<Field component="input" type="text" name="nombre" className="form-control form-control-lg" placeholder="Nombre"/>
				</div>
				<div className="col-md-3 my-1">
					<Field component="input" type="text" name="email" className="form-control form-control-lg" placeholder="Email"/>
				</div>
				<div className="col-md-3 my-1">
					<Field component="input" type="number" name="cedula" className="form-control form-control-lg" placeholder="Cedula"/>
				</div>
				<div className="col-2 my-2">
					<button className="btn btn-secondary btn-lg float-right" type="submit">	
						<FontAwesomeIcon icon="search" size="1x"/>
						&nbsp;Buscar
					</button>	
				</div>
		 	</div>
	 	 </form>
	 )
}
SearchClient = reduxForm({
  form: 'search_user',
  enableReinitialize: true
})(SearchClient);

export default SearchClient;