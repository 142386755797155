import React, {Component} from 'react';
import {Switch} from "react-router-dom";
//
import NewInvoicePage from '../components/pages/new_invoice_page';
import NewElectronicTikectPage from '../components/pages/new_electronic_ticket_page';
import InvoiceList from '../components/lists/invoice_list';
import NewCreditNotePage from '../components/pages/new_credit_note_page';
import NewDebitNotePage from '../components/pages/new_debit_note_page';
import InvoiceInfoPage from '../components/pages/invoice_info_page';
import NewProformaPage from '../components/pages/new_proforma_page';
import NewElectronicBuyInvoicePage from '../components/pages/new_electronic_buy_invoice_page';
import AceptFilePage from '../components/pages/acept_file_page';
//reports
import DialyReport from '../components/reports/dialy_report';
import WeeklyReport from '../components/reports/weekly_report';
import MonthlyReport from '../components/reports/monthly_report';
import CustomReport from '../components/reports/custom_report';
import AcceptedReport from '../components/reports/accepted_report';
import BalanceReport from '../components/reports/balance_report';
//users
import NewUserPage from '../components/pages/new_user_page';
import EditUserPage from '../components/pages/edit_user_page';
import UserList from '../components/lists/user_list';
//Products
import ProductList from '../components/lists/product_list';
import NewProductPage from '../components/pages/new_product_page';
import EditProductPage from '../components/pages/edit_product_page';
import InfoProductPage from '../components/pages/info_product_page';
//Inventory
import ProductInventoryInfoPage from '../components/pages/product_inventory';
//Client
import ClientList from '../components/lists/client_list';
import NewClientPage from '../components/pages/new_client_page';
import EditClientPage from '../components/pages/edit_client_page';
import InfoClientPage from '../components/pages/info_client_page';
//other
import AuthorizedRoute from '../components/authorized_route';
import AtvPage from '../components/pages/atv_page';
import CompanyPage from '../components/pages/company_page';
import EditCompanyPage from '../components/pages/edit_company_page';
class Content extends Component {
	constructor(props){
		super(props);
		this.state={
			body_content: null,
			id: props.id
		};
		this.setContent = this.setContent.bind(this);
	}
	setContent(element){
		this.setState({body_content: element});
	}
    render() {
        return (        	
           <div className="col-md-10" id={this.state.id}>
           		<div className="col-md-12" >
	                <Switch>
	                	
	                 	<AuthorizedRoute path='/facturas/new'  component={NewInvoicePage}/>
	                 	<AuthorizedRoute path='/facturas/tiqueteelectronico'  component={NewElectronicTikectPage}/>
	                 	<AuthorizedRoute path='/facturas/proforma'  component={NewProformaPage}/>
	                 	<AuthorizedRoute path='/facturas/facturacompra'  component={NewElectronicBuyInvoicePage}/>
	                 	<AuthorizedRoute path='/facturas/aceptar'  component={AceptFilePage}/>
	                 	<AuthorizedRoute path='/facturas/notacredito'  component={NewCreditNotePage}/>
	                 	<AuthorizedRoute path='/facturas/notadebito'  component={NewDebitNotePage}/>
	                 	<AuthorizedRoute path='/facturas/info/:id'  component={InvoiceInfoPage}/>
						<AuthorizedRoute path='/facturas'  component={InvoiceList}/>
						
	                 	<AuthorizedRoute path='/clientes/new'  component={NewClientPage}/>	                 	
	                 	<AuthorizedRoute path='/clientes/edit/:id' component={EditClientPage}/>
	                 	<AuthorizedRoute path='/clientes/info/:id' component={InfoClientPage}/>
	                 	<AuthorizedRoute path='/clientes' component={ClientList}/>
	                 	
	                 	
	                 	<AuthorizedRoute path='/reportes/dia' component={DialyReport}/>
	                 	<AuthorizedRoute path='/reportes/semanal' component={WeeklyReport}/>
	                 	<AuthorizedRoute path='/reportes/mensual' component={MonthlyReport}/>	
	                 	<AuthorizedRoute path='/reportes/doc_aceptados' component={AcceptedReport}/>	
	                 	<AuthorizedRoute path='/reportes/balance' component={BalanceReport}/>	
	                 	<AuthorizedRoute path='/reportes' component={CustomReport}/>	


	                 	
	                 	<AuthorizedRoute path='/productos/new' component={NewProductPage}/>
	                 	<AuthorizedRoute path='/productos/edit/:id' component={EditProductPage}/>
	                 	<AuthorizedRoute path='/productos/inventario/:id' component={ProductInventoryInfoPage}/>
	                 	<AuthorizedRoute path='/productos/info/:id' component={InfoProductPage}/>
	                 	<AuthorizedRoute path='/productos' component={ProductList}/>	    

	                 	<AuthorizedRoute path='/configuracion/usuarios/new' component={NewUserPage}/>
	                 	<AuthorizedRoute path='/configuracion/usuarios/edit/:id' component={EditUserPage}/>
	                 	<AuthorizedRoute path='/configuracion/usuarios' component={UserList}/>
	                 	<AuthorizedRoute path='/configuracion/hacienda' component={AtvPage}/>
	                 	<AuthorizedRoute path='/configuracion/compania/edit' component={EditCompanyPage}/>
	                 	<AuthorizedRoute path='/configuracion/compania' component={CompanyPage}/>
	                </Switch>
                </div>
            </div>
        );
    }
}

export default Content;