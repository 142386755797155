import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {clientList} from '../api/rest_client';
import {connect} from 'react-redux';
import SearchClient from '../forms/search_client';
import PaginationClient from './pagination_client';
const ClientList = (props) => (
	<div className="row">
		<div className="col-md-8 offset-2">
			<h2>Catalogo de clientes</h2>
		</div>
		<div className="col-md-10 offset-1">
			<SearchClient onSubmit={props.handleSubmit}/>
		</div>
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th>Nombre Usuario</th>						
						<th>Correo</th>
						<th>Telefonos</th>
						<th>Opciones</th>
					</tr>					
				</thead>
				<tbody className="list">
					{props.list.map((user, key) => {
						return (
							<tr key={key}>	
								<td>{user.nombre} {user.apellido}</td>
								<td>{user.email}</td>
								<td>{user.telefonos.map((tel) => "(" + tel.codigo_pais + ") " + tel.numero +" ")}</td>
								<td>
									<Link to={"/clientes/info/" + user.id} alt="Ver usuario">
										<FontAwesomeIcon icon="eye" size="2x"/>
							    	</Link>
							    	

						    	</td>
							</tr>
						)
					})
					}	

				</tbody>
			</table>
		</div>		
		<PaginationClient/>
	</div>
);

const mapStateToProps = state  => {
	return {
		list: state.client_list.rows,
	};
};

const mapDispatchToProps = dispatch => {
	dispatch(clientList({}));
	return {
		handleSubmit: function(values){
		    dispatch(clientList(values));
    	}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);

