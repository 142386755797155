import {PROVINCIAS, CANTONES, DISTRITOS, BARRIOS} from '../actions';
export const ProvinceReducer = (state = [], action) => {
	if (action.type === PROVINCIAS){
		return action.provincias;
	}
	return state;
};

export const CantonReducer = (state = [], action) => {
	if (action.type === CANTONES){
		return action.cantones;
	}
	return state;
};

export const DistrictoReducer = (state = [], action) => {
	if (action.type === DISTRITOS){
		return action.distritos;
	}
	return state;
};

export const BarrioReducer = (state = [], action) => {
	if (action.type === BARRIOS){
		return action.barrios;
	}
	return state;
};