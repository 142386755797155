import React, {Component} from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            	<footer className="footer page-footer font-small blue pt-4 bg-dark">
				    <div className="footer-copyright text-center py-3 text-white">© {new Date().getFullYear()} Copyright:
				      <a href="https://www.digifact.cr"> DigiFact.cr</a> <br/>
				      <Link to="/terminosycondiciones" className="" >									    	
								Términos y Condiciones
					    </Link> &nbsp;|&nbsp;
					    <Link to="/info" className="" >									    	
								Información de Registro
					    </Link>
				    </div>
			  </footer>
        );
    }
}

export default Footer;