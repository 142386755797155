import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {FileField,PasswordTextField} from "../tags";
let AtvFileForm = (props) =>{
     const { handleSubmit, error} = props;
		 return (
	      <form onSubmit={handleSubmit}>
	      	{error && <strong>{error}</strong>}
	      	<div id="form_success_msg" className="alert alert-success hidde alert-dismissible fade show"  role="alert"></div>
			    <div id="form_error_msg" className="alert alert-danger hidde alert-dismissible fade show"  role="alert"></div>
          <Field name="pin" component={PasswordTextField} label="Pin *"/>         
	      	<Field name="key_file" component={FileField} label="Llave Criptográfica"/>
	        <button type="submit" className="btn btn-primary btn-lg  float-right">Subir Archivo</button>
	      </form>
	);
}
//Validations
const validate = values => {
  const errors = {};
  if (!values.pin) {
    errors.pin = 'Campo Requerido'
  } 

   return errors;
}

AtvFileForm = reduxForm({
  form: 'atv_file', // a unique name for this form
  validate,
  enableReinitialize: true
})(AtvFileForm);

export default AtvFileForm;