import React from 'react';
import {Switch, Route} from "react-router-dom";
import LoginPage from '../components/pages/login_page';
import RecoverPasswordPage from '../components/pages/recover_password_page';
import RecoverQuestionForm from '../components/pages/recover_question_page';
import RegiterPage from '../components/pages/register_page';
import AccountPage from '../components/pages/account_page';
import ActivateCompanyPage from '../components/pages/activate_company_page'
const Home = () => {
	return (
		<div className="home row">
			<div className="col-md-10 offset-1">
		   	   <Switch>
	             	<Route path='/login' component={LoginPage}/>	             	
	             	<Route path='/recuperar_contrasena' component={RecoverPasswordPage}/>
	             	<Route path='/recuperar_pregunta' component={RecoverQuestionForm}/>
	             	<Route path='/registro' component={RegiterPage}/>
	             	<Route path='/micuenta' component={AccountPage}/>
	             	<Route path='/activar' component={ActivateCompanyPage}/>
	         	</Switch>
         	</div>
	    </div>
	)
}
export default Home;